import React, { useEffect, useState } from 'react'
import { Segment, Icon, Dimmer, Loader, IconGroup } from 'semantic-ui-react'
import { useHistory, useParams, generatePath, NavLink } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { useIntl } from 'react-intl'
import { camelizeKeys } from 'humps'
import get from 'lodash/get'
import {
  Button,
  Form,
  useSavingModals,
  Modal,
} from '@labsavvyapp/ui-components'

import { ClientDetails } from '@labsavvyapp/components'

import style from './InsurancePage.module.css'
import { CLIENTS, LAB_ORDER } from '../../../config/routes'
import { GetPatient } from '../../../graphql/clients/queries.js'
import { GetMe } from '../../../graphql/user/queries.js'
import capitalize from '../../../utils/capitalize'
import InsuranceForm from './InsuranceForm/InsuranceForm'

import {
  ListInsurance,
  ListInsuranceImages,
} from '../../../graphql/insurance/queries'
import {
  AddInsurance,
  DeleteInsuranceImage,
  EditInsurance,
} from '../../../graphql/insurance/mutations'
import { UploadImage } from '../../../graphql/files/mutations'
import { ListHealthPlans } from '../../../graphql/health-plan/queries'
import { ListInsuranceProviders } from '../../../graphql/insurance-provider/queries'
import { useClientViewModel } from '../../../viewmodels'

export default function InsurancePage() {
  const { formatMessage } = useIntl()
  const { location, push } = useHistory()
  const clientViewModel = useClientViewModel()

  // Grab the patient ID form the URL
  const { clientId } = useParams()

  // States
  const [errors, setErrors] = useState([])
  const [isPrimaryInsurance, setIsPrimaryInsurance] = useState(true)
  const [primaryInsuranceData, setPrimaryInsuranceData] = useState()
  const [secondaryInsuranceData, setSecondaryInsuranceData] = useState()
  const activeInsurance = isPrimaryInsurance ? 'primary' : 'secondary'
  const [hasSubmittedForm, setHasSubmittedForm] = useState(false)
  const [
    showCannotFillSecondaryInsuranceAlert,
    setShowCannotFillSecondaryInsuranceAlert,
  ] = useState(false)
  const selectedInsuranceData = isPrimaryInsurance
    ? primaryInsuranceData
    : secondaryInsuranceData

  // GraphQL Mutations
  const [addInsurance] = useMutation(AddInsurance)
  const [editInsurance] = useMutation(EditInsurance)
  const [uploadImage] = useMutation(UploadImage)
  const [deleteInsuranceImage] = useMutation(DeleteInsuranceImage)

  // GrapQL Query - Fetch user information
  const { data: userData } = useQuery(GetMe)
  const parsedUserData = camelizeKeys(userData)
  const consumerTitle = capitalize(
    get(parsedUserData, 'getMe.project.consumersTitle', 'Client'),
  )

  // GraphQL Query - Get Insurance  data
  const { data } = useQuery(GetPatient, {
    variables: {
      id: clientId,
    },
    skip: !clientId,
  })

  const clientData = data && data.getPatient
  const saveClientId = clientViewModel?.handleSelectedClientChange?.(
    clientData?._id,
  )

  // GraphQL Query - Get Insurance  data
  const { data: insuranceData, refetch: refetchListInsurance } = useQuery(
    ListInsurance,
    {
      variables: { patient_id: clientId },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
    },
  )

  const [insuranceDataState, setInsuranceDataState] = useState(insuranceData)

  const insuranceDataStateLength = insuranceData?.listInsurance?.length

  useEffect(() => {
    if (!insuranceData) {
      refetchListInsurance()
    }
    setInsuranceDataState(insuranceData)
  }, [insuranceData])

  const { data: insuranceDataImages, refetch: refetchListInsuranceImages } =
    useQuery(ListInsuranceImages, {
      variables: { patient_id: clientId },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
    })

  const [insuranceDataImagesState, setInsuranceDataImagesState] =
    useState(insuranceDataImages)

  useEffect(() => {
    setInsuranceDataImagesState(insuranceDataImages)
  }, [insuranceDataImages])

  // GraphQL Query - Get Insurance Provider data
  const { data: insuranceProvidersData } = useQuery(ListInsuranceProviders, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  })

  // GraphQL Query - Get Insurance Plan Type data
  const { data: healthPlanData } = useQuery(ListHealthPlans, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  })

  // Handle isPrimaryInsurance state change
  const handleIsPrimaryInsuranceOnChange = (isPrimaryInsuranceSelected) => {
    if (!isPrimaryInsuranceSelected) {
      const isPrimaryInsuranceFound = insuranceData.listInsurance.find(
        (insurance) => insurance.form_type === 'primary',
      )
      if (!isPrimaryInsuranceFound) {
        setIsPrimaryInsurance(true)
        setShowCannotFillSecondaryInsuranceAlert(true)
        // alert(
        //   'Primary insurance must be filled out before creating a secondary insurance',
        // )
      } else {
        setIsPrimaryInsurance(isPrimaryInsuranceSelected)
      }
    } else {
      setIsPrimaryInsurance(isPrimaryInsuranceSelected)
    }
  }

  // Handle primaryInsuranceData state change
  const handlePrimaryInsuranceDataOnChange = (primaryInsuranceData) => {
    setPrimaryInsuranceData(primaryInsuranceData)
  }

  // Handle secondaryInsuranceData state change
  const handleSecondaryInsuranceDataOnChange = (secondaryInsuranceData) => {
    setSecondaryInsuranceData(secondaryInsuranceData)
  }

  const handleInsuranceImagesChange = (insuranceImages) => {
    setInsuranceDataImagesState(insuranceImages)
  }
  const handleHasSubmittedFormChange = (hasSubmittedState) => {
    setHasSubmittedForm(hasSubmittedState)
  }

  const addInsuranceImage = async (face, file) => {
    try {
      const { data: imageData } = await uploadImage({
        variables: { file },
      })

      refetchListInsuranceImages()

      const activeInsuranceData =
        insuranceDataImages?.listInsurance.length > 0
          ? insuranceDataImages.listInsurance.find(
              (insurance) => insurance.form_type === activeInsurance,
            )
          : null

      if (!activeInsuranceData) {
        await addInsurance({
          variables: {
            data: {
              patient_id: clientId,
              [face]: imageData?.uploadImage?._id,
              form_type: activeInsurance,
              is_active: true,
            },
          },
        })
      } else {
        await editInsurance({
          variables: {
            id: activeInsuranceData._id,
            data: {
              patient_id: clientId,
              [face]: imageData?.uploadImage?._id,
            },
          },
        })
      }

      refetchListInsuranceImages()
    } catch (e) {
      throw new Error(e)
    }
  }

  const onRemoveInsuranceImage = async (id, face) => {
    try {
      await deleteInsuranceImage({
        variables: {
          id,
          data: {
            field: face,
          },
        },
        skip: !id,
      })
      refetchListInsuranceImages()
    } catch (e) {
      throw new Error(e)
    }
  }

  const toggleInsuranceActivation = async (isActive) => {
    const activeInsuranceData =
      insuranceDataImages?.listInsurance.length > 0
        ? insuranceDataImages.listInsurance.find(
            (insurance) => insurance.form_type === activeInsurance,
          )
        : null

    try {
      if (activeInsuranceData) {
        await editInsurance({
          variables: {
            id: activeInsuranceData._id,
            data: {
              patient_id: clientId,
              form_type: activeInsurance,
              is_active: isActive,
            },
          },
        })
      } else {
        await addInsurance({
          variables: {
            data: {
              patient_id: clientId,
              form_type: activeInsurance,
              is_active: isActive,
            },
          },
        })
      }
    } catch (e) {
      throw new Error(e)
    }
  }

  const validateFormInput = (data) => {
    const keys = []
    const except = ['middle_initial', 'front_image', 'back_image']
    for (const [key, val] of Object.entries(data)) {
      if (!val && !except.includes(key)) keys.push(key)
    }

    return keys
  }

  // Cancel button URL
  const previousPageURL = clientId
    ? generatePath(CLIENTS.view, { clientId })
    : CLIENTS.list

  const handleFormSaveError = () => {
    showErrorModal(true)
    showSavingModal(false)
    showSavedModal(false)
  }

  const handleErrorsChange = (errors) => {
    setErrors(errors)
  }

  const generateInsurancePayload = (data) => {
    if (data) {
      // Deconstruct insuranceData for graphQL mutation
      const {
        provider,
        plan_type,
        id_number,
        coverage_date,
        group_name,
        group_number,
        first_name,
        last_name,
        middle_initial,
        guarantor_dob,
        address,
        city,
        state,
        zip,
        phone,
        relationship,
        is_active,
      } = data

      // Deconstruct as payload
      const payload = {
        provider: provider,
        plan_type: plan_type,
        id_number: id_number,
        coverage_date: new Date(coverage_date),
        group_name: group_name,
        group_number: group_number,
        subscriber: {
          first_name: first_name,
          last_name: last_name,
          middle_initial: middle_initial,
          guarantor_dob: new Date(guarantor_dob),
          address: address,
          city: city,
          state: state,
          zip: zip,
          phone: phone,
          relationship: relationship,
        },
        is_active: is_active,
        form_type: activeInsurance,
        patient_id: clientId,
      }

      return payload
    }
  }

  const handleInsuranceCreateUpdate = async (payload, skipErrorCheck) => {
    // check for errors
    let hasErrors

    setHasSubmittedForm(true)

    if (skipErrorCheck) {
      hasErrors = []
    } else {
      hasErrors = validateFormInput(selectedInsuranceData)
      handleErrorsChange(hasErrors)
    }

    const activeInsuranceData =
      insuranceData?.listInsurance.length > 0
        ? insuranceData.listInsurance.find(
            (insurance) => insurance.form_type === activeInsurance,
          )
        : null

    if (insuranceDataStateLength > 2) {
      // Needs DB cleanup! - There are more that 2 instances of primary or secondary insurance data under one patient
      handleFormSaveError()
    } else {
      if (!hasErrors.length) {
        try {
          if (activeInsuranceData) {
            await editInsurance({
              variables: {
                id: activeInsuranceData._id,
                data: payload,
              },
            })
            showSavedModal(true)
          } else {
            await addInsurance({
              variables: {
                data: payload,
              },
            })
            showSavedModal(true)
          }
          refetchListInsurance()
        } catch {
          handleFormSaveError()
        }
      } else {
        // There is an error on the form, throw error modal
        handleFormSaveError()
      }
    }
  }

  // Modals
  const [
    modals,
    {
      showSavingModal,
      showSavedModal,
      showErrorModal,
      showConfirmationModal: showAddEditInsuranceModal,
    },
  ] = useSavingModals({
    savingMessage: `We're saving the ${activeInsurance} insurance data, please wait...`,
    savedMessage: 'The insurance data has been processed.',
    confirmationMessage: `Are you sure you want to ${
      insuranceData?.listInsurance.length ? 'update' : 'add'
    } this insurance?`,
    errorMessage: `There was a problem in saving insurance data`,
    onConfirm: async () => {
      const payload = generateInsurancePayload(selectedInsuranceData)
      handleInsuranceCreateUpdate(payload, false)
    },
  })

  if (!clientData) {
    return (
      <Dimmer active inverted>
        <Loader size="large">Loading</Loader>
      </Dimmer>
    )
  }

  return (
    <>
      {/* Modals */}
      {modals}

      <Form
        intl={{
          formatMessage,
        }}
        className={style.container}
      >
        {location?.state?.fromLab ? (
          <NavLink
            data-test="button-insurance-client"
            to={generatePath(LAB_ORDER.new)}
            className={style.editLink}
          >
            <Icon name="caret left" className={style.breadcrumbsIcon} />
            Back to Lab Order
          </NavLink>
        ) : (
          <NavLink
            data-test="button-insurance-client"
            to={generatePath(CLIENTS.view, { clientId: clientData?._id })}
            className={style.editLink}
          >
            <Icon name="caret left" className={style.breadcrumbsIcon} />
            Back to {consumerTitle}
          </NavLink>
        )}

        <div className={style.formContainer}>
          <div className={style.leftColumn}>
            <ClientDetails
              _id={clientData._id}
              data={clientData.data}
              clientDataPageLink={() =>
                push(generatePath(CLIENTS.view, { clientId: clientData?._id }))
              }
            />
          </div>
          <div className={style.rightColumn}>
            <Segment className={style.segment}>
              <InsuranceForm
                insuranceData={insuranceDataState}
                insuranceDataImages={insuranceDataImagesState}
                refetchListInsuranceImages={refetchListInsuranceImages}
                handleInsuranceImagesChange={handleInsuranceImagesChange}
                insuranceProvidersData={insuranceProvidersData}
                healthPlanData={healthPlanData}
                consumerTitle={consumerTitle}
                isPrimaryInsurance={isPrimaryInsurance}
                handleIsPrimaryInsuranceOnChange={
                  handleIsPrimaryInsuranceOnChange
                }
                primaryInsuranceData={primaryInsuranceData}
                handlePrimaryInsuranceDataOnChange={
                  handlePrimaryInsuranceDataOnChange
                }
                secondaryInsuranceData={secondaryInsuranceData}
                handleSecondaryInsuranceDataOnChange={
                  handleSecondaryInsuranceDataOnChange
                }
                addInsuranceImage={addInsuranceImage}
                onRemoveInsuranceImage={onRemoveInsuranceImage}
                toggleInsuranceActivation={toggleInsuranceActivation}
                hasError={errors}
                handleErrorsChange={handleErrorsChange}
                hasSubmittedForm={hasSubmittedForm}
                handleHasSubmittedFormChange={handleHasSubmittedFormChange}
                activeInsurance={activeInsurance}
                handleInsuranceCreateUpdate={handleInsuranceCreateUpdate}
                selectedInsuranceData={selectedInsuranceData}
                generateInsurancePayload={generateInsurancePayload}
              />
            </Segment>
          </div>
        </div>

        <div className={style.buttonsContainer}>
          <Button primary onClick={showAddEditInsuranceModal}>
            {insuranceData?.listInsurance.length ? 'Update' : 'Add'}{' '}
            {activeInsurance} Insurance
          </Button>

          <Button onClick={() => push(previousPageURL)} variant="basic">
            Cancel
          </Button>
        </div>
      </Form>

      <Modal
        open={showCannotFillSecondaryInsuranceAlert}
        showClose
        onCloseClick={() => setShowCannotFillSecondaryInsuranceAlert(false)}
      >
        <div className={style.modalAlert}>
          <IconGroup size="medium">
            <Icon size="big" color="red" name="dont" />
            <Icon color="black" name="user" />
          </IconGroup>
          <p>{`Primary insurance must be filled out before creating a secondary insurance`}</p>
        </div>
      </Modal>
    </>
  )
}
