import React from 'react'

import { PanelHeader, PanelBody } from '../../../../components/Panel/Panel'
import { Chart } from './Chart'
import style from './HistoryChart.module.css'

export default function HistoryChart({
  width,
  units,
  ranges,
  results,
  result = null,
  showNewVersion = false,
}) {
  if (!results || !results.length) {
    return null
  }
  const MARGINS = 20
  return (
    <>
      <PanelHeader style={{ marginTop: 16 }}>
        <h2 className={style.chartTitle}>History</h2>
        <span className={style.chartUnits}>{units}</span>
      </PanelHeader>
      <PanelBody>
        {result?.externalResource?.lineChart?.url && showNewVersion ? (
          <img
            alt="History"
            src={result.externalResource?.lineChart?.url ?? ''}
            className={style.chartIMG}
          />
        ) : (
          <Chart
            width={width > 600 ? width - MARGINS : 600}
            height={200}
            data={results}
            ranges={ranges}
          />
        )}
      </PanelBody>
    </>
  )
}
