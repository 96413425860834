import React from 'react'
import styled from '@emotion/styled'
import { CreateNewLabOrder } from './page'

const LabOrderLayout = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
`

interface ComponentProps extends React.ComponentProps<any> {}

export const CreateLabOrderView: React.FunctionComponent<ComponentProps> = () => {
  return (
    <LabOrderLayout>
      <CreateNewLabOrder />
    </LabOrderLayout>
  )
}
