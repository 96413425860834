import React from 'react'
import classnames from 'classnames'
import { NavLink, useParams, generatePath } from 'react-router-dom'

import { LAB_REPORTS } from '../../../config/routes'
import style from './LabReportTrendChild.module.css'

export default function LabReportTrendChild({ editing, test }) {
  const { labReportId } = useParams()

  return (
    <NavLink
      data-test="link-result"
      to={
        (!editing &&
          generatePath(LAB_REPORTS.resultDetails, {
            labReportId,
            resultId: test.id,
          })) ||
        undefined
      }
      className={classnames(style.container, {
        [style.statusAbnormal]: test.status === 'abnormal',
        [style.statusBorderline]: test.status === 'borderline',
        [style.editing]: editing,
      })}
    >
      {test.externalResource?.lineChart?.url && (
        <img
          alt={test.status}
          src={test.externalResource?.lineChart?.url}
          className={style.chartIMG}
        />
      )}
    </NavLink>
  )
}
