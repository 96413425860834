import React from 'react'
import { Label } from 'semantic-ui-react'
import { getBrightness } from '../../../../../utils/helpers'

export const metaContainers = (containers: any, containers_external: any) => (
  <>
    {containers?.map((container: any, i: any) => {
      const light = getBrightness(container?.value)

      return (
        <Label
          style={
            light
              ? {
                  backgroundColor: `#` + container?.value,
                  border: '1px solid #ccc',
                  color: '#333',
                }
              : {
                  backgroundColor: `#` + container?.value,
                  border: `1px solid #` + container?.value,
                  color: '#eee',
                }
          }
          horizontal
          key={i}
        >
          {container?.value2}
        </Label>
      )
    })}
    {containers_external?.map((external: any, i: any) => (
      <Label horizontal style={{ background: 'none', margin: 0 }} key={i}>
        {external?.name}
      </Label>
    ))}
  </>
)
