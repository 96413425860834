import { PartnerCompendiumPanel, ProjectPackage } from '@labsavvyapp/components'

export const generateOrderItemsData = (selectedCompendiaPanels: PartnerCompendiumPanel[], selectedOrderSets: ProjectPackage[]) => {
  const orderItemDataFromPanels =
    selectedCompendiaPanels.map((panel) => ({
      order_code: panel?.code,
      // specimen: { type: panel?.specimen?.type }, // where to get specimen.type??
    })) ?? []

  const orderItemDataFromOrdersets = []

  return []
}
