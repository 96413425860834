import { TextInput } from '@labsavvyapp/components'
import React from 'react'
import { useCreateLabOrderBusinessLogic } from '../../../../logic'

export const SelectedTestListSearch = () => {
  const businessLogic = useCreateLabOrderBusinessLogic()
  const rightPanelSearch = businessLogic?.rightPanelSearch ?? []

  return (
    <TextInput
      label=""
      placeholder="Search by code or name..."
      description=""
      value={rightPanelSearch}
      onChange={(event) => businessLogic?.handleRightPanelSearchChange(event.currentTarget.value)}
    />
  )
}
