import React, { createContext, useContext } from 'react'
import { AddEditClientPageBusinessLogicContextContract } from './AddEditClientPageBusinessLogicContextContract'

const AddEditClientPageBusinessLogicContext = createContext<AddEditClientPageBusinessLogicContextContract | null>(null)

export const useAddEditClientPageBusinessLogic = (): AddEditClientPageBusinessLogicContextContract | null => {
  return useContext(AddEditClientPageBusinessLogicContext)
}

interface ComponentProps extends React.ComponentProps<any>, AddEditClientPageBusinessLogicContextContract {}

export const AddEditClientPageBusinessLogicProvider: React.FunctionComponent<ComponentProps> = (props: React.PropsWithChildren<ComponentProps>) => {
  const { children, ...rest } = props

  return <AddEditClientPageBusinessLogicContext.Provider value={rest}>{children}</AddEditClientPageBusinessLogicContext.Provider>
}
