import React from 'react'
import get from 'lodash/get'
import { capitalize } from 'lodash'
import { format } from 'date-fns'
import classnames from 'classnames'
import { generatePath } from 'react-router-dom'

import { Badge, Button, CircleIcon, Tooltip } from '@labsavvyapp/components'
import dateFormatFromIso from '../../../../utils/date-format-from-iso'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/pro-solid-svg-icons'
import { LAB_ORDER } from '../../../../config/routes'
import { showQuestResults } from '../../../../utils/helpers'
import { LAB_IS_IN_ORDERED_STATUS_MESSAGE } from '../constants'
import styled from '@emotion/styled'

const RowContainer = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1; /* Limits to 2 lines */
`

export const generateDynamicLabReportRowData = (columnsToDisplay, style, data) => {
  if (!data.patient) {
    return null
  }
  const {
    patient: { id: clientId },
  } = data

  const status = get(data, 'status')
  const summary = get(data, 'summary')
  const allNormal = !summary?.abnormal
  const pdf = get(data, 'resultDocumentsBase64')

  // const openTrending = (e, page) => {
  //   e.preventDefault()
  //   const url = generatePath(page, { labReportId })
  //   window.open(url, '_top')
  // }

  const openPDF = (e) => {
    e.preventDefault()
    if (pdf?.pdfBase64) showQuestResults(pdf?.pdfBase64)
  }

  const openLabOrder = (e, page) => {
    e.preventDefault()
    const url = generatePath(page)
    window.open(url, '_top')
  }

  const getBadgeStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'ordered':
        return 'violet'
      case 'started':
        return 'blue'
      case 'partial':
        return 'grey'
      case 'completed':
        return 'green'
      case 'final':
        return 'pink'
      default:
        return ''
    }
  }

  return columnsToDisplay.map((partnerColumn, i) => {
    if (partnerColumn.isActive) {
      switch (partnerColumn.code) {
        case 'patient_name':
          const patientName = get(data, 'patient.data.name.display')
          return (
            <RowContainer key={i}>
              <Tooltip label={patientName} position="bottom">
                <span>{patientName}</span>
              </Tooltip>
            </RowContainer>
          )
        case 'dob':
          const dob = dateFormatFromIso(get(data, 'patient.data.dob'), 'MM/DD/yyyy')
          return (
            <RowContainer key={i}>
              <Tooltip label={dob} position="bottom">
                <span>{dob}</span>
              </Tooltip>
            </RowContainer>
          )
        case 'filler_id':
          const fillerId = data?.fillerId
          return (
            <RowContainer key={i}>
              <Tooltip label={fillerId} position="bottom">
                <span>{fillerId}</span>
              </Tooltip>
            </RowContainer>
          )
        case 'short_id':
          const shortId = `LS-${data?.shortId}`
          return (
            <RowContainer key={i}>
              <Tooltip label={shortId} position="bottom">
                <span>
                  <Badge color={getBadgeStatusColor(shortId)}>{`LS-${data?.shortId.slice(-5)}`}</Badge>
                </span>
              </Tooltip>
            </RowContainer>
          )
        case 'name':
          const name = data?.name ?? ''
          return (
            <RowContainer key={i}>
              <Tooltip label={name} position="bottom">
                <span>{name}</span>
              </Tooltip>
            </RowContainer>
          )
        case 'provider_name':
          const providerName = get(data, 'provider.name') ?? '--'
          return (
            <RowContainer key={i}>
              <Tooltip label={providerName} position="bottom">
                <span>{providerName}</span>
              </Tooltip>
            </RowContainer>
          )
        case 'ordered_date':
          const orderedDate = data.createdAt && format(new Date(data.createdAt), 'MM/dd/yyyy')
          return (
            <RowContainer key={i}>
              <Tooltip label={orderedDate} position="bottom">
                <span>{orderedDate}</span>
              </Tooltip>
            </RowContainer>
          )
        case 'report_date':
          const reportDate = data.createdAt && format(new Date(data.resultDate), 'MM/dd/yyyy')
          return (
            <RowContainer key={i}>
              <Tooltip label={reportDate} position="bottom">
                <span>{reportDate}</span>
              </Tooltip>
            </RowContainer>
          )

        case 'status':
          return (
            <RowContainer key={i}>
              {/* <Badge color={getBadgeStatusColor(status)} variant="filled">
                {status}
              </Badge> */}
              <div className={style.status} key={i}>
                <RowContainer
                  className={classnames('ui', 'label', {
                    violet: status === 'ordered',
                    blue: status === 'started',
                    grey: status === 'partial',
                    green: status === 'completed',
                    pink: status === 'final',
                  })}
                >
                  {capitalize(get(data, 'status').replace(/_/g, ' '))}
                </RowContainer>
              </div>
            </RowContainer>
          )
        case 'reviewed':
          const reviewedDate = data?.resultReviewed?.date ? format(new Date(data.resultReviewed.date), 'MM/dd/yyyy') : '--'
          return (
            <RowContainer key={i}>
              <Tooltip label={reviewedDate} position="bottom">
                <span>{reviewedDate}</span>
              </Tooltip>
            </RowContainer>
          )
        case 'viewed':
          const viewedDate = data?.resultViewed?.date ? format(new Date(data.resultViewed.date), 'MM/dd/yyyy') : '--'
          return (
            <RowContainer key={i}>
              <Tooltip label={viewedDate} position="bottom">
                <span>{viewedDate}</span>
              </Tooltip>
            </RowContainer>
          )
        case 'trending':
          switch (status) {
            case 'ordered':
              return (
                <Tooltip label={LAB_IS_IN_ORDERED_STATUS_MESSAGE}>
                  <RowContainer key={i}>
                    <CircleIcon color={`gray`} />
                  </RowContainer>
                </Tooltip>
              )
            default:
              if (allNormal) {
                return (
                  <Tooltip label={'Normal'}>
                    <RowContainer key={i}>
                      <CircleIcon color={`green`} />
                    </RowContainer>
                  </Tooltip>
                )
              } else {
                return (
                  <Tooltip label={'Abnormal'}>
                    <RowContainer key={i}>
                      <CircleIcon color={`orange`} />
                    </RowContainer>
                  </Tooltip>
                )
              }
          }

        case 'lab_report_pdf':
          return status === 'ordered' ? (
            <Tooltip label={LAB_IS_IN_ORDERED_STATUS_MESSAGE}>
              <RowContainer onClick={openPDF}>
                <FontAwesomeIcon icon={faFilePdf} className={style.s20} />
              </RowContainer>
            </Tooltip>
          ) : (
            <RowContainer onClick={openPDF} key={i}>
              <FontAwesomeIcon icon={faFilePdf} className={style.s20} />
            </RowContainer>
          )
        case 'place_order':
          return (
            <span>
              <Button
                className={style.orderLab}
                radius="xl"
                size="xs"
                uppercase
                variant="filled"
                onClick={(e) => openLabOrder(e, `${LAB_ORDER.new}/?clientId=${clientId}`)}
              >
                Order Labs
              </Button>
            </span>
          )

        default:
          break
      }
    }
  })
}
