import React from 'react'
import { ClientDetails } from '@labsavvyapp/components'
import { useCreateLabOrderBusinessLogic } from '../../logic'
import { generatePath, useHistory } from 'react-router'
import { CLIENTS } from '../../../../config/routes'
import styled from '@emotion/styled'

const PatientDetailsContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-top: 50px;
  margin-left: 30px;
`
export const PatientDetails = () => {
  const { push } = useHistory()

  const businessLogic = useCreateLabOrderBusinessLogic()
  const selectedPatient = businessLogic?.selectedPatient ?? null
  const selectedPaymentMethod = businessLogic?.selectedPaymentMethod ?? null

  return (
    <PatientDetailsContainer>
      <ClientDetails
        _id={selectedPatient?._id}
        data={selectedPatient?.data}
        otherData={{
          orderLabel: selectedPaymentMethod?.text ?? '',
        }}
        clientDataPageLink={() =>
          push(
            generatePath(CLIENTS.view, {
              clientId: selectedPatient?._id,
            }),
          )
        }
      />
    </PatientDetailsContainer>
  )
}
