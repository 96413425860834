import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Icon, Grid, Message, Table } from 'semantic-ui-react'
import { GetSpecimenContainerSequence } from '../../../../graphql/configs/queries'
import { useCreateLabOrderBusinessLogic } from '../../logic'
import styled from '@emotion/styled'
import { SelectedTestList } from '../StepSelectTests/components'
import { metaContainers, metaSpecimens } from './utils'
import { generateOrderCodes, calculatePricing } from '../../utils'
import formatterPrice from '../../../../utils/formatter-price'

const StepReviewLabOrderContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin: 10px 30px 10px 10px;
`

export const StepReviewLabOrder = () => {
  const businessLogic = useCreateLabOrderBusinessLogic()
  const selectedIcdCodes = businessLogic?.selectedIcdCodes ?? []
  const selectedLaboratory = businessLogic?.selectedLaboratory ?? null
  const selectedCompendiaPanels = businessLogic?.selectedCompendiaPanels ?? []
  const selectedOrderSets = businessLogic?.selectedOrderSets ?? []
  const orderCodes = generateOrderCodes(selectedCompendiaPanels, selectedOrderSets)

  const partnerMiscPricing = businessLogic?.partnerMiscPricing ?? []
  const showPricing = businessLogic?.me?.partner?.config?.ordering?.enable_misc_fees ?? false

  const { data: drawOrder } = useQuery(GetSpecimenContainerSequence, {
    variables: {
      providerId: selectedLaboratory?._id,
      orderCodes: orderCodes,
    },
    skip: !selectedLaboratory,
  })

  const metaData = drawOrder?.getSpecimenContainerSequence || []

  const { subTotal, miscPrice, totalPrice } = calculatePricing(partnerMiscPricing, selectedCompendiaPanels, selectedOrderSets)

  return (
    <StepReviewLabOrderContainer>
      <Message warning attached="bottom">
        <Icon name="warning" />
        Review the following order before submitting.
      </Message>

      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <Table color="pink" key="pink" striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Test Name</Table.HeaderCell>
                  <Table.HeaderCell style={{ textAlign: 'center' }}>Draw Order</Table.HeaderCell>
                  <Table.HeaderCell>Container(s)</Table.HeaderCell>
                  <Table.HeaderCell>Specimen</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {metaData?.map((panel: any) => (
                  <Table.Row key={panel.code}>
                    <Table.Cell>{panel.name}</Table.Cell>
                    <Table.Cell style={{ textAlign: 'center' }}>{panel.draw_order}</Table.Cell>
                    <Table.Cell>{metaContainers(panel?.meta?.containers, panel?.meta?.containers_external)}</Table.Cell>
                    <Table.Cell>{metaSpecimens(panel?.meta?.specimens)}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>

            <Table color="olive" key="olive" striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan="2">ICD-10 Code and Diagnosis</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {selectedIcdCodes.length ? (
                  selectedIcdCodes.map((icd) => (
                    <Table.Row key={icd.code}>
                      <Table.Cell width={2}>{icd.code}</Table.Cell>
                      <Table.Cell width={14}>{icd.text}</Table.Cell>
                    </Table.Row>
                  ))
                ) : (
                  <Table.Row>
                    <Table.Cell>
                      <Message icon="inbox" content="No ICD codes selected" />
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </Grid.Column>

          <Grid.Column width={8}>
            <Table color="blue" key="blue" striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan="2">Selected Tests</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                <Table.HeaderCell colSpan="2">
                  <SelectedTestList hideCheckbox={true} />
                </Table.HeaderCell>
              </Table.Body>

              {showPricing && (
                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell>
                      <strong>Subtotal</strong>
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">
                      <strong>{formatterPrice(subTotal)}</strong>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              )}
            </Table>

            {showPricing && (
              <Table color="violet" key="violet" striped>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Miscellaneous Fees</Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">{formatterPrice(miscPrice)}</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {partnerMiscPricing.length > 0 ? (
                    partnerMiscPricing.map(({ name, retail_price }: { name: string; retail_price: string }, key: number) => (
                      <Table.Row key={key}>
                        <Table.Cell>{name}</Table.Cell>
                        <Table.Cell textAlign="right">{formatterPrice(retail_price)}</Table.Cell>
                      </Table.Row>
                    ))
                  ) : (
                    <Table.Row>
                      <Table.Cell colSpan="2">
                        <Message icon="inbox" content="No Miscellaneous Fees Found" />
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>

                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell>
                      <strong>Total Price</strong>
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">
                      <strong>{formatterPrice(totalPrice)}</strong>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </StepReviewLabOrderContainer>
  )
}
