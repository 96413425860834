import React, { createContext, useContext } from 'react'
import { LabOrdersContextContract } from './LabOrdersContextContract'

const LabOrdersContext = createContext<LabOrdersContextContract | null>(null)

export const useLabOrdersViewModel = (): LabOrdersContextContract | null => {
  return useContext(LabOrdersContext)
}

interface ComponentProps extends React.ComponentProps<any>, LabOrdersContextContract {}

export const LabOrdersContextProvider: React.FunctionComponent<ComponentProps> = (
  props: React.PropsWithChildren<ComponentProps>,
) => {
  const { children, ...rest } = props

  return <LabOrdersContext.Provider value={rest}>{children}</LabOrdersContext.Provider>
}
