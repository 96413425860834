import { useMutation } from 'react-apollo'
import { GetMe } from '../../../graphql/user/queries'
import { SetActivePartner } from '../../../graphql/partners/mutations'
import { setCookie } from '../../../utils/auth'
import { ListLabOrders } from '../../../graphql/lab-orders/queries'
import { ListPatients } from '../../../graphql/clients/queries'

/**
 *  Query to get a save active partner
 * @returns
 */

export const useMutationSetActivePartnerApi = () => {
  const [setActivePartner, { data: setActivePartnerData, loading: setActivePartnerLoading, error: setActivePartnerError }] = useMutation(
    SetActivePartner,
    {
      onCompleted: ({ setActivePartner }) => {
        // Update cookie with the new access token
        setCookie(setActivePartner.access_token)
      },
      refetchQueries: [
        {
          query: GetMe,
        },
        {
          query: ListLabOrders,
        },
        {
          query: ListPatients,
        },
      ],
    },
  )

  return [setActivePartner, { data: setActivePartnerData, loading: setActivePartnerLoading, error: setActivePartnerError }] as const
}
