import { useMutation } from 'react-apollo'
import { GetMe } from '../../../graphql/user/queries'
import { SetActiveProject } from '../../../graphql/partners/mutations'
import { setCookie } from '../../../utils/auth'
import { ListLabOrders } from '../../../graphql/lab-orders/queries'
import { ListPatients } from '../../../graphql/clients/queries'

/**
 *  Query to get a save active project
 * @returns
 */

export const useMutationSetActiveProjectApi = () => {
  const [setActiveProject, { data: setActiveProjectData, loading: setActiveProjectLoading, error: setActiveProjectError }] = useMutation(
    SetActiveProject,
    {
      onCompleted: ({ setActiveProject }) => {
        // Update cookie with the new access token
        setCookie(setActiveProject.access_token)
      },
      refetchQueries: [
        {
          query: GetMe,
        },
        {
          query: ListLabOrders,
        },
        {
          query: ListPatients,
        },
      ],
    },
  )

  return [setActiveProject, { data: setActiveProjectData, loading: setActiveProjectLoading, error: setActiveProjectError }] as const
}
