import { PartnerCompendiumPanel, ProjectPackage } from '@labsavvyapp/components'
import { COMPENDIA_PANEL_IDENTIFIER, ORDER_SET_IDENTIFIER } from '../constants'

export const generateTransferListData = (packageListData: ProjectPackage[], partnerCompendiaData: PartnerCompendiumPanel[]) => {
  if (packageListData && partnerCompendiaData) {
    const packageListDataTransferListData = packageListData?.map((packageList) => ({
      value: `${packageList.package._id}${ORDER_SET_IDENTIFIER}`, // identifier for ordersets
      label: ` ${packageList.name}`,
    }))

    const partnerCompendiaTransferListData = partnerCompendiaData?.map((partnerCompendia) => ({
      value: `${partnerCompendia.code}${COMPENDIA_PANEL_IDENTIFIER}`, // identifier for compendia panels
      label: `${partnerCompendia.name}`,
      code: `${partnerCompendia.code}`,
    }))

    const allTestData = packageListDataTransferListData.concat(partnerCompendiaTransferListData)
    const allUniqueTestData = allTestData.filter((item, index, self) => index === self.findIndex((obj) => obj.value === item.value))

    return allUniqueTestData
  } else {
    return []
  }
}
