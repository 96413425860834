import React from 'react'
import { Card } from '@labsavvyapp/ui-components'
import styled from '@emotion/styled'

/**
 * List Row component.
 */

const StyledCard = styled(Card)`
  display: grid;
  grid-template-columns: ${(props) => `repeat(${props.numberOfColumns}, 1fr)`};
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 0px;

  height: 64px;
  margin-bottom: 10px;
  color: var(--textColor);

  padding: 10px 20px;
  align-items: center;
  align-content: center;
`

interface ListRowDataProps {
  numberOfColumns: number
}

interface ListRowProps extends ListRowDataProps {
  as: any
  bordered: boolean
  children: any
  to: any
}
export const ListRow = (props: ListRowProps) => {
  const { as, bordered, children, to, numberOfColumns } = props
  return (
    <StyledCard {...props} as={as} to={to} bordered={bordered} numberOfColumns={numberOfColumns}>
      {children}
    </StyledCard>
  )
}
