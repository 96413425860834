import React from 'react'
import { Icon, Popup } from 'semantic-ui-react'
import { REVIEWED_COLUMN_INFO_MESSAGE } from '../constants'

export const generateDynamicLabReportColumns = (columns, listStyle, consumerTitle) => {
  let tempColumns = []

  columns.map((partnerColumn) => {
    const parsedPartnerColumn = JSON.parse(partnerColumn)
    if (parsedPartnerColumn.is_active) {
      switch (parsedPartnerColumn.column_key) {
        case 'patient_name':
          tempColumns.push({
            name: consumerTitle === 'Client' ? 'Patient Name' : consumerTitle,
            className: `${listStyle.user} ${listStyle.th}`,
            code: parsedPartnerColumn.column_key,
            isActive: parsedPartnerColumn.is_active,
          })
          break
        case 'dob':
          tempColumns.push({
            name: parsedPartnerColumn.column_name, // DOB
            className: `${listStyle.accession} ${listStyle.th}`,
            code: parsedPartnerColumn.column_key,
            isActive: parsedPartnerColumn.is_active,
          })
          break
        case 'filler_id':
          tempColumns.push({
            name: parsedPartnerColumn.column_name, // Accession #
            className: `${listStyle.accession} ${listStyle.th}`,
            code: parsedPartnerColumn.column_key,
            isActive: parsedPartnerColumn.is_active,
          })
          break
        case 'short_id':
          tempColumns.push({
            name: parsedPartnerColumn.column_name, // Order ID
            className: `${listStyle.orderid} ${listStyle.th}`,
            code: parsedPartnerColumn.column_key,
            isActive: parsedPartnerColumn.is_active,
          })
          break
        case 'name':
          tempColumns.push({
            name: parsedPartnerColumn.column_name, // Report Name
            className: `${listStyle.reportName} ${listStyle.th}`,
            code: parsedPartnerColumn.column_key,
            isActive: parsedPartnerColumn.is_active,
          })
          break
        case 'provider_name':
          tempColumns.push({
            name: parsedPartnerColumn.column_name, // Provider Name
            className: `${listStyle.organization} ${listStyle.th}`,
            code: parsedPartnerColumn.column_key,
            isActive: parsedPartnerColumn.is_active,
          })
          break
        case 'ordered_date':
          tempColumns.push({
            name: parsedPartnerColumn.column_name, // Ordered Date
            className: `${listStyle.ordered} ${listStyle.th}`,
            code: parsedPartnerColumn.column_key,
            isActive: parsedPartnerColumn.is_active,
          })
          break
        case 'report_date':
          tempColumns.push({
            name: parsedPartnerColumn.column_name, // Ordered Date
            className: `${listStyle.ordered} ${listStyle.th}`,
            code: parsedPartnerColumn.column_key,
            isActive: parsedPartnerColumn.is_active,
          })
          break
        case 'status':
          tempColumns.push({
            name: parsedPartnerColumn.column_name, // Status
            className: `${listStyle.status} ${listStyle.th}`,
            code: parsedPartnerColumn.column_key,
            isActive: parsedPartnerColumn.is_active,
          })
          break
        case 'reviewed':
          tempColumns.push({
            name: (
              <>
                {parsedPartnerColumn.column_name}
                <Popup trigger={<Icon name="info circle" />} content={REVIEWED_COLUMN_INFO_MESSAGE} basic />
              </>
            ),
            className: `${listStyle.reviewed} ${listStyle.th}`,
            code: parsedPartnerColumn.column_key,
            isActive: parsedPartnerColumn.is_active,
          })
          break
        case 'viewed':
          tempColumns.push({
            name: (
              <>
                {parsedPartnerColumn.column_name}
                <Popup
                  trigger={<Icon name="info circle" />}
                  content={`Viewed: the timestamp when the ${consumerTitle.toLowerCase()} opens the lab report for the first time.`}
                  basic
                />
              </>
            ),
            className: `${listStyle.viewed} ${listStyle.th}`,
            code: parsedPartnerColumn.column_key,
            isActive: parsedPartnerColumn.is_active,
          })
          break
        case 'trending':
          tempColumns.push({
            name: parsedPartnerColumn.column_name, // Trending
            className: `${listStyle.trending} ${listStyle.th}`,
            code: parsedPartnerColumn.column_key,
            isActive: parsedPartnerColumn.is_active,
          })
          break
        case 'lab_report_pdf':
          tempColumns.push({
            name: parsedPartnerColumn.column_name, // Lab Report
            className: `${listStyle.labReport} ${listStyle.th}`,
            code: parsedPartnerColumn.column_key,
            isActive: parsedPartnerColumn.is_active,
          })
          break
        case 'place_order':
          tempColumns.push({
            name: parsedPartnerColumn.column_name, // Place Order
            className: `${listStyle.orderLab} ${listStyle.th}`,
            code: parsedPartnerColumn.column_key,
            isActive: parsedPartnerColumn.is_active,
          })
          break

        default:
          break
      }
    }
  })

  return tempColumns
}
