import React from 'react'
import { SanitizedHTML } from '@labsavvyapp/ui-components'
import style from './LabReportTrendHeader.module.css'
import { Grid, Icon } from 'semantic-ui-react'
import isAnEnumUnits from '../../../utils/is-an-enum-units'
import capitalize from '../../../utils/capitalize'
import { generatePath } from 'react-router'
import { LAB_REPORTS } from '../../../config/routes'
import { NavLink, useParams } from 'react-router-dom'

export default function LabReportTrendHeader({
  children,
  test,
  subtitle,
  summary,
  style: outStyle,
}) {
  const { labReportId } = useParams()
  return (
    <div className={style.container} style={outStyle}>
      <div className={style.category}>
        <Grid.Column>
          <span className={style.testName}>{test.name}</span>
        </Grid.Column>
        <Grid.Column>
          <span>
            <strong>{`Patient's Result: `}</strong>
          </span>
          <span
            className={
              (test.value === null && style.greyFontColor) ||
              (test.status === 'normal' && style.greenFontColor) ||
              (test.status === 'borderline' && style.yellowFontColor) ||
              (test.status === 'abnormal' && style.orangeFontColor) ||
              style.greyFontColor
            }
          >
            {(isAnEnumUnits(test.units) && capitalize(test.value)) ||
              test.value}{' '}
            {!isAnEnumUnits(test.units) && test.units}
          </span>
        </Grid.Column>
        <Grid.Column>
          <span>{`Reference: `}</span>
          <span>
            {(isAnEnumUnits(test.units) && capitalize(test.refRange)) ||
              test.refRange}{' '}
            {!isAnEnumUnits(test.units) && test.units}
          </span>
        </Grid.Column>
        <Grid.Column>
          <NavLink
            data-test="link-result"
            to={generatePath(LAB_REPORTS.resultDetails, {
              labReportId,
              resultId: test.id,
            })}
          >
            View Education
            <Icon name="angle double right"></Icon>
          </NavLink>
        </Grid.Column>
      </div>
      {(subtitle || summary) && (
        <div className={style.categoryInfo}>
          {subtitle ? <h3>{subtitle}</h3> : null}
          {summary ? (
            <p>
              <SanitizedHTML text={summary} />
            </p>
          ) : null}
        </div>
      )}
      <div>{children}</div>
    </div>
  )
}
