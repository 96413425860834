import React from 'react'

const ReproSource = (provider) => (
  <ol>
    <li>
      <b>Schedule</b> an appointment with a nearby{' '}
      <a href="https://www.reprosource.com/doctors-clinicians/hours-holidays-2/" target="_blank" rel="noopener noreferrer">
        {provider.text} location
      </a>
    </li>
    <li>
      <b>It’s recommended that you print</b> your lab order for all tests performed at a patient service center. Remember, LabSavvy orders are
      prepaid, so do not make any payments to the lab and do not submit your order as a claim to your insurance provider.
    </li>
    <li style={{ marginTop: 10 }}>
      <b>Prepare</b> for your appointment. Please check with your provider to see if you need to fast prior to your test.
    </li>
  </ol>
)

export default ReproSource
