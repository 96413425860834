import { useQuery } from 'react-apollo'
import { ListInsurance } from '../../../graphql/insurance/queries'

/**
 *  Query to get list of insurance of a specific patient

 * @param (patientId)
 * @returns 
 */

interface ListInsuranceVariables {
  patient_id: string
}

export const useQueryListInsuranceApi = (variables: ListInsuranceVariables) => {
  const { patient_id } = variables

  const {
    data: insuranceListData,
    error: insuranceListError,
    loading: insuranceListLoading,
    refetch: refetchInsuranceListData,
    fetchMore: fetchMoreInsuranceListData,
  } = useQuery(ListInsurance, {
    variables: {
      patient_id: patient_id,
    },
    skip: !patient_id,
  })

  return {
    data: insuranceListData,
    error: insuranceListError,
    loading: insuranceListLoading,
    refetch: refetchInsuranceListData,
    fetchMore: fetchMoreInsuranceListData,
  }
}
