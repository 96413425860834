export const SORT_OPTIONS = Object.freeze({
  defaultSortOption: 'nameAZ',
  options: [
    {
      text: 'Name A-Z',
      queryStringValue: 'nameAZ',
      key: 'name',
      value: 'asc',
    },
    {
      text: 'Name Z-A',
      queryStringValue: 'nameZA',
      key: 'name',
      value: 'desc',
    },
  ],
})

// payment method dropdown options
export const ORDER_TYPE = Object.freeze({
  defaultOrderTypeOption: 'direct_to_lab',
  options: [
    // Submit directly to Lab
    {
      text: 'Bill to Client',
      label: 'Lab Contract Price',
      queryStringValue: 'direct_to_lab',
      key: 'order_type',
      value: '1',
    },
    // Send to Patient for Purchase
    {
      text: 'Bill to Patient',
      label: 'Patient Retail Price',
      queryStringValue: 'patient_to_purchase',
      key: 'order_type',
      value: '2',
    },
    // Third Party
    {
      text: 'Bill to Insurance',
      label: 'Bill to Insurance',
      queryStringValue: 'bill_to_insurance',
      key: 'order_type',
      value: '3',
    },
  ],
})

export const BILL_TYPE = Object.freeze({
  defaultLabTypeOption: 'client',
  options: [
    {
      text: 'Client',
      label: 'Client',
      queryStringValue: 'client',
      key: 'bill_type',
      value: 'C',
    },
    {
      text: 'Patient',
      label: 'Patient',
      queryStringValue: 'patient',
      key: 'bill_type',
      value: 'P',
    },
    {
      text: 'Third Party',
      label: 'Third Party',
      queryStringValue: 'thirdparty',
      key: 'bill_type',
      value: 'T',
    },
  ],
})

export const LAB_TYPE = Object.freeze({
  defaultLabTypeOption: 'existing_package',
  options: [
    {
      text: 'Existing Package',
      queryStringValue: 'existing_package',
      key: 'lab_type',
      value: '1',
    },
    {
      text: 'Custom Package',
      queryStringValue: 'custom_package',
      key: 'lab_type',
      value: '2',
    },
    {
      text: 'Custom Order',
      queryStringValue: 'custom_order',
      key: 'lab_type',
      value: '3',
    },
  ],
})

export const DEFAULT_PROJECT = '5e53da61f5935b001ab1a0f4'
