import { PartnerCompendiumPanel } from '@labsavvyapp/components'

export const checkCompendiaPanelAOEIfAnswered = (selectedCompendiumPanels: PartnerCompendiumPanel[]) => {
  let areAllAOEQuestionsAnswered = true

  if (!selectedCompendiumPanels.length) {
    return true
  }

  selectedCompendiumPanels.forEach((panel, i) => {
    if (panel?.aoe.length) {
      panel.aoe.forEach((aoe, i) => {
        if (!aoe.answer && aoe.is_required) {
          areAllAOEQuestionsAnswered = false
        }
      })
    }
  })

  return areAllAOEQuestionsAnswered
}
