/* eslint-disable */
import React, { useEffect } from 'react'
import { Grid, Popup, Icon } from 'semantic-ui-react'

import classnames from 'classnames'
import { useQuery } from '@apollo/react-hooks'
import { Search } from '@labsavvyapp/ui-components'
import { camelizeKeys } from 'humps'

import style from './TemplateSearch.module.css'
import { WebappListPackages } from '../../../../graphql/lab-orders/queries.js'

const LabOrderTemplate = ({ name }) => (
  <div className={style.result} data-test="template-search-result">
    <span>{name}</span>
  </div>
)

export default function TemplateSearch({
  refetch = false,
  onSelect,
  onChange,
  providerId,
  label,
  search,
}) {
  const {
    data,
    loading,
    refetch: refetchTemplates,
  } = useQuery(WebappListPackages, {
    variables: {
      providerId,
    },
    skip: providerId === '',
  })
  const templatesData = camelizeKeys(data)

  useEffect(() => {
    refetchTemplates()
  }, [refetch])

  const onSearchChange = (value) => {
    refetchTemplates({ search: value })

    if (onChange) onChange(value)
  }

  const onResultSelect = async (_, { result }) => {
    if (onSelect) {
      await onSelect((result && result.data) || {})
    }
  }

  const results =
    (templatesData &&
      templatesData.webappListPackages.packages.map((template) => ({
        key: template.package.id,
        title: '', // Semantic UI search need to have a title in order to avoid PropType warning
        data: template,
      }))) ||
    []

  return (
    <div className={style.formField}>
      <label className={style.formFieldLabel}>{label}</label>
      {results.length > 0 ? (
        <Search
          delay={300}
          placeholder="e.g. Comprehensive Physical"
          className={classnames(style.formFieldInput, {
            [style.noResults]: results.length === 0,
          })}
          loading={loading}
          results={results}
          resultRenderer={(result) => (
            <LabOrderTemplate
              name={result.data.name}
              key={result.data.package.id}
            />
          )}
          onResultSelect={onResultSelect}
          onSearchChange={onSearchChange}
          selectResultValueMapping="data.name"
          showResultsOnFocus
          showNoResults={false}
          icon=""
          searchText={search}
          disabled={!providerId}
        />
      ) : (
        <Popup
          trigger={
            <div>
              <Search
                delay={300}
                placeholder="e.g. Comprehensive Physical"
                className={classnames(style.formFieldInput, {
                  [style.noResults]: results.length === 0,
                })}
                loading={loading}
                results={results}
                resultRenderer={(result) => (
                  <LabOrderTemplate
                    name={result.data.name}
                    key={result.data.package.id}
                  />
                )}
                onResultSelect={onResultSelect}
                onSearchChange={onSearchChange}
                selectResultValueMapping="data.name"
                showResultsOnFocus
                showNoResults={false}
                icon=""
                searchText={search}
              />
            </div>
          }
          header="Please contact your LabSavvy administrator:"
          content="No order sets were setup with this lab."
          position="bottom left"
        />
      )}
    </div>
  )
}
/* eslint-enable */
