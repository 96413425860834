import { TransferListData } from '@labsavvyapp/components'

export const removeTransferListDuplicates = (
  transferListData: TransferListData,
  setTransferListData: (value: React.SetStateAction<TransferListData>) => void,
) => {
  const unselectedItems = transferListData[0]
  const selectedItems = transferListData[1]

  const updatedUnselectedItems = unselectedItems.filter(
    (unselectedItem) => !selectedItems.some((selectedItem) => unselectedItem.value === selectedItem.value),
  )
  if (updatedUnselectedItems.length !== unselectedItems.length) {
    setTransferListData([updatedUnselectedItems, selectedItems])
  }
}
