import { useQuery } from 'react-apollo'
import { GetProvider } from '../../../graphql/providers/queries'
import { Provider } from '@labsavvyapp/components'

/**
 *  Query to get a specific provider by ID

 * @param providerId 
 * @returns Provider
 */

export const useQueryGetProviderApi = (providerId: string | null) => {
  const {
    data: providerData,
    error: providerDataError,
    loading: loadingProviderData,
    refetch: refetchProviderData,
  } = useQuery(GetProvider, {
    variables: { id: providerId },
    skip: !providerId,
  })

  return {
    data: providerData?.getProvider as Provider,
    error: providerDataError,
    loading: loadingProviderData,
    refetch: refetchProviderData,
  }
}
