import gql from 'graphql-tag'

export const ListHealthPlans = gql`
  query ListHealthPlans {
    listHealthPlans {
      title
      description
    }
  }
`
