import React from 'react'
import { ClientsViewModel } from './clients'
import { LabOrdersViewModel } from './lab-orders'
import { User } from '@labsavvyapp/components'
import { ProvidersViewModel } from './provider'
import { UserViewModel } from './user/UserViewModel'

interface ComponentProps extends React.ComponentProps<any> {
  me: User
  loggedIn: boolean
}

export const LabsavvyViewModels: React.FunctionComponent<ComponentProps> = (props: React.PropsWithChildren<ComponentProps>) => {
  const { me, loggedIn } = props

  return (
    <UserViewModel me={me} loggedIn={loggedIn}>
      <ClientsViewModel me={me}>
        <LabOrdersViewModel me={me}>
          <ProvidersViewModel me={me}>{props.children}</ProvidersViewModel>
        </LabOrdersViewModel>
      </ClientsViewModel>
    </UserViewModel>
  )
}
