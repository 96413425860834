import styled from '@emotion/styled'
import { LogoutIcon, Popover, TriangleIcon, UserCircleIcon } from '@labsavvyapp/components'
import React from 'react'
import { PROFILE, SIGN } from '../../../../../../config/routes'
import { Button, Divider } from '@mantine/core'
import { useUserViewModel } from '../../../../../../viewmodels'
import { useHistory } from 'react-router-dom'

const MenuDropdown = styled.span`
  display: flex;
  flex-flow: row nowrap;
  align-content: center;
  align-items: center;
  width: 100%;
  margin-right: 10px;
  cursor: pointer;
`

const Label = styled.span`
  padding: 0px 5px 0px 10px;
  color: gray;
  font-size: 12px;
`

const MenuItem = styled.div``

export const UserMenu = () => {
  const { push } = useHistory()
  const userViewModel = useUserViewModel()
  const me = userViewModel?.me ?? null
  const loggedIn = userViewModel?.loggedIn ?? false

  const DROPDOWN_MENU_ITEMS = [
    {
      name: 'My Profile',
      url: PROFILE.base,
      showWhenLoggedIn: true,
      icon: <UserCircleIcon height={18} width={18} />,
    },
    {
      name: 'Sign Out',
      url: SIGN.out,
      showWhenLoggedIn: true,
      showDivider: true,
      icon: <LogoutIcon height={18} width={18} />,
    },
    {
      name: 'Sign in',
      url: SIGN.in,
      showWhenLoggedIn: false,
    },
    {
      name: 'Sign up',
      url: SIGN.up,
      showWhenLoggedIn: false,
    },
  ]

  return (
    <Popover width={200} position="bottom" withArrow shadow="md">
      <Popover.Target>
        <MenuDropdown>
          <Label>{me?.name?.display}</Label>
          <TriangleIcon color="pink" direction="down" opacity="1" />
        </MenuDropdown>
      </Popover.Target>
      <Popover.Dropdown>
        {DROPDOWN_MENU_ITEMS.map((menuItem, index) => (
          <>
            {loggedIn === menuItem.showWhenLoggedIn && (
              <MenuItem key={index}>
                {menuItem.showDivider && <Divider />}
                <Button radius="sm" size="sm" variant="basic" leftIcon={menuItem.icon} onClick={() => push(menuItem.url)}>
                  <Label> {menuItem.name}</Label>
                </Button>
              </MenuItem>
            )}
          </>
        ))}
      </Popover.Dropdown>
    </Popover>
  )
}
