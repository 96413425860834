import React from 'react'
import { Dimmer, Loader } from 'semantic-ui-react'
import { AddEditClientForm } from './components/AddEditClientForm/AddEditClientForm'
import { AddEditClientPageBusinessLogic } from './AddEditClientPageBusinessLogic'
import { useAddEditClientPageBusinessLogic } from './logic'

export default function AddEditClientPage() {
  const businessLogic = useAddEditClientPageBusinessLogic()
  const selectedPatientLoading = businessLogic?.selectedPatientLoading ?? false

  if (selectedPatientLoading) {
    return (
      <Dimmer active inverted>
        <Loader size="large">Loading</Loader>
      </Dimmer>
    )
  }

  return (
    <AddEditClientPageBusinessLogic>
      <AddEditClientForm />
    </AddEditClientPageBusinessLogic>
  )
}
