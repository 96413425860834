import { useQuery } from 'react-apollo'
import { GetPatient } from '../../../graphql/clients/queries'
import { Patient } from '@labsavvyapp/components'

/**
 *  Query to get a specific patient by ID

 * @param patientId 
 * @returns Patient
 */

export const useQueryGetPatientApi = (patientId?: string) => {
  const {
    data: patientData,
    error: patientDataError,
    loading: loadingPatientData,
    refetch: refetchPatientData,
  } = useQuery(GetPatient, {
    variables: { id: patientId },
    skip: !patientId,
  })

  return { data: patientData?.getPatient as Patient, error: patientDataError, loading: loadingPatientData, refetch: refetchPatientData }
}
