import { PartnerCompendiumPanel, Patient, ProjectPackage } from '@labsavvyapp/components'
import { ORDER_TYPES, PaymentMethodListDropdownOption } from '../../../viewmodels'
import { ICDCode } from '../logic'

export const checkIfOrderIsReady = (
  selectedPatient: Patient,
  selectedPaymentMethod: PaymentMethodListDropdownOption | null,
  selectedOrderSets: ProjectPackage[],
  selectedCompendiaPanels: PartnerCompendiumPanel[],
  insuranceListData: any,
) => {
  const useInsurance = selectedPaymentMethod?.value === ORDER_TYPES['Bill to Insurance'].value
  const hasSelectedTests = selectedCompendiaPanels.length > 0 || selectedOrderSets.length > 0
  const isReady = useInsurance ? hasSelectedTests && insuranceListData?.listInsurance.length : hasSelectedTests

  return isReady && selectedPatient
}
