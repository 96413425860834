export const generateMiscPricing = (partnerMiscPricingData: any) =>
  (partnerMiscPricingData &&
    partnerMiscPricingData.listPartnerMiscPricing.misc_pricing.map(
      ({ name, code, contract_price, retail_price }: { name: string; code: string; contract_price: number; retail_price: number }) => ({
        name,
        code,
        contract_price,
        retail_price,
      }),
    )) ||
  []
