import React, { createContext, useContext } from 'react'
import { ProvidersContextContract } from './ProvidersContextContract'

const ProvidersContext = createContext<ProvidersContextContract | null>(null)

export const useProvidersViewModel = (): ProvidersContextContract | null => {
  return useContext(ProvidersContext)
}

interface ComponentProps extends React.ComponentProps<any>, ProvidersContextContract {}

export const ProvidersContextProvider: React.FunctionComponent<ComponentProps> = (
  props: React.PropsWithChildren<ComponentProps>,
) => {
  const { children, ...rest } = props

  return <ProvidersContext.Provider value={rest}>{children}</ProvidersContext.Provider>
}
