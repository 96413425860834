import React from 'react'
import styled from '@emotion/styled'

const ListHeaderContainer = styled.div<ListHeaderProps>`
  display: grid;
  grid-template-columns: ${(props) => `repeat(${props.columns.length}, 1fr)`};
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 0px;
  background-color: #e9e9e9;
  padding: 16px 30px 9px 30px;
  margin-bottom: 15px;
`

const ColumnCell = styled.div`
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

interface ListHeaderProps {
  columns: any[]
}

export const ListColumnHeader = (props: ListHeaderProps) => {
  const { columns } = props
  return (
    <ListHeaderContainer {...props}>
      {columns.map((column, id) => (
        <ColumnCell>
          <span key={id} className={column.className}>
            {column.name}
          </span>
        </ColumnCell>
      ))}
    </ListHeaderContainer>
  )
}
