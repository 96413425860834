import gql from 'graphql-tag'

export const ListICDs = gql`
  query ListICDs($filter: ICDFilter, $sort: ICDSort = { code: asc }, $limit: PositiveInt = 20, $page: NonNegativeInt = 1) {
    listICDs(filter: $filter, sort: $sort, limit: $limit, page: $page) {
      icds {
        code
        text
      }
      page
      pages
      total
    }
  }
`
