import { useQuery } from 'react-apollo'
import { ListPartnerMiscPricing } from '../../../graphql/partners/queries.js'

/**
 *  Query to get partner misc pricing

 * @param (partner_id, provider_id)
 * @returns 
 */

interface MiscPricingVariables {
  partner_id: string
  provider_id: string
}

export const useQueryListPartnerMiscPricingApi = (variables: MiscPricingVariables) => {
  const { partner_id, provider_id } = variables

  const {
    data: miscPricingData,
    error: miscPricingError,
    loading: miscPricingLoading,
    refetch: refetchMiscPricingData,
    fetchMore: fetchMoreMiscPricingData,
  } = useQuery(ListPartnerMiscPricing, {
    variables: {
      partnerId: partner_id,
      providerId: provider_id,
    },
    skip: !provider_id,
  })

  return {
    data: miscPricingData,
    error: miscPricingError,
    loading: miscPricingLoading,
    refetch: refetchMiscPricingData,
    fetchMore: fetchMoreMiscPricingData,
  }
}
