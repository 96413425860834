import React from 'react'
import { Label } from 'semantic-ui-react'

export const metaSpecimens = (specimens: any) => (
  <>
    {specimens?.map((specimen: any, i: any) => (
      <Label horizontal style={{ background: 'none', margin: 0, textAlign: 'left' }} key={i}>
        {specimen?.name}
      </Label>
    ))}
  </>
)
