import React from 'react'
import { Segment } from 'semantic-ui-react'
import { DateInput, Grid, InfoCircleFilledIcon, Select, TextInput, Tooltip } from '@labsavvyapp/components'

import style from './FormPage.module.css'

import { useAddEditClientPageBusinessLogic } from '../../logic'
import styled from '@emotion/styled'
import { ConsumerTitle, FormButtonControls, FormModals, ProfilePhotoUpload } from './components'

const AddEditClientFormLayout = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
`

const Fieldset = styled.fieldset`
  border-radius: 5px;
  border-color: #e5e5e5;
  border-style: solid;
  border-width: 1px;
`

const Legend = styled.legend`
  font-weight: 700;
`

export const AddEditClientForm = () => {
  const businessLogic = useAddEditClientPageBusinessLogic()
  const form = businessLogic?.form ?? null
  const genderOptions = businessLogic?.genderOptions ?? []
  const raceOptions = businessLogic?.raceOptions ?? []
  const ethnicityOptions = businessLogic?.ethnicityOptions ?? []
  const stateOptions = businessLogic?.stateOptions ?? []
  const phoneTypeOptions = businessLogic?.phoneTypeOptions ?? []

  return (
    <AddEditClientFormLayout>
      <Segment className={style.segment}>
        <ConsumerTitle />

        <Grid>
          <Grid.Col sm={12} lg={3}>
            <ProfilePhotoUpload />
          </Grid.Col>
          <Grid.Col sm={12} lg={9}>
            <Fieldset>
              <Legend>Personal Information</Legend>
              <Grid>
                <Grid.Col sm={12} lg={6}>
                  <TextInput label="First Name" placeholder="First Name" withAsterisk {...form?.getInputProps('first_name')} />
                </Grid.Col>
                <Grid.Col sm={12} lg={6}>
                  <TextInput label="Last Name" placeholder="Last Name" withAsterisk {...form?.getInputProps('last_name')} />
                </Grid.Col>
                <Grid.Col sm={12} lg={3}>
                  <DateInput
                    label="Date of Birth (MM/DD/YYYY)"
                    placeholder="Enter MM/DD/YYYY, YYYYMMDD or MMMM D, YYYY formats (e.g. 01/19/1999, 19990119 or January 19, 1999"
                    withAsterisk
                    {...form?.getInputProps('dob')}
                  />
                </Grid.Col>
                <Grid.Col lg={3}>
                  <Select
                    label="Gender"
                    placeholder="Select Gender"
                    data={genderOptions}
                    searchable
                    withAsterisk
                    {...form?.getInputProps('gender')}
                  />
                </Grid.Col>
                <Grid.Col lg={3}>
                  <Select label="Race" placeholder="Select Race" data={raceOptions} searchable withAsterisk {...form?.getInputProps('race')} />
                </Grid.Col>
                <Grid.Col lg={3}>
                  <Select
                    label="Ethnicity"
                    placeholder="Select Ethnicity"
                    data={ethnicityOptions}
                    searchable
                    withAsterisk
                    {...form?.getInputProps('ethnicity')}
                  />
                </Grid.Col>
                <Grid.Col sm={6} lg={3}>
                  <TextInput label="Phone Number" placeholder="Enter Phone Number" withAsterisk {...form?.getInputProps('phone_number')} />
                </Grid.Col>
                <Grid.Col sm={6} lg={3}>
                  <Select label="Type" placeholder="Select Phone Type" data={phoneTypeOptions} searchable {...form?.getInputProps('phone_type')} />
                </Grid.Col>
                <Grid.Col lg={6}>
                  <TextInput label="Email Address" placeholder="Enter Email Address" withAsterisk {...form?.getInputProps('email')} />
                </Grid.Col>
              </Grid>
            </Fieldset>
          </Grid.Col>

          <Grid.Col span={12}>
            <Fieldset>
              <Legend>Street Address</Legend>
              <Grid>
                <Grid.Col lg={6}>
                  <TextInput label="Address" placeholder="Enter Street Address" withAsterisk {...form?.getInputProps('street')} />
                </Grid.Col>
                <Grid.Col lg={2}>
                  <TextInput label="City" placeholder="Enter City" withAsterisk {...form?.getInputProps('city')} />
                </Grid.Col>
                <Grid.Col lg={2}>
                  <Select label="State" placeholder="Select State" data={stateOptions} searchable withAsterisk {...form?.getInputProps('state')} />
                </Grid.Col>
                <Grid.Col lg={2}>
                  <TextInput label="ZIP" placeholder="Enter ZIP" withAsterisk {...form?.getInputProps('zip')} />
                </Grid.Col>
              </Grid>
            </Fieldset>
          </Grid.Col>

          <Grid.Col span={12}>
            <Fieldset>
              <Legend>Billing Address</Legend>
              <Grid>
                <Grid.Col lg={6}>
                  <TextInput label="Address" placeholder="Enter Billing Address" {...form?.getInputProps('address_billing_street')} />
                </Grid.Col>
                <Grid.Col lg={2}>
                  <TextInput label="City" placeholder="Enter City" {...form?.getInputProps('address_billing_city')} />
                </Grid.Col>
                <Grid.Col lg={2}>
                  <Select label="State" placeholder="Select State" data={stateOptions} searchable {...form?.getInputProps('address_billing_state')} />
                </Grid.Col>
                <Grid.Col lg={2}>
                  <TextInput label="ZIP" placeholder="Enter ZIP" {...form?.getInputProps('address_billing_zip')} />
                </Grid.Col>
              </Grid>
            </Fieldset>
          </Grid.Col>

          <Grid.Col span={12}>
            <Fieldset>
              <Legend>Shipping Address</Legend>
              <Grid>
                <Grid.Col lg={6}>
                  <TextInput label="Address" placeholder="Enter Shipping Address" {...form?.getInputProps('address_shipping_street')} />
                </Grid.Col>
                <Grid.Col lg={2}>
                  <TextInput label="City" placeholder="Enter City" {...form?.getInputProps('address_shipping_city')} />
                </Grid.Col>
                <Grid.Col lg={2}>
                  <Select
                    label="State"
                    placeholder="Select State"
                    data={stateOptions}
                    searchable
                    {...form?.getInputProps('address_shipping_state')}
                  />
                </Grid.Col>
                <Grid.Col lg={2}>
                  <TextInput label="ZIP" placeholder="Enter ZIP" {...form?.getInputProps('address_shipping_zip')} />
                </Grid.Col>
              </Grid>
            </Fieldset>
          </Grid.Col>
        </Grid>
      </Segment>

      <FormButtonControls />
      <FormModals />
    </AddEditClientFormLayout>
  )
}
