import React from 'react'
import classnames from 'classnames'
import { Link, generatePath } from 'react-router-dom'
import style from './LabReportList.module.css'
import { LAB_REPORTS } from '../../../config/routes'
import { generateDynamicLabReportRowData } from './utils'
import { ListRow } from './components'
import { useProvidersViewModel } from '../../../viewmodels'

export default function ReportRow({ data, columnsToDisplay }) {
  const { id: labReportId } = data

  const providersViewModel = useProvidersViewModel()
  const providerList = providersViewModel?.providerList ?? []
  const updatedData = { ...data, provider: providerList.find((provider) => provider._id === data?.provider?.id) }
  const rowData = generateDynamicLabReportRowData(columnsToDisplay, style, updatedData)

  return (
    <ListRow
      className={classnames(style.listRow, {
        [style.unreleased]: data.hasUnreleasedResults,
      })}
      as={Link}
      to={{
        pathname: generatePath(LAB_REPORTS.view, { labReportId }),
        state: { prevPath: window.location.pathname },
      }}
      bordered={false}
      key={`${labReportId}_${Math.random}`}
      numberOfColumns={rowData?.length}
    >
      {rowData}
    </ListRow>
  )
}
