export const showQuestResults = (data) => {
  const byteCharacters = atob(data)
  const byteNumbers = new Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }
  const byteArray = new Uint8Array(byteNumbers)
  const file = new Blob([byteArray], { type: 'application/pdf;base64' })
  const fileURL = URL.createObjectURL(file)
  window.open(fileURL)
}

export const getQueryVariables = (sortBy, search, options) => {
  const variables = { sort: {}, filter: {} }

  if (sortBy) {
    const selectSortOption = options.find(
      (option) => option.queryStringValue === sortBy,
    )

    variables.sort[selectSortOption.key] = selectSortOption.value
  }

  if (search) {
    variables.filter.query = search
  }

  return variables
}

export const getBrightness = (hex) => {
  const rgb = parseInt(hex, 16)
  const r = (rgb >> 16) & 0xff
  const g = (rgb >> 8) & 0xff
  const b = (rgb >> 0) & 0xff

  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b

  return luma > 200
}
