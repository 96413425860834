/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useQuery } from '@apollo/react-hooks'
import { camelizeKeys } from 'humps'

// Styiling
import style from './LabReportListPage.module.css'
import listStyle from './LabReportList.module.css'

// Components
import { Icon, Popup } from 'semantic-ui-react'
import { InfiniteScrollList, List, ListLoader, ListEmpty } from '@labsavvyapp/ui-components'
import ListPageHeader from '../../../components/ListPageHeader/ListPageHeader'
import ReportRow from './ReportRow'

// Utilities
import { fetchNext, hasMore, isEmpty, updateURLParameter } from '@labsavvyapp/ui-components/lib/utils'
import { useDidUpdateEffect } from '../../../utils/custom-hooks'
import capitalize from '../../../utils/capitalize'
import get from 'lodash/get'
import { generateDynamicLabReportColumns } from './utils'

// Constants
import { LAB_REPORTS } from '../../../config/routes'
import {
  DEFAULT_LAB_REPORT_COLUMN_VIEW,
  FILTER_OPTIONS,
  NO_COLUMNS_CONFIGURED_MESSAGE,
  NO_LAB_REPORTS_FOUND_MESSAGE,
  SORT_OPTIONS,
} from './constants'

// Queries and Mutations
import { ListLabOrders } from '../../../graphql/lab-orders/queries.js'
import { GetMe } from '../../../graphql/user/queries.js'
import { ListColumnHeader } from './components'
import { useUserViewModel } from '../../../viewmodels'

function getQueryVariables(sortBy, search, filterBy) {
  const variables = {
    limit: 30,
    sort: {},
    filter: {},
    page: 1,
  }

  switch (sortBy) {
    case SORT_OPTIONS.nameAsc.key:
      variables.sort.name = SORT_OPTIONS.nameAsc.value
      break
    case SORT_OPTIONS.nameDesc.key:
      variables.sort.name = SORT_OPTIONS.nameDesc.value
      break

    case SORT_OPTIONS.dateAsc.key:
      variables.sort.created_at = SORT_OPTIONS.dateAsc.value
      break
    case SORT_OPTIONS.dateDesc.key:
      variables.sort.created_at = SORT_OPTIONS.dateDesc.value
      break

    case SORT_OPTIONS.statusAsc.key:
      variables.sort.status = SORT_OPTIONS.statusAsc.value
      break
    default:
      break
  }

  if (filterBy) {
    switch (filterBy) {
      case FILTER_OPTIONS.resultViewed.key:
        variables.filter = { name: search, viewed: true }
        break
      case FILTER_OPTIONS.resultNotViewed.key:
        variables.filter = { name: search, viewed: false }
        break
      default:
        variables.filter = { name: search }
        break
    }
  }

  return variables
}

export default function LabReportListPage({ me }) {
  const { push } = useHistory()
  const userViewModel = useUserViewModel()
  const { search: locationSearch } = useLocation()

  const query = new URLSearchParams(locationSearch)
  const searchParameter = query.get('search') || ''
  const sortByParameter = query.get('sort') || SORT_OPTIONS.dateAsc.key
  const filterByParameter = query.get('filter') || FILTER_OPTIONS.allResults.key

  const [search, setSearch] = useState(searchParameter)
  const [sortBy, setSortBy] = useState(sortByParameter)
  const [filterBy, setFilterBy] = useState(filterByParameter)
  const [partnerId, setPartnerId] = useState(userViewModel?.selectedPartner?._id)
  const [projectId, setProjectId] = useState(userViewModel?.selectedProject?._id)
  const [columns, setColumns] = useState([])

  // Fetch user information
  const { data: userData } = useQuery(GetMe)

  // const me = userViewModel?.me ?? null
  const parsedUserData = camelizeKeys(userData)
  const consumerTitle = capitalize(userViewModel?.consumerTitle)
  const selectedPartner = userViewModel?.selectedPartner ?? null
  const selectedProject = userViewModel?.selectedProject ?? null

  useEffect(() => {
    if (partnerId !== selectedPartner?._id) {
      setPartnerId(selectedPartner?._id)
    }
    if (projectId !== selectedProject?._id) {
      setProjectId(selectedProject?._id)
    }
    const columns = userViewModel?.selectedPartner?.config?.lab_report?.columns ?? []

    const partnerColumns = columns.length > 0 ? columns : DEFAULT_LAB_REPORT_COLUMN_VIEW
    const generatedLabReportColumns = generateDynamicLabReportColumns(partnerColumns, listStyle, consumerTitle)

    if (generatedLabReportColumns.length) {
      setColumns(generatedLabReportColumns)
    }
  }, [selectedPartner, selectedProject])

  const { data, loading, fetchMore, refetch } = useQuery(ListLabOrders, {
    notifyOnNetworkStatusChange: true,
    variables: {
      ...getQueryVariables(sortByParameter, searchParameter, filterByParameter),
    },
  })
  const labOrdersData = camelizeKeys(data)

  useEffect(() => {
    refetch({
      page: 1,
      ...getQueryVariables(sortBy, search, filterBy),
    })
  }, [sortBy, search, filterBy])

  // Refetch data if partner or project change
  useDidUpdateEffect(() => {
    refetch({
      page: 1,
      ...getQueryVariables(sortBy, search, filterBy),
    })
  }, [partnerId, projectId, filterBy])

  function handleSortChange(_, { value }) {
    const urlParams = updateURLParameter('sort', value)
    setSortBy(value)
    push(`${LAB_REPORTS.base}?${urlParams}`)
  }

  function handleFilterChange(_, { value }) {
    const urlParams = updateURLParameter('filter', value)
    setFilterBy(value)
    push(`${LAB_REPORTS.base}?${urlParams}`)
  }

  function handleSearchChange(value) {
    const urlParams = updateURLParameter('search', value)
    setSearch(value)
    push(`${LAB_REPORTS.base}?${urlParams}`)
  }

  // List items
  let items = []
  let pagination = {
    page: 0,
    pages: 0,
    total: 0,
  }

  if (labOrdersData) {
    items = labOrdersData.listLabOrders.labOrders
    pagination.page = labOrdersData.listLabOrders.page
    pagination.pages = labOrdersData.listLabOrders.pages
    pagination.total = labOrdersData.listLabOrders.total
  }

  const LabReportListErrorMessage = () => {
    if (loading) {
      return <ListLoader fetched={items.length} total={pagination.total} loading={loading} />
    } else {
      if (isEmpty(items)) {
        return <ListEmpty message={NO_LAB_REPORTS_FOUND_MESSAGE} />
      } else if (columns.length <= 0) {
        return <ListEmpty message={NO_COLUMNS_CONFIGURED_MESSAGE} />
      } else {
        return null
      }
    }
  }

  return (
    <div className={style.pageContainer}>
      <ListPageHeader
        title="Lab Reports"
        sort={{
          sortOptions: Object.keys(SORT_OPTIONS).map((option) => {
            let label = SORT_OPTIONS[option].text
            if (option == 'nameAsc' || option == 'nameDesc') {
              label = `${consumerTitle} ${label}`
            }
            return {
              text: label,
              value: SORT_OPTIONS[option].key,
              key: SORT_OPTIONS[option].key,
            }
          }),
          sortBy,
          onChange: handleSortChange,
        }}
        filter={{
          filterOptions: Object.keys(FILTER_OPTIONS).map((option) => {
            return {
              text: FILTER_OPTIONS[option].text,
              value: FILTER_OPTIONS[option].key,
              key: FILTER_OPTIONS[option].key,
            }
          }),
          filterBy,
          onChange: handleFilterChange,
        }}
        search={{
          placeholder: 'Search Reports',
          onChange: handleSearchChange,
          text: search,
        }}
      />

      <List>
        {columns?.length > 0 && <ListColumnHeader columns={columns} />}

        <InfiniteScrollList
          scrollableTarget="lab-report-list"
          containerClassName={style.infiniteScrollContainer}
          dataLength={items.length}
          hasMore={hasMore(pagination)}
          next={() =>
            fetchNext('listLabOrders', 'lab_orders', {
              page: pagination.page,
              fetchMore,
            })
          }
        >
          {items.map((item, i) => (
            <ReportRow key={i} data={item} columnsToDisplay={columns} />
          ))}
          <LabReportListErrorMessage />
        </InfiniteScrollList>
      </List>
    </div>
  )
}
/* eslint-enable */
