import React from 'react'
import { useHistory, generatePath } from 'react-router-dom'
import { Icon } from 'semantic-ui-react'
import { format } from 'date-fns'
import classnames from 'classnames'
import { ListRow } from '@labsavvyapp/ui-components'

import FancyID from '../../../../components/Utils/FancyID'
import { LAB_REPORTS } from '../../../../config/routes'
import style from './Row.module.css'
import { Tooltip } from '@labsavvyapp/components'

export default function LabReport({ data }) {
  const { push } = useHistory()

  const { id, name, provider, createdAt, status, summary, priority, isNew, hasUnreleasedResults } = data

  const location = {
    pathname: generatePath(LAB_REPORTS.view, { labReportId: id }),
    state: {
      prevPath: window.location.pathname,
    },
  }

  return (
    <ListRow
      data-test="client-report-list-item"
      className={classnames(style.row, {
        [style.priorityUrgent]: priority === 'urgent' || hasUnreleasedResults,
        [style.priorityPartialLong]: priority === 'partial-long',
        [style.priorityEmptyWaiting]: priority === 'empty-waiting',
        [style.priorityPartialShort]: priority === 'partial-short',
        [style.new]: isNew,
        [style.notNew]: !isNew,
      })}
      onClick={() => push(location)}
    >
      <div className={style.column}>
        <div className={style.name}>
          <Tooltip label={name} position="top">
            <span>{name}</span>
          </Tooltip>
        </div>
        <div className={style.id}>
          Report ID: <FancyID id={id} />
        </div>
      </div>

      <div className={style.column}>
        <div className={style.providerName}>{provider && provider.name}</div>
        <div className={style.date}>{createdAt && format(new Date(createdAt), 'MM/dd/yyyy')}</div>
        <div className={style.status}>
          Status: <span className={style.statusText}>{status.replace(/_/g, ' ')}</span>
        </div>
      </div>

      <div className={style.column}>
        <div className={style.summaryContainer}>
          <div className={classnames(style.summaryCircle, style.summaryCircleNormal)}>{summary ? summary.normal : 0}</div>
          <div className={classnames(style.summaryCircle, style.summaryCircleAbnormal)}>{summary ? summary.abnormal : 0}</div>
        </div>

        {hasUnreleasedResults && (
          <div className={style.releasedStatus}>
            <div className={style.releasedStatusText}>NOT RELEASED</div>
            <Icon name="circle" size="small" color="red" />
          </div>
        )}
      </div>
    </ListRow>
  )
}
