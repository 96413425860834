import React, { useEffect, useCallback, useState } from 'react'
import { useLocation, useHistory } from 'react-router'
import { ErrorModal } from '@labsavvyapp/ui-components'
import { Sidebar, User } from '@labsavvyapp/components'

/// Styles
import style from './MainLayout.module.css'

/// Constants
import { LAB_REPORTS, ORDERS_SENT } from '../../config/routes'
import { useUserViewModel } from '../../viewmodels'
import { getSidebarItems } from './utils'
import { NavigationBar } from './components'

interface ComponentProps extends React.ComponentProps<any> {
  loggedIn: boolean
  me: User
}

export default function MainLayout(props: React.PropsWithChildren<ComponentProps>) {
  const { me, children } = props
  const { push } = useHistory()
  const { pathname } = useLocation()

  const userViewModel = useUserViewModel()
  const consumerTitle = userViewModel?.consumerTitle ?? ''
  const sidebarItems = getSidebarItems(pathname, me, consumerTitle)

  const [showErrorModal, setShowErrorModal] = useState(false)

  // Navigation
  const handleLinkClick = (url: string) => {
    push(url)
  }

  const preventSwitchProject = useCallback(() => {
    const notAllowedToSwitchRoutes = [
      LAB_REPORTS.newExisting,
      LAB_REPORTS.newCustom,
      LAB_REPORTS.review,
      ORDERS_SENT.newExisting,
      ORDERS_SENT.newCustom,
      ORDERS_SENT.review,
    ]

    const match = notAllowedToSwitchRoutes.includes(pathname)
    return match
  }, [pathname])

  // Disable Project switch in certain pages (e.g. Lab Order flow)
  useEffect(() => {
    preventSwitchProject()
  }, [pathname, preventSwitchProject])

  return (
    <div
      className={style.root}
      style={{
        height: pathname.match(/lab-reports.*view/) ? '' : '100%',
      }}
    >
      <NavigationBar />

      <div className={style.contentHolder}>
        <Sidebar items={sidebarItems} onItemClick={(url: string) => handleLinkClick(url)} />
        <div className={style.content}>{children}</div>
      </div>

      <ErrorModal message="Error updating active partner." open={showErrorModal} onCloseClick={() => setShowErrorModal(false)} />
    </div>
  )
}
