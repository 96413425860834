import { ORDER_TYPES, PaymentMethodListDropdownOption } from '../../../viewmodels'

export const generateOrderType = (selectedPaymentMethod: PaymentMethodListDropdownOption | null) => {
  switch (selectedPaymentMethod?.value) {
    case ORDER_TYPES['Bill to Client'].value:
      return [
        {
          text: ORDER_TYPES['Bill to Client'].text,
          label: ORDER_TYPES['Bill to Client'].label,
          queryStringValue: ORDER_TYPES['Bill to Client'].queryStringValue,
          key: ORDER_TYPES['Bill to Client'].key,
          value: ORDER_TYPES['Bill to Client'].value,
        },
      ]
    case ORDER_TYPES['Bill to Patient'].value:
      return [
        {
          text: ORDER_TYPES['Bill to Patient'].text,
          label: ORDER_TYPES['Bill to Patient'].label,
          queryStringValue: ORDER_TYPES['Bill to Patient'].queryStringValue,
          key: ORDER_TYPES['Bill to Patient'].key,
          value: ORDER_TYPES['Bill to Patient'].value,
        },
      ]
    case ORDER_TYPES['Bill to Insurance'].value:
      return [
        {
          text: ORDER_TYPES['Bill to Insurance'].text,
          label: ORDER_TYPES['Bill to Insurance'].label,
          queryStringValue: ORDER_TYPES['Bill to Insurance'].queryStringValue,
          key: ORDER_TYPES['Bill to Insurance'].key,
          value: ORDER_TYPES['Bill to Insurance'].value,
        },
      ]
    default:
      return []
  }
}
