import React, { useState } from 'react'
import { useSavingModals } from '@labsavvyapp/ui-components'
import { capitalize, Partner, Project, User } from '@labsavvyapp/components'
import { UserContextProvider } from './context'
import { useQueryListMyPartnersApi } from '../partners'
import { useHistory } from 'react-router'
import { LAB_REPORTS } from '../../config/routes'
import { useMutationSetActivePartnerApi, useMutationSetActiveProjectApi } from './api'

interface ComponentProps extends React.ComponentProps<any> {
  me: User
  loggedIn: boolean
}

export const UserViewModel: React.FunctionComponent<ComponentProps> = (props: React.PropsWithChildren<ComponentProps>) => {
  const { me, loggedIn } = props
  const { push } = useHistory()

  const isSuperAdmin = me?.capabilities?.isSuperAdmin ?? false

  const [showSwitchPartnerModal, setShowSwitchPartnerModal] = useState<boolean>(false)
  const [shotErrorModal, setShowErrorModal] = useState<boolean>(false)

  const { data: listMyPartnersData } = useQueryListMyPartnersApi()

  const [setActivePartner, { data: setActivePartnerData, loading: setActivePartnerLoading, error: setActivePartnerError }] =
    useMutationSetActivePartnerApi()

  const [setActiveProject, { data: setActiveProjectData, loading: setActiveProjectLoading, error: setActiveProjectError }] =
    useMutationSetActiveProjectApi()

  const partnerOptions =
    listMyPartnersData && listMyPartnersData.listMyPartners
      ? listMyPartnersData.listMyPartners.partners.map((partner: Partner) => ({
          key: partner._id,
          value: partner._id,
          text: partner.name,
        }))
      : []

  const selectedPartnerData =
    listMyPartnersData &&
    listMyPartnersData.listMyPartners &&
    listMyPartnersData.listMyPartners.partners.find((partner: Partner) => partner._id === me?.partner._id)

  const projectOptions = selectedPartnerData
    ? selectedPartnerData.projects.map((project: Project) => ({
        key: project._id,
        value: project._id,
        text: project.name,
      }))
    : []

  const handleShowSwitchPartnerModalChange = (isModalShown: boolean) => {
    setShowSwitchPartnerModal(isModalShown)
  }

  const handleShowErrorModalChange = (isModalShown: boolean) => {
    setShowErrorModal(isModalShown)
  }

  const getInitialProject = () => {
    const currentPartner = listMyPartnersData?.listMyPartners.partners.find((partner: Partner) => partner._id === me?.partner?._id)
    return currentPartner?.projects[0].id
  }

  const [modals, { showModals }] = useSavingModals({
    savedMessage: 'Your Partner has been saved',
    savingMessage: 'Your Partner is being saved.',
    errorMessage: 'Error saving your Partner.',
    callback: (partnerId: string) => {
      setActivePartner({
        variables: { partnerId },
      })

      setTimeout(() => {
        push(LAB_REPORTS.base)
      }, 400)
    },
  })

  return (
    <UserContextProvider
      me={me}
      loggedIn={loggedIn}
      isSuperAdmin={isSuperAdmin}
      capabilities={me?.capabilities}
      selectedPartner={me?.partner}
      selectedProject={me?.project}
      providers={me?.providers}
      consumerTitle={capitalize(me?.project?.consumers_title)}
      showSwitchPartnerModal={showSwitchPartnerModal}
      handleShowSwitchPartnerModalChange={handleShowSwitchPartnerModalChange}
      shotErrorModal={shotErrorModal}
      handleShowErrorModalChange={handleShowErrorModalChange}
      partnerOptions={partnerOptions}
      projectOptions={projectOptions}
      partnerSavingModal={modals}
      showPartnerSavingModal={showModals}
      getInitialProject={getInitialProject}
      setActivePartner={setActivePartner}
      setActivePartnerData={setActivePartnerData}
      setActivePartnerLoading={setActivePartnerLoading}
      setActivePartnerError={setActivePartnerError}
      setActiveProject={setActiveProject}
      setActiveProjectData={setActiveProjectData}
      setActiveProjectLoading={setActiveProjectLoading}
      setActiveProjectError={setActiveProjectError}
    >
      {props.children}
    </UserContextProvider>
  )
}
