import { useQuery } from 'react-apollo'
import { ListConfigs } from '../../../graphql/configs/queries'

export const useQueryListEthnicityApi = () => {
  const {
    data: ethnicityListData,
    error: ethnicityListError,
    loading: ethnicityListLoading,
  } = useQuery(ListConfigs, {
    variables: {
      sort: {
        name: 'asc',
      },
      limit: 500,
      page: 1,
      filter: {
        domain: 'MASTERDATA',
        category: 'ETHNICITY',
      },
    },
  })

  return {
    data: ethnicityListData?.listConfigs?.configs,
    error: ethnicityListError,
    loading: ethnicityListLoading,
  }
}
