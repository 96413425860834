import React from 'react'
import classnames from 'classnames'

import style from './ListItem.module.css'

const ListItem = ({ item, selected, disabled, onClick }) => (
  <div
    className={classnames(style.listItem, {
      [style.selected]: selected,
      [style.disabled]: disabled,
    })}
    onClick={() => !item.selected && onClick(item)}
    data-test="group-list-item"
  >
    <span className={style.code}>{item.code}</span>
    <span className={style.name}>{item.name}</span>
  </div>
)

export default ListItem
