import { PartnerCompendiumPanel, ProjectPackage } from '@labsavvyapp/components'

export const generateLabOrderName = (selectedCompendiaPanels: PartnerCompendiumPanel[], selectedOrderSets: ProjectPackage[]) => {
  const compendiaPanelNames = selectedCompendiaPanels
    .filter((item) => item.name)
    .map((item) => item.name)
    .join(', ')

  const ordersetNames = selectedOrderSets
    .filter((item) => item.name)
    .map((item) => item.name)
    .join(', ')

  let labOrderName = `${compendiaPanelNames} ${ordersetNames}`

  return labOrderName
}
