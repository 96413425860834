import React from 'react'
import { Grid } from 'semantic-ui-react'
import { ICDList } from './components'
import { useCreateLabOrderBusinessLogic } from '../../logic'
import styled from '@emotion/styled'
import { SelectedTestList } from '../StepSelectTests/components'

const StepSelectICDCodesContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin: 20px 30px 20px 20px;
`
const StyledList = styled.div`
  border: 1px solid var(--gallery);
  margin-top: 50px;

  h4 {
    background-color: var(--grayedWhite);
    font-weight: 700;
    margin-bottom: 0;
    padding: 15px 20px;
  }
  ul {
    list-style: none;
    margin: 0;
    max-height: 490px;
    overflow-y: auto;
    padding: 0;
  }
  ul li {
    background-color: var(--white);
    padding: 10px 20px;
    border-bottom: 1px solid var(--gallery);
  }
  ul li:last-child {
    border: 0;
  }
`
export const StepSelectICDCodes = () => {
  const businessLogic = useCreateLabOrderBusinessLogic()
  const selectedCompendiaPanels = businessLogic?.selectedCompendiaPanels ?? []
  const selectedIcdCodes = businessLogic?.selectedIcdCodes ?? []

  return (
    <StepSelectICDCodesContainer>
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <StyledList>
              <h4>Test Name</h4>
              <SelectedTestList hideCheckbox={true} />
            </StyledList>
          </Grid.Column>
          <Grid.Column width={8}>
            <ICDList selectedICDCodes={selectedIcdCodes} onICDSelection={businessLogic?.handleSelectedIcdCodesChange} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </StepSelectICDCodesContainer>
  )
}
