import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useQuery } from '@apollo/react-hooks'
import { SORT_OPTIONS } from '../../../constants'

import ICDRow from './ICDRow'
import ListPageHeader from '../../../../../components/ListPageHeader/ListPageHeader'
import { InfiniteScrollList, List, ListHeader, ListLoader, ListEmpty } from '@labsavvyapp/ui-components'
import { fetchNext, hasMore, isEmpty, updateURLParameter } from '@labsavvyapp/ui-components/lib/utils'
import { getQueryVariables } from '../../../../../utils/helpers'

import { LAB_ORDER } from '../../../../../config/routes'
import { ListICDs } from '../../../../../graphql/icd/queries'

import style from './ICDList.module.css'

export const ICDList = ({ selectedICDCodes, onICDSelection }) => {
  const { push } = useHistory()
  const { options } = SORT_OPTIONS
  const { search: locationSearch } = useLocation()
  const query = new URLSearchParams(locationSearch)
  const [search, setSearch] = useState(query.get('search') || '')
  const [sortBy, setSortBy] = useState(query.get('sort') || SORT_OPTIONS.defaultSortOption)

  const { data, loading, fetchMore, refetch } = useQuery(ListICDs, {
    variables: getQueryVariables(sortBy, search, options),
    notifyOnNetworkStatusChange: true,
  })

  useEffect(() => {
    refetch({
      page: 1,
      ...getQueryVariables(sortBy, search, options),
    })
  }, [sortBy, search])

  const handleSort = (_, { value }) => {
    setSortBy(value)
    const urlParams = updateURLParameter('sort', value)
    push(`${LAB_ORDER.new}?${urlParams}`)
  }

  const handleSearch = (search) => {
    setSearch(search)
    const urlParams = updateURLParameter('search', search)
    push(`${LAB_ORDER.new}?${urlParams}`)
  }

  const icdList = data?.listICDs

  let items = []
  let pagination = {
    page: 0,
    pages: 0,
    total: 0,
  }

  if (icdList) {
    items = icdList.icds
    pagination.page = icdList.page
    pagination.pages = icdList.pages
    pagination.total = icdList.total
  }

  return (
    <>
      <ListPageHeader
        sort={{
          sortOptions: SORT_OPTIONS.options.map(({ text, queryStringValue }) => ({
            text,
            value: queryStringValue,
          })),
          sortBy,
          onChange: handleSort,
        }}
        search={{
          placeholder: 'Search ICD Code',
          onChange: handleSearch,
          text: '',
        }}
        className={style.listHeader}
      />
      <List className={style.container}>
        <ListHeader
          className={style.header}
          columns={[{ className: style.check }, { name: 'ICD Code', className: style.code }, { name: 'Diagnosis', className: style.text }]}
        />

        <InfiniteScrollList
          scrollableTarget="icd-list"
          dataLength={items.length}
          hasMore={hasMore(pagination)}
          next={() =>
            fetchNext('listICDs', 'icds', {
              page: pagination.page,
              fetchMore,
            })
          }
        >
          {!isEmpty(items) &&
            items.map((item) => (
              <ICDRow
                checked={selectedICDCodes?.some((icd) => item.code === icd.code)}
                key={item.code}
                data={item}
                selectedICDCodes={selectedICDCodes}
                onICDSelection={onICDSelection}
              />
            ))}

          <ListLoader fetched={items.length} total={pagination.total} loading={loading} />

          {!loading && isEmpty(items) && <ListEmpty message={`No ICDs were found.`} />}
        </InfiniteScrollList>
      </List>
    </>
  )
}
