import React from 'react'
import styled from '@emotion/styled'
import { Button } from '@labsavvyapp/components'
import { useHistory } from 'react-router-dom'
import { useAddEditClientPageBusinessLogic } from '../../../../logic'

const FormButtonControlsLayout = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 22px;
  margin-bottom: 22px;
  margin-right: 30px;
  gap: 10px;
`

export const FormButtonControls = () => {
  const { push } = useHistory()
  const businessLogic = useAddEditClientPageBusinessLogic()
  const previousPageURL = businessLogic?.previousPageURL ?? ''

  const form = businessLogic?.form ?? null

  const isFormNotReady = () => {
    return !form?.isValid()
  }

  return (
    <FormButtonControlsLayout>
      <Button display="inline" onClick={() => push(previousPageURL)} radius="xl" size="md" uppercase variant="default">
        Cancel
      </Button>

      <Button radius="xl" size="md" uppercase variant="filled" onClick={() => businessLogic?.handleSaveChangesClick()} disabled={isFormNotReady()}>
        Save Changes
      </Button>
    </FormButtonControlsLayout>
  )
}
