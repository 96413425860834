import { useQuery } from 'react-apollo'
import { ListProviders } from '../../../graphql/providers/queries'

export const useQueryListProvidersApi = () => {
  const {
    data: providersListData,
    error: providersListError,
    loading: providersListLoading,
    refetch: refetchProvidersListData,
  } = useQuery(ListProviders, {
    fetchPolicy: 'network-only',
  })

  return {
    data: providersListData,
    error: providersListError,
    loading: providersListLoading,
    refetch: refetchProvidersListData,
  }
}
