import gql from 'graphql-tag'

export const ListInsuranceProviders = gql`
  query ListInsuranceProviders {
    listInsuranceProviders {
      title
      description
    }
  }
`
