import gql from 'graphql-tag'

export const ListInsurance = gql`
  query ListInsurance($patient_id: MongoID!) {
    listInsurance(patient_id: $patient_id) {
      _id
      provider
      plan_type
      form_type
      subscriber {
        first_name
        last_name
        middle_initial
        guarantor_dob
        address
        state
        city
        zip
        phone
        relationship
      }
      id_number
      group_name
      group_number
      patient_id {
        data {
          name {
            display
          }
          profile_photo {
            url
          }
          emails {
            address
          }
          gender
          dob
        }
      }
      coverage_date
      front_image
      back_image
      is_active
    }
  }
`

export const ListInsuranceImages = gql`
  query ListInsuranceImages($patient_id: MongoID!) {
    listInsurance(patient_id: $patient_id) {
      _id
      front_image
      back_image
      form_type
    }
  }
`

export const ListInsuranceIsActive = gql`
  query ListInsuranceIsActive($patient_id: MongoID!) {
    listInsurance(patient_id: $patient_id) {
      _id
      is_active
      form_type
    }
  }
`
