import styled from '@emotion/styled'
import { Button, ChevronLeftIcon, ChevronRightIcon } from '@labsavvyapp/components'
import React from 'react'
import { useCreateLabOrderBusinessLogic } from '../../../../logic'

const TestSelectionControlsLayout = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  height: 100%;
  gap: 8px;
`

export const TestSelectionControls = () => {
  const businessLogic = useCreateLabOrderBusinessLogic()
  const transferListData = businessLogic?.transferListData ?? [[], []]
  const transferListUnselected = transferListData[0]
  const transferListSelected = transferListData[1]
  const selectedLeftPanelItems = businessLogic?.selectedLeftPanelItems ?? []
  const selectedRightPanelItems = businessLogic?.selectedRightPanelItems ?? []

  const handleTransferToSelectedColumn = () => {
    const updatedTransferListUnselected = transferListUnselected.filter((item) => !selectedLeftPanelItems.includes(item.value))
    const updatedTransferListSelected = transferListSelected.concat(
      transferListUnselected.filter((item) => selectedLeftPanelItems.includes(item.value)),
    )

    businessLogic?.handleTransferListDataChange([updatedTransferListUnselected, updatedTransferListSelected])
    businessLogic?.handleSelectedLeftPanelItemsChange([])
    businessLogic?.handleLeftPanelSearchChange('')
  }

  const handleTransferToUnselectedColumn = () => {
    const updatedTransferListUnselected = transferListUnselected.concat(
      transferListSelected.filter((item) => selectedRightPanelItems.includes(item.value)),
    )
    const updatedTransferListSelected = transferListSelected.filter((item) => !selectedRightPanelItems.includes(item.value))

    businessLogic?.handleTransferListDataChange([updatedTransferListUnselected, updatedTransferListSelected])
    businessLogic?.handleSelectedRightPanelItemsChange([])
  }

  return (
    <TestSelectionControlsLayout>
      <Button radius="md" size="sm" uppercase variant="filled" disabled={!selectedLeftPanelItems.length} onClick={handleTransferToSelectedColumn}>
        <ChevronRightIcon />
      </Button>
      <Button radius="md" size="sm" uppercase variant="filled" disabled={!selectedRightPanelItems.length} onClick={handleTransferToUnselectedColumn}>
        <ChevronLeftIcon />
      </Button>
    </TestSelectionControlsLayout>
  )
}
