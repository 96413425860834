import React from 'react'
import { useHistory, useLocation } from 'react-router'
import { generatePath } from 'react-router-dom'
import get from 'lodash/get'
import classnames from 'classnames'
import { format } from 'date-fns'
import { Badge, ClientDetails } from '@labsavvyapp/components'

import style from './ReportDetails.module.css'
import { CLIENTS, LAB_REPORTS } from '../../config/routes'
import DocumentsDetails from './DocumentsDetails'
import { Button } from '@labsavvyapp/ui-components'
import { useClientViewModel } from '../../viewmodels'

export default function ReportDetails({ labOrder, refetch }) {
  const { push } = useHistory()
  const { pathname } = useLocation()
  const clientViewModel = useClientViewModel()

  if (!labOrder) return null

  // const orderId = get(labOrder, 'shortId')
  const orderIds = get(labOrder, 'shortIds')
  const accession = get(labOrder, 'fillerId')
  const clientId = get(labOrder, 'patient.id')
  const clientData = get(labOrder, 'patient.data')
  const orderedDate = format(new Date(labOrder.createdAt), 'MMM d, yyyy')

  const updateSelectedClient = clientViewModel?.handleSelectedClientChange?.(clientId)

  const handleClientDataPageLink = () => {
    push(generatePath(CLIENTS.view, { clientId: clientId }))
  }

  const getBadgeStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'ordered':
        return 'violet'
      case 'started':
        return 'blue'
      case 'partial':
        return 'grey'
      case 'completed':
        return 'green'
      case 'final':
        return 'pink'
      default:
        return ''
    }
  }

  return (
    <section className={style.container} data-test="lab-report-profile">
      <ClientDetails
        _id={clientId}
        data={{
          ...clientData,
          profile_photo: {
            _id: clientData?.profilePhoto?._id ?? '',
            url: clientData?.profilePhoto?.url ?? '',
          },
        }}
        clientDataPageLink={handleClientDataPageLink}
      />

      {pathname.includes('trending') ? (
        <Button className={style.my10} onClick={() => push(generatePath(LAB_REPORTS.view, { labReportId: labOrder.id }))}>
          View Test Results
        </Button>
      ) : (
        <Button className={style.my10} onClick={() => push(generatePath(LAB_REPORTS.trending, { labReportId: labOrder.id }))}>
          View Trending
        </Button>
      )}

      <span className={style.detailsItem}>
        <strong>Accession #:</strong> {accession || '--'}
      </span>
      <span className={style.detailsItem}>
        <strong>Ordered:</strong> {orderedDate || '--'}
      </span>

      {labOrder.summary && (
        <div className={style.summary}>
          <div className={classnames(style.summaryValue, style.summaryNormal)}>{get(labOrder, 'summary.normal', 0)}</div>
          <div className={classnames(style.summaryValue, style.summaryAbnormal)}>{get(labOrder, 'summary.abnormal', 0)}</div>
        </div>
      )}

      {orderIds[0] && (
        <span className={style.detailsItem}>
          <strong>Order ID:</strong> {orderIds[0]}
        </span>
      )}

      {labOrder.status && (
        <span className={style.detailsItem}>
          <strong>Status:</strong>
          <Badge color={getBadgeStatusColor(labOrder.status)} variant="filled">
            {labOrder.status}
          </Badge>
        </span>
      )}

      <DocumentsDetails labOrder={labOrder} refetch={refetch} />
    </section>
  )
}
