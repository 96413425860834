import React from 'react'
import styled from '@emotion/styled'
import { useAddEditClientPageBusinessLogic } from '../../../../logic'
import { Link } from 'react-router-dom'
import { Icon } from 'semantic-ui-react'
import { CLIENTS } from '../../../../../../../config/routes'

const StyledConsumerTitle = styled.div`
  display: flex;
  flex-flow: column nowrap;
`

export const ConsumerTitle = () => {
  const businessLogic = useAddEditClientPageBusinessLogic()
  const selectedPatient = businessLogic?.selectedPatient ?? null
  const consumerTitle = businessLogic?.consumerTitle ?? ''

  return (
    <StyledConsumerTitle>
      <Link data-test="link-back-to-clients" to={CLIENTS.list}>
        <Icon name="caret left" />
        Back to {consumerTitle}s
      </Link>

      <h1>
        {selectedPatient ? `Edit ` : `New `}
        {consumerTitle}
      </h1>
    </StyledConsumerTitle>
  )
}
