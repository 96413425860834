import React from 'react'
import { Switch, Route } from 'react-router'

import { LAB_ORDER } from '../config/routes'
import withMainLayout from '../layouts/Main/withMainLayout'
import { CreateLabOrderBusinessLogic } from '../pages/LabOrder/CreateLabOrderBusinessLogic'

const LabOrderRoutes = ({ path, me, loggedIn }) => (
  <Route
    path={path}
    render={() => (
      <Switch>
        <Route
          path={LAB_ORDER.new}
          render={() =>
            withMainLayout(CreateLabOrderBusinessLogic, {
              me,
              loggedIn,
            })
          }
        />
      </Switch>
    )}
  />
)

export default LabOrderRoutes
