import React, { useState } from 'react'
import { Loader } from 'semantic-ui-react'
import { useParams } from 'react-router'
import { List, Card } from '@labsavvyapp/ui-components'

import { NotesModal } from '@labsavvyapp/components'
import ClientNote from './ClientNote'
import style from './ClientNotes.module.css'
import { CreateNote } from '../../../graphql/notes/mutations'
import { useMutation, useQuery } from 'react-apollo'
import { ListNotes } from '../../../graphql/notes/queries'
import { camelizeKeys } from 'humps'

export default function ClientNotes({ consumer }) {
  const { clientId } = useParams()
  const [noteData, setNoteData] = useState('')

  // Fetch client notes
  const {
    data: notesData,
    refetch,
    loading,
  } = useQuery(ListNotes, {
    variables: { filter: { patient_id: clientId } },
  })
  const parsedNotesData = camelizeKeys(notesData)
  const notes =
    parsedNotesData &&
    parsedNotesData.listNotes &&
    parsedNotesData.listNotes.notes

  const getListNotesQueryVariables = () => {
    const variables = { filter: { lab_order_id: null } }
    variables.filter = {
      patient_id: clientId,
    }

    return variables
  }

  const [createNote] = useMutation(CreateNote, {
    refetchQueries: [
      {
        query: ListNotes,
        variables: getListNotesQueryVariables(),
      },
    ],
  })

  const onSaveNote = async () => {
    try {
      await createNote({
        variables: {
          data: {
            patient_id: clientId,
            lab_order_id: null,
            body: noteData,
          },
        },
      })
      refetch()
      setNoteData('')
    } catch (error) {
      new Error('Error saving note...')
    }
  }

  return (
    <div className={style.container}>
      <div className={style.header}>
        <h3>{consumer} Notes</h3>

        <NotesModal
          onChange={setNoteData}
          onSaveNote={onSaveNote}
          title="Add new Patient Note"
        />
      </div>

      <List className={style.reportList} data-test="client-notes-list">
        {loading && <Loader active inline="centered" />}

        {notes &&
          notes.length > 0 &&
          notes.map((note) => (
            <ClientNote key={note.id} clientId={clientId} data={note} />
          ))}

        {!loading && notes && notes.length <= 0 && (
          <Card data-test="card-no-client-notes-found" emptyCard shadowed>
            No {consumer} notes found.
          </Card>
        )}
      </List>
    </div>
  )
}
