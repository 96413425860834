import React from 'react'
import { Button } from '@labsavvyapp/ui-components'
import { useCreateLabOrderBusinessLogic } from '../../logic'

export const CreateLabOrderStepControls = () => {
  const businessLogic = useCreateLabOrderBusinessLogic()
  const activeStep = businessLogic?.activeStep ?? null
  const isOrderReady = businessLogic?.isOrderReady ?? false
  const disabledSteps = businessLogic?.disabledSteps ?? []

  return (
    <div>
      <Button variant="basic" onClick={() => businessLogic?.handleCancelOrder()}>
        Cancel
      </Button>

      {activeStep === 'labs' && (
        <Button disabled={disabledSteps.includes('aoe')} onClick={() => businessLogic?.handleActiveStepChange('aoe')}>
          Next
        </Button>
      )}

      {activeStep === 'aoe' && (
        <Button disabled={disabledSteps.includes('icd')} onClick={() => businessLogic?.handleActiveStepChange('icd')}>
          Next
        </Button>
      )}

      {activeStep === 'icd' && (
        <Button disabled={disabledSteps.includes('review')} onClick={() => businessLogic?.handleActiveStepChange('review')}>
          Review Order
        </Button>
      )}

      {activeStep === 'review' && (
        <Button disabled={!isOrderReady} onClick={() => businessLogic?.handleSubmitOrder()}>
          Submit Order
        </Button>
      )}
    </div>
  )
}
