import { useQuery } from 'react-apollo'
import { ListPatients } from '../../../graphql/clients/queries'
import { PatientFilter, PatientSort, PatientsPage } from '@labsavvyapp/components'

export interface ListPatientVariables {
  filter?: PatientFilter
  limit?: number
  page?: number
  sort?: PatientSort
}

export const useQueryListPatientsApi = (variables: ListPatientVariables | null) => {
  const {
    data: patientListData,
    error: patientListError,
    loading: patientListLoading,
    refetch: refetchPatientListData,
    fetchMore: fetchMorePatientListData,
  } = useQuery(ListPatients, {
    variables: {
      limit: variables?.limit,
      page: variables?.page,
      filter: variables?.filter,
      sort: variables?.sort,
    },
  })

  return {
    data: patientListData?.listPatients as PatientsPage,
    error: patientListError,
    loading: patientListLoading,
    refetch: refetchPatientListData,
    fetchMore: fetchMorePatientListData,
  }
}
