import React, { useState } from 'react'
import { Loader } from 'semantic-ui-react'
import { useParams } from 'react-router'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { camelizeKeys } from 'humps'
import { Card } from '@labsavvyapp/ui-components'
import { isEmpty } from '@labsavvyapp/ui-components/lib/utils'
import { NotesModal } from '@labsavvyapp/components'
import LabReportNote from './LabReportNote'
import { ListNotes } from '../../../graphql/notes/queries.js'
import style from './LabReportNotes.module.css'
import { CreateNote } from '../../../graphql/notes/mutations'

export default function LabReportNotes(props) {
  const { labReportId } = useParams()
  const { labOrder } = props
  const { data, refetch, loading } = useQuery(ListNotes, {
    variables: { filter: { lab_order_id: labReportId } },
  })
  const notesData = camelizeKeys(data)
  const notes = notesData && notesData.listNotes.notes

  const [noteData, setNoteData] = useState('')

  const getListNotesQueryVariables = () => {
    const variables = { filter: { lab_order_id: labReportId } }
    if (labOrder) {
      variables.filter = {
        patient_id: labOrder.patient.id,
      }
    }
    return variables
  }

  const [createNote] = useMutation(CreateNote, {
    refetchQueries: [
      {
        query: ListNotes,
        variables: getListNotesQueryVariables(),
      },
    ],
  })

  const onSaveNote = async () => {
    try {
      if (noteData)
        await createNote({
          variables: {
            data: {
              patient_id: labOrder.patient.id,
              lab_order_id: labReportId,
              body: noteData,
            },
          },
        })
      refetch()
      setNoteData('')
    } catch {
      new Error('Error saving note...')
    }
  }

  return (
    <div className={style.notesRootContainer}>
      <div className={style.reportNotesHeader}>
        <h2 className={style.reportNotesTitle}>Report Notes </h2>

        <NotesModal
          onChange={setNoteData}
          onSaveNote={onSaveNote}
          title="Add new Report Note"
        />
      </div>
      <div className={style.container}>
        {loading && <Loader active inline="centered" />}

        {notes &&
          notes.map((note) => <LabReportNote key={note.id} data={note} />)}

        {!loading && isEmpty(notes) && (
          <Card data-test="card-no-notes" emptyCard shadowed>
            No order notes found.
          </Card>
        )}
      </div>
    </div>
  )
}
