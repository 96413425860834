import React from 'react'
import { Form } from 'semantic-ui-react'
import { generateAOEInput } from './generateAOEInput'
import { Question } from '@labsavvyapp/components'

export const generateAOEQuestions = (aoe: Question[], code: any, handleChange: any) => {
  return aoe.map((aoe: Question, i: number) => {
    const change = (value: any) => {
      aoe.answer = value
      handleChange()
    }

    return <Form.Group key={i}>{generateAOEInput({ aoe: aoe, panelCode: code, handleChange: change })}</Form.Group>
  })
}
