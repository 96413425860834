import React, { useEffect, useState } from 'react'
import { ClientContextProvider, PatientListDropdownOption } from './context'
import { ListPatientVariables, useQueryListEthnicityApi, useQueryListPatientsApi, useQueryListRaceApi } from './api'
import { Configs, User } from '@labsavvyapp/components'
import { stateOptions } from './constants'

interface ComponentProps extends React.ComponentProps<any> {
  me: User
}

export const ClientsViewModel: React.FunctionComponent<ComponentProps> = (props: React.PropsWithChildren<ComponentProps>) => {
  const [patientsPageVariables, setPatientsPageVariables] = useState<ListPatientVariables | null>(null)
  const [patientListForDropdown, setPatientListForDropdown] = useState<PatientListDropdownOption[]>([])

  const {
    data: patientListData,
    error: patientListError,
    loading: patientListLoading,
    refetch: refetchPatientListData,
    fetchMore: fetchMorePatientListData,
  } = useQueryListPatientsApi(patientsPageVariables)

  const { data: raceList } = useQueryListRaceApi()
  const { data: ethnicityList } = useQueryListEthnicityApi()

  const genderOptions = [
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
  ]

  const raceOptions = raceList?.map((race: Configs) => {
    return {
      label: race.name,
      value: race._id,
    }
  })

  const ethnicityOptions = ethnicityList?.map((ethnicity: Configs) => {
    return {
      label: ethnicity.name,
      value: ethnicity._id,
    }
  })

  const phoneTypeOptions = [
    { label: 'Mobile', value: 'mobile' },
    { label: 'Home', value: 'home' },
    { label: 'Work', value: 'work' },
  ]

  // Formatting patient list for dropdown
  useEffect(() => {
    const patientDropdownList = patientListData?.patients.map((client: any) => {
      return {
        key: client?._id,
        text: client?.data?.name?.display,
        label: client?.data?.name?.display,
        value: client?._id,
        image: { avatar: true, src: client?.data?.profile_photo?.url },
      }
    })
    setPatientListForDropdown(patientDropdownList)
  }, [patientListData])

  const handlePatientsPageVariablesChange = (updatedVariables: ListPatientVariables) => {
    setPatientsPageVariables(updatedVariables)
  }

  return (
    <ClientContextProvider
      patientsPage={patientListData}
      patientsPageVariables={patientsPageVariables}
      handlePatientsPageVariablesChange={handlePatientsPageVariablesChange}
      patientList={patientListData?.patients}
      patientListError={patientListError}
      patientListLoading={patientListLoading}
      refetchPatientList={refetchPatientListData}
      fetchMorePatientListData={fetchMorePatientListData}
      patientListForDropdown={patientListForDropdown}
      genderOptions={genderOptions}
      raceOptions={raceOptions}
      ethnicityOptions={ethnicityOptions}
      stateOptions={stateOptions}
      phoneTypeOptions={phoneTypeOptions}
    >
      {props.children}
    </ClientContextProvider>
  )
}
