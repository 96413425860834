import { CustomPackagePricingInput, PartnerCompendiumPanel, ProjectPackage } from '@labsavvyapp/components'

export const generateCustomPackagePricing = (selectedCompendiaPanels: PartnerCompendiumPanel[], selectedOrderSets: ProjectPackage[]) => {
  const custom_package_pricing: CustomPackagePricingInput[] = []

  if (selectedCompendiaPanels.length > 0)
    selectedCompendiaPanels.forEach((panel) => {
      custom_package_pricing.push({
        partnerPricing: {
          id: panel?.partner_pricing?.id,
          contractPrice: panel.partner_pricing.contract_price,
          itemCode: panel.partner_pricing.item_code,
          itemName: panel.partner_pricing.item_name,
          lsCost: panel.partner_pricing.ls_cost ?? 0,
          lsStandardPrice: panel.partner_pricing?.ls_standard_price ?? 0,
          retailPrice: panel.partner_pricing?.retail_price ?? 0,
          standardRetailPrice: panel.partner_pricing?.standard_retail_price ?? 0,
          typename: 'PartnerCompendiumPricing',
        },
      })
    })

  if (selectedOrderSets.length > 0)
    selectedOrderSets.forEach((orderset) => {
      custom_package_pricing.push({
        partnerPricing: {
          id: orderset.package._id,
          contractPrice: 0,
          itemCode: '',
          itemName: orderset.name,
          lsCost: orderset.package.partner_price ?? 0,
          lsStandardPrice: orderset.package.ls_price ?? 0,
          retailPrice: orderset.package?.retail_price ?? 0,
          standardRetailPrice: orderset.package.additional_fee ?? 0,
          typename: 'Package',
        },
      })
    })

  return custom_package_pricing
}
