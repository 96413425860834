import React from 'react'
import { Step } from 'semantic-ui-react'
import { useCreateLabOrderBusinessLogic } from '../../logic'
import { CREATE_LAB_ORDER_STEPS } from '../../../../viewmodels'
import styled from '@emotion/styled'

const OrderStepsLayout = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin: 20px 30px 0px 10px;
`
export const OrderSteps = () => {
  const businessLogic = useCreateLabOrderBusinessLogic()
  const activeStep = businessLogic?.activeStep ?? null
  const disabledSteps = businessLogic?.disabledSteps ?? []
  const completedSteps = businessLogic?.completedSteps ?? []

  return (
    <OrderStepsLayout>
      <Step.Group ordered>
        <Step
          active={activeStep === CREATE_LAB_ORDER_STEPS.labs.key}
          disabled={disabledSteps?.includes(CREATE_LAB_ORDER_STEPS.labs.key)}
          completed={completedSteps?.includes(CREATE_LAB_ORDER_STEPS.labs.key)}
          onClick={() => businessLogic?.handleActiveStepChange(CREATE_LAB_ORDER_STEPS.labs.key)}
        >
          <Step.Content>
            <Step.Title>{CREATE_LAB_ORDER_STEPS.labs.name}</Step.Title>
            <Step.Description>{CREATE_LAB_ORDER_STEPS.labs.description}</Step.Description>
          </Step.Content>
        </Step>

        <Step
          active={activeStep === CREATE_LAB_ORDER_STEPS.aoe.key}
          disabled={disabledSteps?.includes(CREATE_LAB_ORDER_STEPS.aoe.key)}
          completed={completedSteps?.includes(CREATE_LAB_ORDER_STEPS.aoe.key)}
          onClick={() => businessLogic?.handleActiveStepChange(CREATE_LAB_ORDER_STEPS.aoe.key)}
        >
          <Step.Content>
            <Step.Title>{CREATE_LAB_ORDER_STEPS.aoe.name}</Step.Title>
            <Step.Description>{CREATE_LAB_ORDER_STEPS.aoe.description}</Step.Description>
          </Step.Content>
        </Step>

        <Step
          active={activeStep === CREATE_LAB_ORDER_STEPS.icd.key}
          disabled={disabledSteps?.includes(CREATE_LAB_ORDER_STEPS.icd.key)}
          completed={completedSteps?.includes(CREATE_LAB_ORDER_STEPS.icd.key)}
          onClick={() => businessLogic?.handleActiveStepChange(CREATE_LAB_ORDER_STEPS.icd.key)}
        >
          <Step.Content>
            <Step.Title>{CREATE_LAB_ORDER_STEPS.icd.name}</Step.Title>
            <Step.Description>{CREATE_LAB_ORDER_STEPS.icd.description}</Step.Description>
          </Step.Content>
        </Step>

        <Step
          active={activeStep === CREATE_LAB_ORDER_STEPS.review.key}
          disabled={disabledSteps?.includes(CREATE_LAB_ORDER_STEPS.review.key)}
          completed={completedSteps?.includes(CREATE_LAB_ORDER_STEPS.review.key)}
          onClick={() => businessLogic?.handleActiveStepChange(CREATE_LAB_ORDER_STEPS.review.key)}
        >
          <Step.Content>
            <Step.Title>{CREATE_LAB_ORDER_STEPS.review.name}</Step.Title>
            <Step.Description>{CREATE_LAB_ORDER_STEPS.review.description}</Step.Description>
          </Step.Content>
        </Step>
      </Step.Group>
    </OrderStepsLayout>
  )
}
