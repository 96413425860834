import React from 'react'
import { matchPath } from 'react-router'
import { ChatIcon, DashboardIcon, LaboratoryIcon, OrderSentIcon, TeamMembersIcon, User } from '@labsavvyapp/components'
import { CLIENTS, LAB_ORDER, LAB_REPORTS, MESSAGING, ORDERS_SENT } from '../../../config/routes'

export const getSidebarItems = (pathname: string, me: User, consumerTitle: string) => {
  const isCurrentPath = (path: string) => {
    return matchPath(pathname, {
      path,
    })
  }

  let items = [
    {
      title: 'Create Lab Order',
      icon: <LaboratoryIcon />,
      url: LAB_ORDER.new,
      active: isCurrentPath(LAB_ORDER.new),
      divider: true,
    },
    {
      title: 'Lab Reports',
      icon: <DashboardIcon />,
      url: LAB_REPORTS.base,
      active: isCurrentPath(LAB_REPORTS.base),
    },
    {
      title: `Transaction History`,
      icon: <OrderSentIcon />,
      url: ORDERS_SENT.base,
      active: isCurrentPath(ORDERS_SENT.base),
    },
    {
      title: `${consumerTitle}s`,
      icon: <TeamMembersIcon />,
      url: CLIENTS.base,
      active: isCurrentPath(CLIENTS.base),
    },
  ]

  if (me && !me.capabilities.isSuperAdmin && process.env.REACT_APP_USE_CHAT === 'true') {
    items.push({
      title: 'Messaging',
      icon: <ChatIcon />,
      url: MESSAGING.base,
      active: isCurrentPath(MESSAGING.base),
    })
  }

  return items
}
