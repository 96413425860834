import { ProjectPackage } from '@labsavvyapp/components'

export const checkOrdersetPanelAOEIfAnswered = (selectedOrderSets: ProjectPackage[]) => {
  let areAllAOEQuestionsAnswered = true

  if (!selectedOrderSets.length) {
    return true
  }

  selectedOrderSets.forEach((orderSet, i) => {
    orderSet.package.categories.forEach((category, i) => {
      category.panels.forEach((panel, i) => {
        if (panel?.aoe.length) {
          panel.aoe.forEach((aoe, i) => {
            if (!aoe.answer && aoe.is_required) {
              areAllAOEQuestionsAnswered = false
            }
          })
        }
      })
    })
  })

  return areAllAOEQuestionsAnswered
}
