import { AOEInput, PartnerCompendiumPanel, ProjectPackage } from '@labsavvyapp/components'

export const generateAOEAnswers = (selectedCompendiaPanels: PartnerCompendiumPanel[], selectedOrderSets: ProjectPackage[]) => {
  let aoe_answers: AOEInput[] = []

  selectedCompendiaPanels.forEach((panel) => {
    if (panel.aoe.length) {
      panel.aoe.forEach(({ _id, answer, choices }) => {
        if (answer) {
          let answer_code = answer.code

          // update code for multiple choices
          if (choices?.length) {
            const result = choices?.find((e) => e.value === answer.value)
            answer_code = result?.code
          }

          // construct aoe answers
          aoe_answers.push({
            order_code: panel.code,
            question_id: _id,
            answers: [
              {
                code: answer_code,
                value: answer.value,
              },
            ],
          })
        }
      })
    }
  })

  selectedOrderSets.forEach((orderSet, i) => {
    orderSet.package.categories.forEach((category, i) => {
      category.panels.forEach((panel, i) => {
        if (panel?.aoe.length) {
          panel.aoe.forEach(({ _id, answer, choices }) => {
            if (answer) {
              let answer_code = answer.code

              // update code for multiple choices
              if (choices?.length) {
                const result = choices?.find((e) => e.value === answer.value)
                answer_code = result?.code
              }

              // construct aoe answers
              aoe_answers.push({
                order_code: panel.code,
                question_id: _id,
                answers: [
                  {
                    code: answer_code,
                    value: answer.value,
                  },
                ],
              })
            }
          })
        }
      })
    })
  })

  return aoe_answers
}
