import { PartnerCompendiumPanel, ProjectPackage } from '@labsavvyapp/components'

export const generateOrderCodes = (selectedCompendiaPanels: PartnerCompendiumPanel[], selectedOrderSets: ProjectPackage[]) => {
  const orderCodesFromPanels = selectedCompendiaPanels?.map((panel) => panel.code) ?? []
  const orderCodesFromOrderSets =
    selectedOrderSets?.map((orderset) => orderset.package.categories.map((category) => category.panels.map((panel) => panel.code))).flat(3) ?? []
  const allOrderCodes = orderCodesFromPanels.concat(orderCodesFromOrderSets).filter((item, index, array) => array.indexOf(item) === index)

  return allOrderCodes
}
