import { BillTypeInput } from '@labsavvyapp/components'

export const ICD_CODE_VERSION = '10'

export type OrderTypes = 'Bill to Client' | 'Bill to Patient' | 'Bill to Insurance'

export interface OrderType {
  text: string
  label: string
  queryStringValue: string
  key: string
  value: string
  enabled: boolean
}

export const ORDER_TYPES: Record<OrderTypes, OrderType> = {
  'Bill to Client': {
    text: 'Bill to Client',
    label: 'Lab Contract Price',
    queryStringValue: 'direct_to_lab',
    key: 'order_type',
    value: '1',
    enabled: true,
  },
  'Bill to Patient': {
    text: 'Bill to Patient',
    label: 'Patient Retail Price',
    queryStringValue: 'patient_to_purchase',
    key: 'order_type',
    value: '2',
    enabled: true,
  },
  'Bill to Insurance': {
    text: 'Bill to Insurance',
    label: 'Bill to Insurance',
    queryStringValue: 'bill_to_insurance',
    key: 'order_type',
    value: '3',
    enabled: false,
  },
}

export type BillTypes = 'Client' | 'Patient' | 'Third Party'

export const BILL_TYPES: Record<BillTypes, BillTypeInput> = {
  Client: {
    text: 'Client',
    label: 'Client',
    queryStringValue: 'client',
    key: 'bill_type',
    value: 'C',
  },
  Patient: {
    text: 'Patient',
    label: 'Patient',
    queryStringValue: 'patient',
    key: 'bill_type',
    value: 'P',
  },
  'Third Party': {
    text: 'Third Party',
    label: 'Third Party',
    queryStringValue: 'thirdparty',
    key: 'bill_type',
    value: 'T',
  },
}

export type CreateLabOrderSteps = 'labs' | 'aoe' | 'icd' | 'review'

export interface CreateLabOrderStep {
  key: CreateLabOrderSteps
  name: string
  description: string
}

export const CREATE_LAB_ORDER_STEPS: Record<CreateLabOrderSteps, CreateLabOrderStep> = {
  labs: {
    key: 'labs',
    name: 'Labs',
    description: 'Select Tests',
  },
  aoe: {
    key: 'aoe',
    name: 'AOE',
    description: 'Ask at Order Entry',
  },
  icd: {
    key: 'icd',
    name: 'ICD',
    description: 'Select ICD Codes',
  },
  review: {
    key: 'review',
    name: 'Review',
    description: 'Confirm Order Information',
  },
}

export const LAB_TYPE = Object.freeze({
  defaultLabTypeOption: 'existing_package',
  options: [
    {
      label: 'Existing Package',
      text: 'Existing Package',
      queryStringValue: 'existing_package',
      key: 'lab_type',
      value: '1',
    },
    {
      label: 'Custom Package',
      text: 'Custom Package',
      queryStringValue: 'custom_package',
      key: 'lab_type',
      value: '2',
    },
    {
      label: 'Custom Order',
      text: 'Custom Order',
      queryStringValue: 'custom_order',
      key: 'lab_type',
      value: '3',
    },
  ],
})
