import { useQuery } from 'react-apollo'
import { WebappListPackages } from '../../../graphql/lab-orders/queries'
import { LabOrderSort, NameFilter, ProjectPackage } from '@labsavvyapp/components'

/**
 *  Query to get the list of packages (aka order sets) based on provider id 

 * @param (providerId)
 * @returns PartnerCompendiumPage
 */

interface ListPackagesVariables {
  providerId: string
  filter?: NameFilter
  limit?: number
  sort?: LabOrderSort
  page?: number
}

export const useQueryListPackagesApi = (variables: ListPackagesVariables) => {
  const { providerId, filter, limit, sort, page } = variables

  const {
    data: packageListData,
    error: packageListError,
    loading: packageListLoading,
    refetch: refetchPackageListData,
    fetchMore: fetchMorePackageListData,
  } = useQuery(WebappListPackages, {
    variables: {
      providerId: providerId,
      limit: limit || 20,
      sort: sort,
      filter: filter,
      page: page,
    },
    skip: !providerId,
  })

  return {
    data: packageListData?.webappListPackages?.packages as ProjectPackage[],
    error: packageListError,
    loading: packageListLoading,
    refetch: refetchPackageListData,
    fetchMore: fetchMorePackageListData,
  }
}
