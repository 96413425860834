import { PartnerCompendiumPanel, ProjectPackage, TransferListData } from '@labsavvyapp/components'
import { COMPENDIA_PANEL_IDENTIFIER, ORDER_SET_IDENTIFIER } from '../constants'

export const generateSelectedOrderSetsAndPanels = (
  transferListData: TransferListData,
  packageListData: ProjectPackage[],
  partnerCompendiaData: PartnerCompendiumPanel[],
  selectedOrderSets: ProjectPackage[],
  selectedCompendiaPanels: PartnerCompendiumPanel[],
) => {
  let updatedSelectedPackages: ProjectPackage[] = []
  let updatedSelectedCompendiaPanels: PartnerCompendiumPanel[] = []

  transferListData[1].map((tranferListItem) => {
    const selectionType = tranferListItem.value.includes(COMPENDIA_PANEL_IDENTIFIER) ? 'compendia' : 'orderset'

    if (selectionType === 'orderset') {
      const orderSetId = tranferListItem.value.split(ORDER_SET_IDENTIFIER)[0]
      const projectPackageData =
        packageListData?.find((orderSetData) => orderSetData.package._id === orderSetId) ??
        selectedOrderSets?.find((orderSetData) => orderSetData.package._id === orderSetId)
      if (projectPackageData) {
        updatedSelectedPackages.push(projectPackageData)
      }
    } else if (selectionType === 'compendia') {
      const code = tranferListItem.value.split(COMPENDIA_PANEL_IDENTIFIER)[0]
      const partnerCompendiaPanel =
        partnerCompendiaData?.find((compendiaData) => compendiaData.code === code) ??
        selectedCompendiaPanels?.find((compendiaData) => compendiaData.code === code)
      if (partnerCompendiaPanel) {
        updatedSelectedCompendiaPanels.push(partnerCompendiaPanel as PartnerCompendiumPanel)
      }
    }
  })

  return { updatedSelectedPackages, updatedSelectedCompendiaPanels } as const
}
