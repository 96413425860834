import React, { useEffect } from 'react'
import { Loader } from 'semantic-ui-react'
import { useParams, useHistory, useLocation, matchPath } from 'react-router'
import { useQuery } from '@apollo/react-hooks'
import { camelizeKeys } from 'humps'
import { Button } from '@labsavvyapp/ui-components'
import ReportDetails from '../../../components/ReportDetails/ReportDetails'
import { LAB_REPORTS, CLIENTS } from '../../../config/routes'
import LabReportTrendChild from './LabReportTrendChild'
import LabReportNotes from '../ViewEdit/LabReportNotes'
import { GetLabOrder } from '../../../graphql/lab-orders/queries.js'
import PageError from '../../../components/PageError/PageError'
import { DetailsLayout } from '../../../layouts/DetailsLayout/DetailsLayout'
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs'
import style from './TrendingPage.module.css'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import LabReportTrendHeader from './LabReportTrendHeader'
import StickyBox from 'react-sticky-box'

export default function TrendingPage() {
  const { push, goBack } = useHistory()
  const { labReportId } = useParams()
  const { state: locationState, pathname } = useLocation()

  // handle scroll position after content load
  const handleScrollPosition = () => {
    const scrollPosition = sessionStorage.getItem('scrollPosition')
    if (scrollPosition) {
      window.scrollTo(0, parseInt(scrollPosition))
      sessionStorage.removeItem('scrollPosition')
    }
  }

  // store position in sessionStorage
  const handleClick = (e) => {
    sessionStorage.setItem('scrollPosition', window.pageYOffset)
  }

  // apply saved scroll position on first load
  useEffect(() => {
    handleScrollPosition()
  }, [])

  const { height } = useWindowDimensions()

  // Fetch lab Order data
  const {
    data,
    loading: labOrderLoading,
    error,
    refetch,
  } = useQuery(GetLabOrder, {
    variables: {
      id: labReportId,
    },
  })
  const labOrderData = camelizeKeys(data)
  const labOrder = labOrderData && labOrderData.getLabOrder

  const isEdit = () =>
    matchPath(pathname, {
      path: LAB_REPORTS.edit,
      exact: true,
    })

  /**
   * Checks if the referrer link is a valid one. If not, the fallback is the "Lab Reports" page.
   */
  const hasValidReferrer = (referer) =>
    referer &&
    (referer.includes(LAB_REPORTS.base) || referer.includes(CLIENTS.base))

  /**
   * Gets the Previous page data.
   */
  const getPreviousPage = (referer) => {
    if (!hasValidReferrer(referer)) {
      return {
        textLink: 'Back to Lab Orders',
        url: LAB_REPORTS.base,
        onClick() {
          window.location.href = LAB_REPORTS.base
        },
      }
    }

    if (referer.includes(LAB_REPORTS.base)) {
      return {
        textLink: 'Back to Lab Orders',
        onClick() {
          goBack()
        },
      }
    }

    return {
      textLink: 'Back to Client',
      onClick() {
        goBack()
      },
    }
  }

  const previousPage = getPreviousPage(locationState && locationState.prevPath)

  if (labOrderLoading) return <Loader active />

  if (error)
    return (
      <PageError message="Lab order not found.">
        <Button
          type="button"
          variant="basic"
          onClick={() => push(LAB_REPORTS.base)}
        >
          Back to Lab Orders
        </Button>
      </PageError>
    )

  return (
    <div className={style.container}>
      <DetailsLayout>
        {/* Back Button */}
        <DetailsLayout.Header>
          <DetailsLayout.Panel>
            <Breadcrumbs
              text={previousPage.textLink}
              onClick={previousPage.onClick}
            />
          </DetailsLayout.Panel>
        </DetailsLayout.Header>

        {/* Report Notes */}
        <DetailsLayout.Panel className={style.leftPanel}>
          <StickyBox offsetTop={20} offsetBottom={20}>
            <div style={{ height: height - 185 }}>
              <ReportDetails labOrder={labOrder} refetch={refetch} />

              <div className={style.notesContainer}>
                <LabReportNotes labOrder={labOrder} />
              </div>
            </div>
          </StickyBox>
        </DetailsLayout.Panel>

        {/* Panel Details */}
        <DetailsLayout.Panel size={8}>
          {labOrder.results.map((result, i) => (
            <div key={i}>
              {result.tests
                ? result.tests.map((test, key) => {
                    const child = (
                      <LabReportTrendHeader
                        key={key}
                        test={test}
                        subtitle={''}
                        summary={''}
                        image={null}
                        style={{ paddingBottom: '20px' }}
                      >
                        <div
                          key={`LabReportTrendChild-${key}`}
                          onClick={() => handleClick()}
                        >
                          <LabReportTrendChild editing={isEdit()} test={test} />
                        </div>
                      </LabReportTrendHeader>
                    )

                    return child
                  })
                : null}
            </div>
          ))}
        </DetailsLayout.Panel>
      </DetailsLayout>
    </div>
  )
}
