import React, { useEffect, useState } from 'react'
import {
  YesCancelModal,
  Modal,
  Button as LabsavvyButton,
} from '@labsavvyapp/ui-components'
import {
  Grid,
  Dimmer,
  Loader,
  Dropdown,
  Input,
  Radio,
  Message,
} from 'semantic-ui-react'
import { DateInput, Button, Overlay } from '@labsavvyapp/components'
import classNames from 'classnames'

import ImageUpload from '../../../../components/ImageUpload'
import style from './InsuranceForm.module.css'

import { STATES, RELATIONSHIPS } from '../../../../constants'

export default function InsuranceForm(props) {
  const {
    insuranceData,
    insuranceDataImages,
    refetchListInsuranceImages,
    handleInsuranceImagesChange,
    insuranceProvidersData,
    healthPlanData,
    isPrimaryInsurance,
    handleIsPrimaryInsuranceOnChange,
    primaryInsuranceData,
    handlePrimaryInsuranceDataOnChange,
    secondaryInsuranceData,
    handleSecondaryInsuranceDataOnChange,
    addInsuranceImage,
    onRemoveInsuranceImage,
    toggleInsuranceActivation,
    hasError,
    hasSubmittedForm,
    handleHasSubmittedFormChange,
    activeInsurance,
    handleInsuranceCreateUpdate,
    selectedInsuranceData,
    generateInsurancePayload,
  } = props

  const INITIAL_INSURANCE_DATA = {
    provider: '',
    plan_type: '',
    first_name: '',
    last_name: '',
    middle_initial: '',
    guarantor_dob: '',
    city: '',
    state: '',
    id_number: '',
    coverage_date: '',
    group_name: '',
    group_number: '',
    front_image: null,
    back_image: null,
    is_active: true,
  }

  const [formData, setFormData] = useState(INITIAL_INSURANCE_DATA)

  const EMPTY_INSURANCE_PAYLOAD = {
    provider: '',
    plan_type: '',
    id_number: '',
    coverage_date: new Date(),
    group_name: '',
    group_number: '',
    subscriber: {
      first_name: '',
      last_name: '',
      middle_initial: '',
      guarantor_dob: new Date(),
      address: '',
      city: '',
      state: '',
      zip: '',
      phone: '',
      relationship: '',
    },
    front_image: null,
    back_image: null,
    patient_id: formData?._id ?? activeInsurance?.id ?? '',
  }

  const [showInsuranceActivationModal, setShowInsuranceActivationModal] =
    useState(false)
  const [
    showNewInsuranceConfirmationModal,
    setShowNewInsuranceConfirmationModal,
  ] = useState(false)
  const [
    showInsuranceSaveConfirmationModal,
    setShowInsuranceSaveConfirmationModal,
  ] = useState(false)

  const [overrideValidations, setOverrideValidations] = useState(false)

  const [insuranceEventResult, setInsuranceEventResult] = useState({
    event: null,
    result: null,
  })
  const primaryInsuranceImages = insuranceDataImages?.listInsurance?.find(
    (insurance) => insurance.form_type === 'primary',
  )

  const secondaryInsuranceImages = insuranceDataImages?.listInsurance?.find(
    (insurance) => insurance.form_type === 'secondary',
  )

  // If there is existing insurance data...
  useEffect(() => {
    const findPrimaryInsurance =
      insuranceData?.listInsurance?.find(
        (insurance) => insurance.form_type === 'primary',
      ) ?? INITIAL_INSURANCE_DATA

    const findSecondaryInsurance =
      insuranceData?.listInsurance?.find(
        (insurance) => insurance.form_type === 'secondary',
      ) ?? INITIAL_INSURANCE_DATA

    const updateInsuranceData = (insuranceData) => {
      return {
        ...insuranceData,
        first_name:
          insuranceData?.subscriber?.first_name ??
          formData?.subscriber?.first_name,
        last_name:
          insuranceData?.subscriber?.last_name ??
          formData?.subscriber?.last_name,
        middle_initial:
          insuranceData?.subscriber?.middle_initial ??
          formData?.subscriber?.middle_initial,
        guarantor_dob:
          insuranceData?.subscriber?.guarantor_dob ??
          formData?.subscriber?.guarantor_dob,
        address:
          insuranceData?.subscriber?.address ?? formData?.subscriber?.address,
        city: insuranceData?.subscriber?.city ?? formData?.subscriber?.city,
        state: insuranceData?.subscriber?.state ?? formData?.subscriber?.state,
        zip: insuranceData?.subscriber?.zip ?? formData?.subscriber?.zip,
        phone: insuranceData?.subscriber?.phone ?? formData?.subscriber?.phone,
        relationship:
          insuranceData?.subscriber?.relationship ??
          formData?.subscriber?.relationship,
      }
    }

    handlePrimaryInsuranceDataOnChange(
      updateInsuranceData(findPrimaryInsurance),
    )

    handleSecondaryInsuranceDataOnChange(
      updateInsuranceData(findSecondaryInsurance),
    )
  }, [insuranceData])

  useEffect(() => {
    const retainFormData = {
      provider: formData?.provider,
      plan_type: formData?.plan_type,
      first_name: formData?.first_name,
      middle_initial: formData?.middle_initial,
      guarantor_dob: formData?.guarantor_dob,
      last_name: formData?.last_name,
      address: formData?.address,
      city: formData?.city,
      state: formData?.state,
      zip: formData?.zip,
      phone: formData?.phone,
      relationship: formData?.relationship,
      id_number: formData?.id_number,
      coverage_date: formData?.coverage_date,
      group_name: formData?.group_name,
      group_number: formData?.group_number,
      is_active: formData?.is_active,
    }

    if (isPrimaryInsurance) {
      handlePrimaryInsuranceDataOnChange({
        ...primaryInsuranceData,
        ...retainFormData,
      })
    } else {
      handleSecondaryInsuranceDataOnChange({
        ...secondaryInsuranceData,
        ...retainFormData,
      })
    }
  }, [insuranceDataImages])

  // Handle switching of forms from primary to secondary v.v.
  useEffect(() => {
    const switchInsuranceForms = (insuranceData) => {
      return {
        ...insuranceData,
        first_name: insuranceData?.first_name ?? '',
        last_name: insuranceData?.last_name ?? '',
        middle_initial: insuranceData?.middle_initial ?? '',
        guarantor_dob: insuranceData?.guarantor_dob ?? '',
        address: insuranceData?.address ?? '',
        city: insuranceData?.city ?? '',
        state: insuranceData?.state ?? '',
        zip: insuranceData?.zip ?? '',
        phone: insuranceData?.phone ?? '',
        relationship: insuranceData?.relationship ?? '',
      }
    }

    if (isPrimaryInsurance) {
      setFormData(switchInsuranceForms(primaryInsuranceData))
    } else {
      setFormData(switchInsuranceForms(secondaryInsuranceData))
    }
  }, [isPrimaryInsurance, primaryInsuranceData, secondaryInsuranceData])

  const handleFormInputChange = (event, result) => {
    const { name, value, checked, type } = result || event.target
    let val

    if (type === 'radio') {
      val = checked
    } else {
      val = value || event?.target?.textContent
    }

    setFormData({
      ...formData,
      [name]: val,
    })

    // update primary and secondary insurance states as well...
    if (isPrimaryInsurance) {
      handlePrimaryInsuranceDataOnChange({
        ...INITIAL_INSURANCE_DATA,
        ...primaryInsuranceData,
        [name]: val,
      })
    } else {
      handleSecondaryInsuranceDataOnChange({
        ...INITIAL_INSURANCE_DATA,
        ...secondaryInsuranceData,
        [name]: val,
      })
    }
  }

  const handleImage = async (file, face) => {
    addInsuranceImage(face, file)
    refetchListInsuranceImages()
  }

  const onPreview = (file, face) => {
    const url = URL.createObjectURL(file)

    handleImage(file, `${face}_image`)
    URL.revokeObjectURL(url)
  }

  const handleInsuranceActivationToggle = (event, result) => {
    setInsuranceEventResult({ event: event, result: result })

    if (formData.is_active) {
      setShowInsuranceActivationModal(true)
    } else {
      setShowNewInsuranceConfirmationModal(true)
    }
  }

  const clearFormData = () => {
    setFormData({ ...INITIAL_INSURANCE_DATA, is_active: true })
    if (isPrimaryInsurance) {
      handlePrimaryInsuranceDataOnChange({
        ...INITIAL_INSURANCE_DATA,
        is_active: true,
      })
    } else {
      handleSecondaryInsuranceDataOnChange({
        ...INITIAL_INSURANCE_DATA,
        is_active: true,
      })
    }
    handleInsuranceImagesChange(null)
    handleHasSubmittedFormChange(false)
  }

  const isValidFormField = (key) => {
    if (!overrideValidations) {
      if (!hasSubmittedForm) {
        return hasError.includes(key)
      } else {
        return !formData[key]
      }
    } else {
      return null
    }
  }

  const parseDataToDropdownItem = (unparsedData) => {
    if (!unparsedData) {
      return []
    }
    let parsedData = unparsedData.map((data) => ({
      ...data,
      text: data.title,
      value: data.title,
      key: data.title.toLowerCase().replace(/\s+/g, '_'),
    }))

    const hasOtherValue = parsedData.find(
      (data) => data.value.toLowerCase === 'other',
    )
    if (!hasOtherValue) {
      parsedData.push({
        text: 'Other',
        value: 'Other',
        key: 'Other',
      })
    } else {
      const reorderData = parsedData.filter(
        (data) => data.value.toLowerCase !== 'other',
      )
      parsedData = reorderData.push({
        text: 'Other',
        value: 'Other',
        key: 'Other',
      })
    }

    return parsedData
  }

  const checkInsuranceFormForChanges = () => {
    const selectedInsurance = insuranceData?.listInsurance.find(
      (insurance) => insurance.form_type === activeInsurance,
    )
    if (selectedInsurance) {
      let {
        provider,
        plan_type,
        subscriber: {
          first_name,
          last_name,
          middle_initial,
          address,
          city,
          state,
          zip,
          phone,
          relationship,
        },
        id_number,
        coverage_date,
        group_name,
        group_number,
      } = selectedInsurance

      const selectedInsuranceArr = [
        provider,
        plan_type,
        first_name,
        last_name,
        middle_initial,
        address,
        city,
        state,
        zip,
        phone,
        relationship,
        id_number,
        coverage_date,
        group_name,
        group_number,
      ]

      const insuranceKeys = [
        'provider',
        'plan_type',
        'first_name',
        'last_name',
        'middle_initial',
        'address',
        'city',
        'state',
        'zip',
        'phone',
        'relationship',
        'id_number',
        'coverage_date',
        'group_name',
        'group_number',
      ]

      let hasChanges = false
      selectedInsuranceArr.forEach((field, index) => {
        if (field === null) {
          field = ''
        }
        if (formData[insuranceKeys[index]] === null) {
          formData[insuranceKeys[index]] = ''
        }
        if (field !== formData[insuranceKeys[index]]) {
          hasChanges = true
        }
      })

      return hasChanges
    } else {
      return false
    }
  }

  const checkForChangesBeforeInsuranceSwitch = (triggeredBy) => {
    if (triggeredBy !== activeInsurance) {
      const hasChanges = checkInsuranceFormForChanges()

      if (hasChanges) {
        setShowInsuranceSaveConfirmationModal(true)
      } else {
        handleIsPrimaryInsuranceOnChange(!isPrimaryInsurance)
      }
    }
    handleHasSubmittedFormChange(false)
  }

  const toggleInsuranceIsActiveState = () => {
    if (!formData.is_active) {
      setShowNewInsuranceConfirmationModal(true)
    }
    handleFormInputChange(
      insuranceEventResult.event,
      insuranceEventResult.result,
    )
    toggleInsuranceActivation(insuranceEventResult.result.checked, formData._id)
  }

  if (!formData) {
    return (
      <Dimmer active inverted>
        <Loader size="large">
          Loading Insurance Data to form. Please wait...
        </Loader>
      </Dimmer>
    )
  }

  return (
    <>
      <div className={style.container}>
        <div className={style.header}>
          <h2>
            Insurance Information (
            {isPrimaryInsurance ? 'Primary' : 'Secondary'})
          </h2>
        </div>
        <Grid columns="equal">
          <Grid.Row>
            <Grid.Column>
              {formData.is_active ? (
                <Message positive>
                  {isPrimaryInsurance ? 'Primary' : 'Secondary'} Insurance is
                  active
                </Message>
              ) : (
                <Message negative>
                  {isPrimaryInsurance ? 'Primary' : 'Secondary'} Insurance is
                  deactivated
                </Message>
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <div
              className={'ui borderless menu'}
              style={{
                backgroundColor: 'gray',
                border: '0px',
                borderRadius: '20px',
                boxShadow: 'none',
                color: 'var(--white)',
                display: 'flex',
                justifyContent: 'center',
                margin: '0.5rem auto 1rem',
                width: '45%',
              }}
            >
              <a
                className={classNames(isPrimaryInsurance && 'active', 'item')}
                onClick={() => checkForChangesBeforeInsuranceSwitch('primary')}
              >
                Primary
              </a>
              <a
                className={classNames(!isPrimaryInsurance && 'active', 'item')}
                onClick={() =>
                  checkForChangesBeforeInsuranceSwitch('secondary')
                }
              >
                Secondary
              </a>
            </div>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <label className={style.label}>Provider</label>
              <Dropdown
                fluid
                search
                selection
                placeholder="Select Provider"
                name="provider"
                onChange={handleFormInputChange}
                options={parseDataToDropdownItem(
                  insuranceProvidersData?.listInsuranceProviders,
                )}
                value={formData.provider}
                disabled={!formData.is_active}
                error={isValidFormField('provider')}
              />
              {isValidFormField('provider') && (
                <p className={style.labelError}>Please select a provider</p>
              )}
            </Grid.Column>
            <Grid.Column>
              <label className={style.label}>Plan Type</label>
              <Dropdown
                fluid
                search
                selection
                placeholder="Select Plan Type"
                name="plan_type"
                onChange={handleFormInputChange}
                options={parseDataToDropdownItem(
                  healthPlanData?.listHealthPlans,
                )}
                value={formData.plan_type}
                disabled={!formData.is_active}
                error={isValidFormField('plan_type')}
              />
              {isValidFormField('plan_type') && (
                <p className={style.labelError}>Please select a plan type</p>
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <label>Guarantor Name</label>
              <Input
                name="first_name"
                placeholder="First Name"
                onChange={handleFormInputChange}
                value={formData.first_name}
                disabled={!formData.is_active}
                fluid
                error={isValidFormField('first_name')}
              />
              {isValidFormField('first_name') && (
                <p className={style.labelError}>Please enter a first name</p>
              )}
            </Grid.Column>
            <Grid.Column computer={1} largeScreen={1} mobile={1} tablet={1}>
              <label className={style.invisible}>M.I.</label>
              <Input
                name="middle_initial"
                placeholder="M.I."
                onChange={(event, data) => {
                  data.value = data.value.toUpperCase()
                  handleFormInputChange(event, data)
                }}
                value={formData.middle_initial}
                disabled={!formData.is_active}
                type="text"
              />
            </Grid.Column>
            <Grid.Column>
              <label className={style.invisible}>Last Name</label>
              <Input
                name="last_name"
                placeholder="Last Name"
                onChange={handleFormInputChange}
                value={formData.last_name}
                disabled={!formData.is_active}
                fluid
                error={isValidFormField('last_name')}
              />
              {isValidFormField('last_name') && (
                <p className={style.labelError}>Please enter a last name</p>
              )}
            </Grid.Column>

            <Grid.Column>
              <label className={style.label}>Guarantor Date of Birth</label>

              <DateInput
                name="guarantor_dob"
                placeholder="Guarantor Date of Birth"
                size="md"
                value={
                  formData.guarantor_dob
                    ? new Date(formData.guarantor_dob)
                    : null
                }
                onChange={(value) => {
                  if (value)
                    handleFormInputChange(null, {
                      name: 'guarantor_dob',
                      value: value,
                    })
                }}
                disabled={!formData.is_active}
                error={isValidFormField('guarantor_dob')}
              />
              {isValidFormField('guarantor_dob') && (
                <p className={style.labelError}>
                  Please input a valid guarantor date of birth
                </p>
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <label>Street Address</label>
              <Input
                name="address"
                placeholder="Address"
                onChange={handleFormInputChange}
                value={formData.address || ''}
                disabled={!formData.is_active}
                type="text"
                error={isValidFormField('address')}
              />
              {isValidFormField('address') && (
                <p className={style.labelError}>Please enter an address</p>
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <label>City</label>
              <Input
                name="city"
                placeholder="City"
                onChange={handleFormInputChange}
                value={formData.city}
                disabled={!formData.is_active}
                fluid
                error={isValidFormField('city')}
              />
              {isValidFormField('city') && (
                <p className={style.labelError}>Please enter a city</p>
              )}
            </Grid.Column>
            <Grid.Column computer={4} tablet={6}>
              <label>State</label>
              <Dropdown
                fluid
                search
                selection
                placeholder="Select State"
                name="state"
                onChange={handleFormInputChange}
                options={STATES}
                value={formData.state}
                disabled={!formData.is_active}
                error={isValidFormField('state')}
              />
              {isValidFormField('state') && (
                <p className={style.labelError}>Please select a state</p>
              )}
            </Grid.Column>
            <Grid.Column>
              <label>ZIP</label>
              <Input
                name="zip"
                placeholder="ZIP"
                onChange={handleFormInputChange}
                value={formData.zip || ''}
                disabled={!formData.is_active}
                error={isValidFormField('zip')}
              />
              {isValidFormField('zip') && (
                <p className={style.labelError}>Please enter a zip</p>
              )}
            </Grid.Column>
            <Grid.Column>
              <label>Phone</label>
              <Input
                name="phone"
                placeholder="Phone"
                onChange={handleFormInputChange}
                value={formData.phone || ''}
                disabled={!formData.is_active}
                type="text"
                error={isValidFormField('phone')}
              />
              {isValidFormField('phone') && (
                <p className={style.labelError}>Please enter a phone number</p>
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <label>Relationship</label>
              <Dropdown
                fluid
                search
                selection
                name="relationship"
                placeholder={'Select Relationship'}
                onChange={handleFormInputChange}
                options={RELATIONSHIPS}
                value={formData.relationship}
                disabled={!formData.is_active}
                error={isValidFormField('relationship')}
              />
              {isValidFormField('relationship') && (
                <p className={style.labelError}>Please select relationship</p>
              )}
            </Grid.Column>

            <Grid.Column>
              <label className={style.label}>ID Number</label>
              <Input
                name="id_number"
                placeholder="ID Number"
                onChange={handleFormInputChange}
                value={formData.id_number || ''}
                disabled={!formData.is_active}
                error={isValidFormField('id_number')}
              />
              {isValidFormField('id_number') && (
                <p className={style.labelError}>Please enter an id number</p>
              )}
            </Grid.Column>
            <Grid.Column>
              <label className={style.label}>Coverage Date</label>

              <DateInput
                name="coverage_date"
                placeholder="Coverage Date"
                size="md"
                value={
                  formData.coverage_date
                    ? new Date(formData.coverage_date)
                    : null
                }
                onChange={(value) => {
                  if (value)
                    handleFormInputChange(null, {
                      name: 'coverage_date',
                      value: value,
                    })
                }}
                disabled={!formData.is_active}
                error={isValidFormField('coverage_date')}
              />
              {isValidFormField('coverage_date') && (
                <p className={style.labelError}>
                  Please input a valid coverage date
                </p>
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <label className={style.label}>Group Name</label>
              <Input
                name="group_name"
                placeholder="Group Name"
                onChange={handleFormInputChange}
                value={formData.group_name || ''}
                disabled={!formData.is_active}
                error={isValidFormField('group_name')}
              />
              {isValidFormField('group_name') && (
                <p className={style.labelError}>Please enter a group name</p>
              )}
            </Grid.Column>
            <Grid.Column>
              <label className={style.label}>Group Number</label>
              <Input
                name="group_number"
                placeholder="Group Number"
                onChange={handleFormInputChange}
                value={formData.group_number || ''}
                disabled={!formData.is_active}
                error={isValidFormField('group_number')}
              />
              {isValidFormField('group_number') && (
                <p className={style.labelError}>Please enter a group number</p>
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={{ zIndex: '0' }}>
            <Grid.Column>
              <div className={style.imageHeader}>
                <label className={style.label}>Front</label>
              </div>
              <ImageUpload
                image={
                  isPrimaryInsurance
                    ? primaryInsuranceImages?.front_image
                    : secondaryInsuranceImages?.front_image
                }
                onUpload={(file) => {
                  onPreview(file, 'front')
                }}
                onRemove={() => {
                  onRemoveInsuranceImage(
                    isPrimaryInsurance
                      ? primaryInsuranceImages?._id
                      : secondaryInsuranceImages?._id,
                    'front_image',
                  )
                }}
                disabled={!formData.is_active}
                formType={formData.form_type}
              />
            </Grid.Column>
            <Grid.Column>
              <div className={style.imageHeader}>
                <label className={style.label}>Back</label>
              </div>
              <ImageUpload
                image={
                  isPrimaryInsurance
                    ? primaryInsuranceImages?.back_image
                    : secondaryInsuranceImages?.back_image
                }
                onUpload={(file) => {
                  onPreview(file, 'back')
                }}
                onRemove={() => {
                  onRemoveInsuranceImage(
                    isPrimaryInsurance
                      ? primaryInsuranceImages?._id
                      : secondaryInsuranceImages?._id,
                    'back_image',
                  )
                }}
                disabled={!formData.is_active && formData}
                formType={formData.form_type}
              />
            </Grid.Column>
            {!formData?._id && (
              <Overlay color={'#fff'} backgroundOpacity={0.9} blur={5} />
            )}
          </Grid.Row>
          {!formData?._id && (
            <Grid.Row>
              <Message warning>
                You can upload a front and back insurance image after you`ve
                filled in the preceding fields and clicked Add/Update Insurance
              </Message>
            </Grid.Row>
          )}
          <Grid.Row>
            <Grid.Column>
              <div style={{ marginBottom: '10px' }}>
                <div className={style.imageHeader}>
                  <label className={style.label}>Is Insurance Active?</label>
                </div>
                <Radio
                  toggle
                  name="is_active"
                  checked={Boolean(formData?.is_active)}
                  onChange={(event, data) => {
                    handleInsuranceActivationToggle(event, data)
                  }}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>

      {/* Modals */}
      <YesCancelModal
        open={showInsuranceActivationModal}
        message={`Do you want to ${
          formData.is_active ? 'deactivate' : 'reactivate'
        } this insurance? `}
        onCloseClick={() => setShowInsuranceActivationModal(false)}
        onYesClick={() => {
          if (!formData.is_active) {
            setShowNewInsuranceConfirmationModal(true)
          }
          handleFormInputChange(
            insuranceEventResult.event,
            insuranceEventResult.result,
          )
          toggleInsuranceActivation(
            insuranceEventResult.result.checked,
            formData._id,
          )
          setShowInsuranceActivationModal(false)
        }}
      />

      <Modal
        open={showInsuranceSaveConfirmationModal}
        showClose
        onCloseClick={() => setShowInsuranceSaveConfirmationModal(false)}
      >
        <div className={style.reactivateModalContainer}>
          <p>{`Do you want to save this ${
            isPrimaryInsurance ? 'primary' : 'secondary'
          } insurance? There are unsaved changes.`}</p>

          <div className={style.saveInsuranceConfirmationModalControls}>
            <Button
              onClick={() => {
                // showAddEditInsuranceModal()
                const payload = generateInsurancePayload(selectedInsuranceData)
                handleInsuranceCreateUpdate(payload)
                setShowInsuranceSaveConfirmationModal(false)
              }}
              radius="xl"
              size="sm"
              uppercase
              variant="default"
            >
              Yes
            </Button>

            <LabsavvyButton
              onClick={() => {
                handleIsPrimaryInsuranceOnChange(!isPrimaryInsurance)
                setShowInsuranceSaveConfirmationModal(false)
              }}
              radius="xl"
              size="sm"
              uppercase
              variant="basic"
            >
              No, switch insurance to{' '}
              {!isPrimaryInsurance ? 'primary' : 'secondary'}
            </LabsavvyButton>
          </div>
        </div>
      </Modal>

      <Modal
        open={showNewInsuranceConfirmationModal}
        showClose
        onCloseClick={() => setShowNewInsuranceConfirmationModal(false)}
      >
        <div className={style.reactivateModalContainer}>
          <p>{`Do you want to create a new ${
            isPrimaryInsurance ? 'primary' : 'secondary'
          } insurance or keep existing insurance? Clicking cancel will deactivate the insurance.`}</p>

          <div className={style.reactivateModalControls}>
            <Button
              display="block"
              onClick={() => {
                toggleInsuranceIsActiveState()
                clearFormData()
                setShowInsuranceActivationModal(false)
                setShowNewInsuranceConfirmationModal(false)
                handleHasSubmittedFormChange(false)
                setOverrideValidations(true)
                // clear current insurance
                handleInsuranceCreateUpdate(
                  { ...EMPTY_INSURANCE_PAYLOAD },
                  true,
                )
              }}
              radius="xl"
              size="sm"
              uppercase
              variant="default"
            >
              Create New Insurance
            </Button>
            <Button
              display="block"
              onClick={() => {
                toggleInsuranceIsActiveState()
                setShowNewInsuranceConfirmationModal(false)
              }}
              radius="xl"
              size="sm"
              uppercase
              variant="default"
            >
              Keep Existing Insurance
            </Button>
            <LabsavvyButton
              onClick={() => {
                handleFormInputChange(insuranceEventResult.event, {
                  name: 'is_active',
                  checked: false,
                  type: 'radio',
                  toggle: true,
                  indeterminate: false,
                })
                toggleInsuranceActivation(false, formData._id)
                setShowNewInsuranceConfirmationModal(false)
              }}
              variant="basic"
            >
              Cancel
            </LabsavvyButton>
          </div>
        </div>
      </Modal>
    </>
  )
}
