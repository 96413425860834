import React from 'react'
import styled from '@emotion/styled'
import { SavingModal, SavedModal, ErrorModal } from '@labsavvyapp/ui-components'
import { useAddEditClientPageBusinessLogic } from '../../../../logic'

const FormModalsLayout = styled.div``

export const FormModals = () => {
  const businessLogic = useAddEditClientPageBusinessLogic()

  const showSavingModal = businessLogic?.showSavingModal ?? false
  const showSavedModal = businessLogic?.showSavedModal ?? false
  const showErrorModal = businessLogic?.showErrorModal ?? false
  const apiError = businessLogic?.apiError ?? ''

  return (
    <FormModalsLayout>
      <SavingModal message="We're saving your client, please wait..." open={showSavingModal} />
      <SavedModal open={showSavedModal} message="Your client has been saved" onClose={() => businessLogic?.handleShowSavedModalChange(false)} />
      <ErrorModal
        open={showErrorModal}
        message={apiError}
        onCloseClick={() => {
          businessLogic?.handleShowSavingModalChange(false)
          businessLogic?.handleShowErrorModalChange(false)
        }}
      />
    </FormModalsLayout>
  )
}
