import { useQuery } from 'react-apollo'
import { ListMyPartners } from '../../../graphql/partners/queries'

/**
 *  Query to get a list of Partners based on user

 * @returns Provider
 */

export const useQueryListMyPartnersApi = () => {
  const {
    data: listMyPartnersData,
    error: listMyPartnersError,
    loading: listMyPartnersLoading,
    refetch: refetchListMyPartnersData,
  } = useQuery(ListMyPartners)

  return {
    data: listMyPartnersData,
    error: listMyPartnersError,
    loading: listMyPartnersLoading,
    refetch: refetchListMyPartnersData,
  }
}
