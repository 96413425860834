import React, { useEffect, useState } from 'react'

import { PartnerProjectProvider, Provider, User } from '@labsavvyapp/components'
import { PartnerProjectProviderListDropdownOption, ProviderListDropdownOption, ProvidersContextProvider } from './context'
import { useQueryGetProviderApi, useQueryListPartnerProjectProvidersApi, useQueryListProvidersApi } from './api'

interface ComponentProps extends React.ComponentProps<any> {
  me: User
}

export const ProvidersViewModel: React.FunctionComponent<ComponentProps> = (props: React.PropsWithChildren<ComponentProps>) => {
  const { me } = props

  const [queriedProviderId, setQueriedProviderId] = useState<string | null>(null)
  const [providerList, setProviderList] = useState<Provider[]>([])
  const [providerListForDropdown, setProviderListForDropdown] = useState<ProviderListDropdownOption[]>([])
  const [partnerProjectProvidersListForDropdown, setPartnerProjectProvidersListForDropdown] = useState<PartnerProjectProviderListDropdownOption[]>([])

  const {
    data: providerListData,
    error: providerListError,
    loading: providerListLoading,
    refetch: refetchProviderListData,
  } = useQueryListProvidersApi()

  const {
    data: partnerProjectProvidersListData,
    error: partnerProjectProvidersListError,
    loading: partnerProjectProvidersListLoading,
    refetch: refetchPartnerProjectProvidersListData,
  } = useQueryListPartnerProjectProvidersApi(me?.project?._id)

  const { data: providerData } = useQueryGetProviderApi(queriedProviderId)

  useEffect(() => {
    if (providerListData) {
      if (providerList.length === 0) {
        setProviderList(providerListData?.listProviders?.providers)
      }
    }
  }, [providerListData])

  // Formatting provider list for dropdown
  useEffect(() => {
    const providerListForDropdown = providerListData?.listProviders?.providers.map((provider: Provider) => {
      return {
        key: provider?._id,
        text: provider?.name ?? '',
        label: provider?.name ?? '',
        value: provider?._id ?? '',
      }
    })
    setProviderListForDropdown(providerListForDropdown)
  }, [providerListData])

  // Formatting partner project provider list for dropdown
  useEffect(() => {
    const partnerProjectProvidersListForDropdown = partnerProjectProvidersListData?.listPartnerProjectProviders.map(
      (provider: PartnerProjectProvider) => {
        return {
          key: provider?.provider?.key ?? '',
          text: provider?.provider?.name ?? '',
          label: provider?.provider?.name ?? '',
          value: provider?.provider?._id,
        }
      },
    )

    setPartnerProjectProvidersListForDropdown(partnerProjectProvidersListForDropdown)
  }, [partnerProjectProvidersListData])

  const getProviderNameById = (providerId: string): string => {
    setQueriedProviderId(providerId)
    return providerData && providerData.name
  }

  return (
    <ProvidersContextProvider
      providerList={providerList}
      providerListError={providerListError}
      providerListLoading={providerListLoading}
      refetchProviderListData={refetchProviderListData}
      providerListForDropdown={providerListForDropdown}
      partnerProjectProvidersListData={partnerProjectProvidersListData?.listPartnerProjectProviders}
      partnerProjectProvidersListError={partnerProjectProvidersListError}
      partnerProjectProvidersListLoading={partnerProjectProvidersListLoading}
      refetchPartnerProjectProvidersListData={refetchPartnerProjectProvidersListData}
      partnerProjectProvidersListForDropdown={partnerProjectProvidersListForDropdown}
      getProviderNameById={getProviderNameById}
    >
      {props.children}
    </ProvidersContextProvider>
  )
}
