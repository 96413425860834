import React from 'react'
import styled from '@emotion/styled'
import { useCreateLabOrderBusinessLogic } from '../../logic'
import { Badge, Checkbox, ProjectPackage } from '@labsavvyapp/components'
import { COMPENDIA_PANEL_IDENTIFIER, ORDER_SET_IDENTIFIER } from '../../constants'

const TestColumnContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
`

const TestRowContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  align-content: center;
  gap: 10px;
  width: 100%;
  margin-bottom: 5px;
  font-size: 12px;
`

const CompendiaRowContainer = styled.div`
  display: grid;
  grid-template-columns: 20px 100px 5fr;
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 0px;
  margin-bottom: 5px;
`

const PanelCode = styled.div`
  display: flex;
  flex-flow: row nowrap;
  font-size: 12px;
  font-weight: 700;
`

const Item = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 500;
  align-items: center;
  align-content: center;
  width: 100%;
`

const PrimaryHeaderItem = styled(Item)`
  height: 30px;
  background-color: #f3f3f3;
  margin-left: 30px;
  padding: 0px 10px;
  width: calc(100% - 30px);
`

const SecondaryHeaderItem = styled(Item)`
  height: 30px;
  background-color: #ebebeb;
  margin-left: 30px;
  padding: 0px 10px;
  width: calc(100% - 30px);
  font-weight: 700;
`

const ResultItem = styled(Item)`
  height: 30px;
  border-bottom: 1px solid #ebebeb;
  margin-left: 30px;
  padding: 0px 10px;
  width: calc(100% - 30px);
`

interface CustomCompendiaTransferListItemProps {
  data: any
  selected: boolean
}

export const CustomCompendiaTransferListItem = (props: CustomCompendiaTransferListItemProps) => {
  const businessLogic = useCreateLabOrderBusinessLogic()
  const packageListData = businessLogic?.packageListData ?? null
  const partnerCompendiaData = businessLogic?.partnerCompendiaData ?? null
  const transferListData = businessLogic?.transferListData ?? [[], []]

  const { data, selected } = props
  const isSelected = transferListData[1].some((x) => x.value === data.value)
  const selectionType = data.value.includes(COMPENDIA_PANEL_IDENTIFIER) ? 'compendia' : 'orderset'

  let projectPackageData
  let partnerCompendiaPanel

  if (selectionType === 'orderset') {
    const orderSetId = data.value.split(ORDER_SET_IDENTIFIER)[0]
    projectPackageData = packageListData?.find((orderSetData) => orderSetData.package._id === orderSetId)
  } else if (selectionType === 'compendia') {
    const code = data.value.split(COMPENDIA_PANEL_IDENTIFIER)[0]
    partnerCompendiaPanel = partnerCompendiaData?.find((compendiaData) => compendiaData.code === code)
  }

  return (
    <TestColumnContainer>
      {selectionType === 'orderset' && (
        <TestRowContainer>
          <Checkbox checked={selected} onChange={() => {}} tabIndex={-1} sx={{ pointerEvents: 'none' }} />
          {data?.code && <PanelCode>{data?.code} </PanelCode>}
          <Item>
            <span>{data.label}</span>
            <Badge variant="filled">{selectionType}</Badge>
          </Item>
        </TestRowContainer>
      )}

      {selectionType === 'compendia' && (
        <CompendiaRowContainer>
          <Checkbox checked={selected} onChange={() => {}} tabIndex={-1} sx={{ pointerEvents: 'none' }} />
          <PanelCode>
            <b>{data?.code}</b>{' '}
          </PanelCode>

          <div>{data.label}</div>
        </CompendiaRowContainer>
      )}

      {isSelected && selectionType === 'orderset' && projectPackageData && (
        <TestColumnContainer>
          {projectPackageData?.package.categories.map((category) => (
            <>
              {category?.category?.name && (
                <PrimaryHeaderItem>
                  <span>{category?.category?.name}</span>
                </PrimaryHeaderItem>
              )}

              {category.panels.map((panel) => (
                <>
                  <SecondaryHeaderItem>
                    <span>{panel.code}</span>
                    <span>{panel.name}</span>
                  </SecondaryHeaderItem>

                  {panel.tests.map((test) => (
                    <ResultItem key={test.result.code}>
                      <span>{test.result.code}</span>
                      <span>{test.result.name}</span>
                    </ResultItem>
                  ))}
                </>
              ))}
            </>
          ))}
        </TestColumnContainer>
      )}

      {isSelected && selectionType === 'compendia' && partnerCompendiaPanel && (
        <TestColumnContainer>
          <SecondaryHeaderItem>
            <span>{partnerCompendiaPanel.code}</span>
            <span>{partnerCompendiaPanel.name}</span>
          </SecondaryHeaderItem>

          {partnerCompendiaPanel.results.map((result) => (
            <ResultItem>
              <span>{result.code}</span>
              <span>{result.name}</span>
            </ResultItem>
          ))}
        </TestColumnContainer>
      )}
    </TestColumnContainer>
  )
}
