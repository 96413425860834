export const SORT_OPTIONS = {
  dateAsc: { text: 'Newest to Oldest', value: 'desc', key: 'dateDesc' },
  dateDesc: { text: 'Oldest to Newest', value: 'asc', key: 'dateAsc' },
  nameAsc: {
    text: 'First Name A-Z',
    value: 'asc',
    key: 'nameAsc',
  },
  nameDesc: {
    text: 'First Name Z-A',
    value: 'desc',
    key: 'nameDesc',
  },
  statusAsc: { text: 'Status', value: 'asc', key: 'statusAsc' },
}

export const FILTER_OPTIONS = {
  allResults: { text: 'All', value: 'all', key: 'allResults' },
  resultViewed: { text: 'Viewed', value: 'viewed', key: 'resultViewed' },
  resultNotViewed: {
    text: 'Not Viewed',
    value: 'notViewed',
    key: 'resultNotViewed',
  },
}
export const DEFAULT_LAB_REPORT_COLUMN_VIEW = [
  '{"column_name":"Patient Name","column_width":null,"column_key":"patient_name","is_active":true}',
  '{"column_name":"DOB","column_width":null,"column_key":"dob","is_active":false}',
  '{"column_name":"Accession #","column_width":null,"column_key":"filler_id","is_active":false}',
  '{"column_name":"Report Name","column_width":null,"column_key":"name","is_active":false}',
  '{"column_name":"Provider Name","column_width":null,"column_key":"provider_name","is_active":true}',
  '{"column_name":"Order ID","column_width":null,"column_key":"short_id","is_active":true}',
  '{"column_name":"Ordered Date","column_width":null,"column_key":"ordered_date","is_active":true}',
  '{"column_name":"Report Date","column_width":null,"column_key":"report_date","is_active":true}',
  '{"column_name":"Status","column_width":null,"column_key":"status","is_active":true}',
  '{"column_name":"Reviewed","column_width":null,"column_key":"reviewed","is_active":false}',
  '{"column_name":"Viewed","column_width":null,"column_key":"viewed","is_active":false}',
  '{"column_name":"Trending","column_width":null,"column_key":"trending","is_active":true}',
  '{"column_name":"Lab Report PDF","column_width":null,"column_key":"lab_report_pdf","is_active":true}',
  '{"column_name":"Place Order","column_width":null,"column_key":"place_order","is_active":true}',
]
export const NO_COLUMNS_CONFIGURED_MESSAGE = `The settings for lab report columns aren't ready for this provider. Please ask your administrator for help.`
export const NO_LAB_REPORTS_FOUND_MESSAGE = `No lab orders found with the current search, sort and filter settings.`

export const REVIEWED_COLUMN_INFO_MESSAGE = `Reviewed: the timestamp when the provider user opens the lab report for first time.`
export const LAB_IS_IN_ORDERED_STATUS_MESSAGE = `Lab is in Ordered status`
