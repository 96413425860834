import { Question } from '@labsavvyapp/components'
import React, { ChangeEvent } from 'react'
import { Form } from 'semantic-ui-react'

interface GenerateAOEInputProps {
  aoe: Question
  panelCode: string
  handleChange: any
}

export const generateAOEInput = (props: GenerateAOEInputProps): JSX.Element | null => {
  const { aoe, panelCode, handleChange } = props
  const { _id: id, question, type, choices, is_required: isRequired, answer } = aoe

  const onChange = (event: ChangeEvent<any>, data: any) => {
    let updateAnswer = { code: '', value: '' }
    updateAnswer.code = id ?? type
    updateAnswer.value = data.value
    handleChange(updateAnswer)
  }

  switch (type) {
    case 'text:short':
      return (
        <Form.Field required={isRequired}>
          <span>{question}</span>
          <Form.Input placeholder={question} name={id} value={answer?.value || ''} onChange={onChange} />
        </Form.Field>
      )
    case 'text:long':
      return (
        <Form.Field required={isRequired}>
          <span>{question}</span>
          <Form.TextArea placeholder={question} name={id} value={answer?.value || ''} onChange={onChange} />
        </Form.Field>
      )
    case 'multi:one':
      return (
        <Form.Field required={isRequired}>
          <span>{question}</span>
          {choices.map((item: any) => (
            <Form.Radio
              label={item.value}
              value={item.value || ''}
              checked={item?.value === answer?.value}
              name={id}
              key={item.code}
              id={`${id}${panelCode}${item.code}`}
              onChange={onChange}
            />
          ))}
        </Form.Field>
      )
    case 'multi:many':
      return (
        <Form.Field required={isRequired}>
          <span>{question}</span>
          {choices.map((item: any) => (
            <Form.Checkbox
              label={item.value}
              value={item.value || ''}
              checked={item.value === answer.value}
              name={id}
              key={item.code}
              id={`${id}${panelCode}${item.code}`}
              onChange={onChange}
            />
          ))}
        </Form.Field>
      )
    case 'date':
      return (
        <Form.Field required={isRequired}>
          <span>{question}</span>
          <Form.Input name={id} placeholder={question} value={answer.value || ''} onChange={onChange} />
        </Form.Field>
      )
    case 'time':
      return (
        <Form.Field required={isRequired}>
          <span>{question}</span>
          <Form.Input name={id} placeholder={question} value={answer.value || ''} onChange={onChange} />
        </Form.Field>
      )
    case 'datetime':
      return (
        <Form.Field required={isRequired}>
          <span>{question}</span>
          <Form.Input name={id} placeholder={question} value={answer.value || ''} onChange={onChange} />
        </Form.Field>
      )
    default:
      return null
  }
}
