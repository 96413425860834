import { TransferListData } from '@labsavvyapp/components'
import { CREATE_LAB_ORDER_STEPS, CreateLabOrderSteps } from '../../../viewmodels'

export const updateStepsOnTestSelection = (
  transferListData: TransferListData,
  setDisabledSteps: (value: React.SetStateAction<CreateLabOrderSteps[]>) => void,
  setCompletedSteps: (value: React.SetStateAction<CreateLabOrderSteps[]>) => void,
) => {
  if (transferListData[1].length) {
    setDisabledSteps([CREATE_LAB_ORDER_STEPS.icd.key, CREATE_LAB_ORDER_STEPS.review.key])
    setCompletedSteps([CREATE_LAB_ORDER_STEPS.labs.key])
  } else {
    setDisabledSteps([CREATE_LAB_ORDER_STEPS.aoe.key, CREATE_LAB_ORDER_STEPS.icd.key, CREATE_LAB_ORDER_STEPS.review.key])
  }
}
