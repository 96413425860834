import React from 'react'
import { Confirm } from 'semantic-ui-react'
import { useCreateLabOrderBusinessLogic } from '../../logic'
import { CREATE_LAB_ORDER_STEPS } from '../../../../viewmodels'

export const ConfirmChangeLaboratoryModal = () => {
  const businessLogic = useCreateLabOrderBusinessLogic()
  const showConfirmSelectNewLabModal = businessLogic?.showConfirmSelectNewLabModal ?? false
  const previousSelectedLaboratory = businessLogic?.previousSelectedLaboratory ?? null
  const transferListData = businessLogic?.transferListData ?? [[], []]

  const onCancelChangeLab = () => {
    businessLogic?.toggleConfirmSelectNewLabModal?.()
    if (previousSelectedLaboratory) {
      businessLogic?.handleSelectedProviderIdChange?.(previousSelectedLaboratory?._id)
    }
  }

  const onConfirmChangeLab = () => {
    businessLogic?.toggleConfirmSelectNewLabModal?.()
    businessLogic?.handleTransferListDataChange?.([transferListData[0], []])
    businessLogic?.handleActiveStepChange?.(CREATE_LAB_ORDER_STEPS.labs.key)
  }

  return (
    <Confirm
      header="You just chose a different Laboratory"
      content="If you continue, all your previous selection will reset.
    Continue with new Laboratory?"
      confirmButton="Continue"
      open={showConfirmSelectNewLabModal}
      onCancel={onCancelChangeLab}
      onConfirm={onConfirmChangeLab}
    />
  )
}
