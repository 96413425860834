import React from 'react'
import { Header, HeaderDropdown } from '@labsavvyapp/ui-components'
import { Button, Divider, LabsavvyProviderLogo, Menu, PackageCategoriesIcon, Popover, TriangleIcon } from '@labsavvyapp/components'
import { CLIENTS, HOME, PROFILE, SIGN } from '../../../../config/routes'
import styled from '@emotion/styled'
import { Dropdown } from 'semantic-ui-react'
import { useHistory } from 'react-router'
import { useUserViewModel } from '../../../../viewmodels/user'
import { UserMenu } from './components'

const StyledHeader = styled(Header)`
  display: flex;
  width: auto;
  height: 60px;
  background-color: var(--white);
  border-bottom: 1px solid var(--darkWhite);
  gap: 10px;
`

const StyledDropdown = styled(Dropdown)`
  margin-right: 10px;
`

const PartnerProjectDropdowns = styled.span`
  display: flex;
  flex-flow: row nowrap;
  align-content: center;
  align-items: center;
  width: 100%;
  margin-right: 10px;
`

const Label = styled.span`
  padding: 0px 5px 0px 10px;
  color: gray;
  font-size: 12px;
`

export const NavigationBar = () => {
  const { push } = useHistory()
  const userViewModel = useUserViewModel()

  const me = userViewModel?.me ?? null
  const loggedIn = userViewModel?.loggedIn ?? false
  const selectedPartner = userViewModel?.selectedPartner ?? null
  const isSuperAdmin = userViewModel?.isSuperAdmin ?? false
  const setActiveProjectLoading = userViewModel?.setActiveProjectLoading ?? false
  const partnerOptions = userViewModel?.partnerOptions ?? []
  const projectOptions = userViewModel?.projectOptions ?? []
  const partnerSavingModal = userViewModel?.partnerSavingModal ?? null
  const navLogo = me?.partner?.logo?.url

  const handleLinkClick = (url: string) => {
    push(url)
  }

  const handleSavePartner = (event: React.SyntheticEvent<HTMLElement>, data: any) => {
    userViewModel?.handleShowSwitchPartnerModalChange(false)
    userViewModel?.showPartnerSavingModal(data.value)
  }

  const handleSwitchProject = async (event: React.SyntheticEvent<HTMLElement>, data: any) => {
    const { value } = data

    const [clients, __, view] = window.location.pathname.slice(1).split('/') // eslint-disable-line no-unused-vars
    if ('clients' === clients && 'view' === view) {
      push(CLIENTS.list)
    }

    try {
      await userViewModel?.setActiveProject({
        variables: { projectId: value },
      })
    } catch {
      userViewModel?.handleShowErrorModalChange(true)
    }
  }

  return (
    <StyledHeader logo={{ image: navLogo ?? <LabsavvyProviderLogo />, url: HOME }} onLinkClick={() => handleLinkClick(HOME)}>
      <PartnerProjectDropdowns>
        <Label>Partner:</Label>
        <StyledDropdown
          selection
          search
          placeholder="Select a Partner"
          value={selectedPartner?._id}
          options={partnerOptions}
          onChange={handleSavePartner}
          disabled={!isSuperAdmin}
        />
        <Label>Project:</Label>
        {projectOptions.length > 0 && (
          <StyledDropdown
            selection
            search
            loading={setActiveProjectLoading}
            options={projectOptions}
            onChange={handleSwitchProject}
            defaultValue={userViewModel?.getInitialProject()}
            value={me?.project?._id}
          />
        )}

        <UserMenu />
      </PartnerProjectDropdowns>

      {partnerSavingModal}
    </StyledHeader>
  )
}
