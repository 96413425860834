import { TextInput } from '@labsavvyapp/components'
import React from 'react'
import { useCreateLabOrderBusinessLogic } from '../../../../logic'

export const UnselectedTestListSearch = () => {
  const businessLogic = useCreateLabOrderBusinessLogic()
  const leftPanelSearch = businessLogic?.leftPanelSearch ?? ''

  return (
    <TextInput
      label=""
      placeholder="Search by code or name..."
      description=""
      value={leftPanelSearch}
      onChange={(event) => businessLogic?.handleLeftPanelSearchChange(event.currentTarget.value)}
    />
  )
}
