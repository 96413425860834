import React from 'react'
import { Switch, Route } from 'react-router'

import withMainLayout from '../layouts/Main/withMainLayout'
import NotesRoutes from './NotesRoutes'
import ListPage from '../pages/Clients/List/ListPage'
import FormPage from '../pages/Clients/AddEditClientPage/AddEditClientPage'
import ViewPage from '../pages/Clients/View/ViewPage'
import { CLIENTS, NOTES } from '../config/routes'
import InsurancePage from '../pages/Clients/InsurancePage/InsurancePage'

export default function ClientsRoutes({ path, me, loggedIn }) {
  return (
    <Route
      path={path}
      render={({ match }) => (
        <Switch>
          <Route
            exact
            path={[match.path, CLIENTS.list]}
            render={() =>
              withMainLayout(ListPage, {
                me,
                loggedIn,
              })
            }
          />
          <Route
            exact
            path={CLIENTS.new}
            render={() =>
              withMainLayout(FormPage, {
                me,
                loggedIn,
              })
            }
          />
          <Route
            exact
            path={CLIENTS.view}
            render={() =>
              withMainLayout(ViewPage, {
                me,
                loggedIn,
              })
            }
          />
          <Route
            exact
            path={CLIENTS.edit}
            render={() =>
              withMainLayout(FormPage, {
                me,
                loggedIn,
              })
            }
          />
          <Route
            exact
            path={CLIENTS.insurance}
            render={() =>
              withMainLayout(InsurancePage, {
                me,
                loggedIn,
              })
            }
          />
          <Route
            exact
            path={CLIENTS.newInsurance}
            render={() =>
              withMainLayout(InsurancePage, {
                me,
                loggedIn,
              })
            }
          />
          <NotesRoutes path={match.path} parentPath={`:clientId${NOTES.base}`} me={me} loggedIn={loggedIn} fallbackRouteComponent={ListPage} />
        </Switch>
      )}
    />
  )
}
