import React from 'react'
import { Card, Form, Header, Segment, Icon } from 'semantic-ui-react'
import style from './StepAskAtOrderEntry.module.css'
import { useCreateLabOrderBusinessLogic } from '../../logic'
import { generateAOEQuestions } from './utils'
import styled from '@emotion/styled'

const StepAskAtOrderEntryLayout = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 35px 0 20px;
  height: 100%;
  margin-bottom: 100px;
`

export const StepAskAtOrderEntry = () => {
  const businessLogic = useCreateLabOrderBusinessLogic()
  const selectedCompendiumPanels = businessLogic?.selectedCompendiaPanels ?? []
  const selectedOrderSets = businessLogic?.selectedOrderSets ?? []
  const compendiumPanelsWithAOE: any[] = []
  const orderSetsPanelsWithAOE: any[] = []

  const handleCompendiaPanelAOEChange = () => {
    const answeredAOE = {
      selectedCompendiumPanels: [...selectedCompendiumPanels],
    }

    businessLogic?.handleSelectedCompendiaPanelsChange?.(answeredAOE.selectedCompendiumPanels)
  }

  const handleOrdersetAOEChange = () => {
    const answeredAOE = {
      selectedOrderSets: [...selectedOrderSets],
    }

    businessLogic?.handleSelectedOrdersetPanelsChange?.(answeredAOE.selectedOrderSets)
  }

  selectedCompendiumPanels.forEach((panel, i) => {
    if (panel?.aoe.length) {
      compendiumPanelsWithAOE.push(
        <Card fluid color="pink" key={i + panel.code} className={style.panel}>
          <Card.Header className={style.header}>{panel.name}</Card.Header>
          <Card.Description className={style.content}>
            <Form className={style.form}>{generateAOEQuestions(panel?.aoe, panel?.code, handleCompendiaPanelAOEChange)}</Form>
          </Card.Description>
        </Card>,
      )
    }
  })

  selectedOrderSets.forEach((orderSet, i) => {
    orderSet.package.categories.forEach((category, i) => {
      category.panels.forEach((panel, i) => {
        if (panel?.aoe.length) {
          orderSetsPanelsWithAOE.push(
            <Card fluid color="pink" key={i + panel.code} className={style.panel}>
              <Card.Header className={style.header}>{panel.name}</Card.Header>
              <Card.Description className={style.content}>
                <Form className={style.form}>{generateAOEQuestions(panel?.aoe, panel?.code, handleOrdersetAOEChange)}</Form>
              </Card.Description>
            </Card>,
          )
        }
      })
    })
  })

  const showNoAOEMessage = !orderSetsPanelsWithAOE.length && !compendiumPanelsWithAOE.length

  return (
    <StepAskAtOrderEntryLayout>
      {orderSetsPanelsWithAOE.length > 0 && orderSetsPanelsWithAOE}
      {compendiumPanelsWithAOE.length > 0 && compendiumPanelsWithAOE}
      {showNoAOEMessage && (
        <Segment placeholder>
          <Header icon>
            <Icon name="question circle outline" />
            No questionnaire attached to the selected compendia.
          </Header>
        </Segment>
      )}
    </StepAskAtOrderEntryLayout>
  )
}
