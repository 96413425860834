import React from 'react'
import classnames from 'classnames'
import { Dropdown } from 'semantic-ui-react'
import { useHistory } from 'react-router'
import { Button, Search } from '@labsavvyapp/ui-components'

import style from './ListPageHeader.module.css'
import sharedStyle from '../../styles/shared.module.css'
import { Helmet } from 'react-helmet-async'

export default function ListPageHeader({
  className,
  title,
  ctaButton,
  sort,
  search,
}) {
  const { push } = useHistory()

  const overidePush = (ctaButton) => {
    if (ctaButton.path) {
      return push(ctaButton.path)
    } else {
      return ctaButton.click()
    }
  }

  return (
    <div className={classnames(style.container, className)}>
      <div className={style.titleAndFilterContainer}>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <h1 className={style.title}>{title}</h1>

        <div className={style.filters}>
          <div className={style.sortBy}>
            <span className={style.sortByLabel}>Sort by:</span>
            <Dropdown
              selection
              className={style.dropdown}
              value={sort.sortBy}
              options={sort.sortOptions}
              onChange={sort.onChange}
              data-test="sort-dropdown"
            />
          </div>
          <Search
            placeholder={search.placeholder}
            onSearchChange={search.onChange}
            searchText={search.text}
            showNoResults={false}
          />
        </div>

        {ctaButton && (
          <Button
            onClick={() => {
              return overidePush(ctaButton)
            }}
            className={classnames(sharedStyle.marginLeft10, style.ctaButton)}
            data-test={ctaButton.dataTest}
          >
            {ctaButton.label}
          </Button>
        )}
      </div>
    </div>
  )
}
