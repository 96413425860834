import { useQuery } from 'react-apollo'
import { ListPartnerProjectProviders } from '../../../graphql/providers/queries'

export const useQueryListPartnerProjectProvidersApi = (projectId: string | null) => {
  const {
    data: partnerProjectProvidersListData,
    error: partnerProjectProvidersListError,
    loading: partnerProjectProvidersListLoading,
    refetch: refetchPartnerProjectProvidersListData,
  } = useQuery(ListPartnerProjectProviders, {
    variables: { projectId: projectId },
    skip: !projectId,
  })

  return {
    data: partnerProjectProvidersListData,
    error: partnerProjectProvidersListError,
    loading: partnerProjectProvidersListLoading,
    refetch: refetchPartnerProjectProvidersListData,
  }
}
