export const ORDER_SET_IDENTIFIER = '_orderset'
export const COMPENDIA_PANEL_IDENTIFIER = '_compendia'

export const SORT_OPTIONS = Object.freeze({
  defaultSortOption: 'codeAZ',
  options: [
    {
      text: 'Code A-Z',
      queryStringValue: 'codeAZ',
      key: 'code',
      value: 'asc',
    },
    {
      text: 'Code Z-A',
      queryStringValue: 'codeZA',
      key: 'code',
      value: 'desc',
    },
  ],
})
