import { useQuery } from 'react-apollo'
import { PartnerCompendium } from '../../../graphql/lab-orders/queries'
import { OrderBy, PartnerCompendiumPage, PartnerCompendiumPanel } from '@labsavvyapp/components'

/**
 *  Query to get a compendia data based on partner and provider id 

 * @param (partnerId, providerId)
 * @returns PartnerCompendiumPage
 */

interface PartnerCompendiumVariables {
  filter: {
    search: string
    partnerId: string
    providerId: string
  }
  limit?: number
  sort?: PartnerCompendiumSort
}

interface PartnerCompendiumSort {
  name: OrderBy
}

export const useQueryPartnerCompendiumApi = (variables: PartnerCompendiumVariables) => {
  const { filter, limit, sort } = variables

  const {
    data: partnerCompendiaData,
    error: partnerCompendiaError,
    loading: loadingPartnerCompendia,
    refetch: refetchPartnerCompendiaData,
    fetchMore: fetchMorePartnerCompendiaData,
  } = useQuery(PartnerCompendium, {
    variables: {
      limit: limit,
      sort: sort,
      filter: filter,
    },
    skip: !filter.partnerId || !filter.providerId,
  })

  return {
    data: partnerCompendiaData?.partnerCompendium?.panels as PartnerCompendiumPanel[],
    pageData: partnerCompendiaData?.partnerCompendium as PartnerCompendiumPage,
    error: partnerCompendiaError,
    loading: loadingPartnerCompendia,
    refetch: refetchPartnerCompendiaData,
    fetchMore: fetchMorePartnerCompendiaData,
  }
}
