import gql from 'graphql-tag'

export const ListConfigs = gql`
  query ListConfigs(
    $filter: ConfigsFilter
    $sort: ConfigsSort = { name: asc }
    $limit: PositiveInt = 100
    $page: NonNegativeInt = 1
  ) {
    listConfigs(filter: $filter, sort: $sort, limit: $limit, page: $page) {
      configs {
        _id
        code
        name
        value
        value2
        value3
        domain
        subdomain
        category
        subcategory
      }
      page
      pages
      total
    }
  }
`

export const GetSpecimenContainerSequence = gql`
  query GetSpecimenContainerSequence(
    $providerId: MongoID!
    $orderCodes: [String]
  ) {
    getSpecimenContainerSequence(
      providerId: $providerId
      orderCodes: $orderCodes
    ) {
      name
      code
      draw_order
      meta {
        containers_external {
          name
        }
        specimens {
          name
        }
        containers {
          name
          subdomain
          value
          value2
        }
      }
    }
  }
`
