import { PartnerCompendiumPanel, ProjectPackage } from '@labsavvyapp/components'

interface IMiscPricing {
  subTotal: number
  miscPrice: number
  totalPrice: number
}

export const calculatePricing = (
  partnerMiscPricing: any,
  selectedCompendiaPanels: PartnerCompendiumPanel[],
  selectedOrderSets: ProjectPackage[],
): IMiscPricing => {
  const panelTotal = selectedCompendiaPanels.reduce((a, b) => a + (b.partner_pricing.retail_price || 0), 0)
  const packageTotal = selectedOrderSets.reduce((a, b) => a + (b.package.retail_price || 0), 0)

  const subTotal = panelTotal + packageTotal
  const miscPrice = partnerMiscPricing.reduce((a: number, b: { retail_price: number }) => a + (b.retail_price || 0), 0)

  const totalPrice = subTotal + miscPrice

  return {
    subTotal,
    miscPrice,
    totalPrice,
  }
}
