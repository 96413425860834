import gql from 'graphql-tag'

export const AddInsurance = gql`
  mutation AddInsurance($data: InsuranceInput!) {
    addInsurance(data: $data) {
      _id
    }
  }
`

export const EditInsurance = gql`
  mutation EditInsurance($id: MongoID!, $data: InsuranceInput!) {
    editInsurance(id: $id, data: $data) {
      _id
    }
  }
`

export const DeleteInsuranceImage = gql`
  mutation DeleteInsuranceImage($id: MongoID!, $data: DeleteInput!) {
    deleteInsuranceImage(id: $id, data: $data) {
      _id
    }
  }
`
