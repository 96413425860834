import React, { forwardRef, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { Dropdown } from 'semantic-ui-react'
import { useCreateLabOrderBusinessLogic } from '../../logic'
import { CreateLabOrderStepControls } from '../CreateLabOrderStepControls'
import { ActionIcon, Avatar, Button, Grid, LabsavvyLogo, Select, XIcon } from '@labsavvyapp/components'
import { useClientViewModel } from '../../../../viewmodels'
import dateFormatFromIso from '../../../../utils/date-format-from-iso'

export const FooterContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: calc(100%);
`

export const FooterSecondaryControls = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  width: 100%;
  background-color: #ececec;
  min-height: 60px;
  padding: 10px 15px;
  gap: 10px;
  justify-content: space-between;

  @media (max-width: 1320) {
    padding: 0px 15px;
  }
`

export const ControlContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 10px;
  align-items: center;
  align-content: center;
`

export const DropdownContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 10px;
  align-items: center;
  align-content: center;
`
export const FooterPrimaryControls = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-content: flex-end;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 77px;
  padding: 0px 15px;
  background-color: #f7f6f6;
`

const PoweredByLabSavvyBadgeContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
`

export const PoweredByLabSavvyBadge = () => {
  return (
    <PoweredByLabSavvyBadgeContainer>
      <span>Powered by</span>
      <LabsavvyLogo height={30} />
    </PoweredByLabSavvyBadgeContainer>
  )
}

export const PatientDOBField = styled.div`
  margin-right: 10px;
  word-wrap: break-word;
  line-height: 1em;
  white-space: normal;
  outline: 0;
  transform: rotate(0deg);
  min-width: 14em;
  min-height: 2.71428571em;
  background: #fff;
  display: inline-block;
  padding: 0.78571429em 2.1em 0.78571429em 1em;
  color: #1d1d26;
  box-shadow: none;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  transition: box-shadow 0.1s ease, width 0.1s ease;
`

interface ComponentProps extends React.ComponentProps<any> {}

export const CreateLabOrderControls: React.FunctionComponent<ComponentProps> = (props: ComponentProps) => {
  const businessLogic = useCreateLabOrderBusinessLogic()
  const clientViewModel = useClientViewModel()
  const selectedPatient = businessLogic?.selectedPatient ?? null
  const selectedLaboratory = businessLogic?.selectedLaboratory ?? null
  const selectedPaymentMethod = businessLogic?.selectedPaymentMethod ?? null
  const patientListForDropdown = businessLogic?.patientListForDropdown ?? []
  const providerListForDropdown = businessLogic?.providerListForDropdown ?? []
  const paymentMethodListForDropdown = businessLogic?.paymentMethodListForDropdown ?? []

  const [patientSearchValue, onPatientSearchChange] = useState<string>('')

  useEffect(() => {
    const patientsPageVariables = clientViewModel?.patientsPageVariables ?? null
    clientViewModel?.handlePatientsPageVariablesChange({
      ...patientsPageVariables,
      filter: { name: patientSearchValue },
      sort: { name: { first: 'asc', last: 'asc' } },
      limit: 5000,
    })
  }, [patientSearchValue])

  interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
    image: { avatar: true; src: string }
    label: string
    description: string
  }

  const SelectItemContainer = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    align-content: center;
    gap: 10px;
  `

  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(({ image, label, description, ...others }: ItemProps, ref) => (
    <SelectItemContainer ref={ref} {...others}>
      <Avatar src={image.src} radius={'xl'} />
      <p>{label}</p>
    </SelectItemContainer>
  ))

  return (
    <FooterContainer>
      <FooterSecondaryControls>
        <DropdownContainer>
          <ControlContainer>
            <span> Patient</span>
            <Select
              label=""
              description=""
              placeholder="Select Patient"
              data={patientListForDropdown}
              searchable={true}
              itemComponent={SelectItem}
              value={selectedPatient?._id ?? ''}
              onSearchChange={onPatientSearchChange}
              searchValue={patientSearchValue}
              onChange={(value) => businessLogic?.handleSelectedPatientChange?.(value ?? '')}
              dropdownComponent="div"
              clearable
            />
          </ControlContainer>

          <ControlContainer>
            <span>Patient DOB</span>
            <PatientDOBField>
              <b>DOB:</b> {selectedPatient ? `${dateFormatFromIso(selectedPatient?.data?.dob, 'MM/DD/yyyy')}` : '--'}
            </PatientDOBField>
          </ControlContainer>

          <ControlContainer>
            <span>Payment Method</span>
            <Select
              label=""
              description=""
              placeholder="Select Payment Method"
              data={paymentMethodListForDropdown}
              searchable={true}
              value={selectedPaymentMethod?.value}
              onChange={(value) => businessLogic?.handleSelectedPaymentMethodChange?.(value ?? '')}
              disabled={!selectedPatient?._id}
              dropdownComponent="div"
            />
          </ControlContainer>

          <ControlContainer>
            <span>Laboratory</span>
            <Select
              label=""
              description=""
              placeholder="Select Laboratory"
              data={providerListForDropdown}
              searchable={true}
              value={selectedLaboratory?._id}
              onChange={(value) => businessLogic?.handleSelectedProviderChange?.(value ?? '')}
              disabled={!selectedPatient?._id}
              dropdownComponent="div"
            />
          </ControlContainer>
        </DropdownContainer>

        <CreateLabOrderStepControls />
      </FooterSecondaryControls>
    </FooterContainer>
  )
}
