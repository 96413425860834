import React from 'react'
import { useAddEditClientPageBusinessLogic } from '../../../../logic'
import styled from '@emotion/styled'
import { AvatarIcon, Button, FileButton } from '@labsavvyapp/components'

const ProfilePhotoUploadLayout = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 10px;
  align-items: flex-end;
  margin-top: 30px;
  margin-left: 30px;
`
const ProfilePhoto = styled.img`
  width: 180px;
  height: 180px;
  line-height: 180px;
  border-radius: 120px;
  object-fit: cover;
  margin-bottom: 10px;
`

const NoProfilePhoto = styled.div`
  width: 180px;
  height: 180px;
  margin: 30px;
  padding: 20px;
  border-radius: 120px;
  margin-bottom: 10px;
`

export const ProfilePhotoUpload = () => {
  const businessLogic = useAddEditClientPageBusinessLogic()
  const selectedPatient = businessLogic?.selectedPatient ?? null
  const updatedPatientPhoto = businessLogic?.updatedPatientPhoto ?? null
  const clientPhoto = updatedPatientPhoto?.url ?? selectedPatient?.data?.profile_photo?.url

  const handleImageUpload = (selectedFile: File) => {
    if (selectedFile) {
      const result = businessLogic?.uploadImageFile(selectedFile)

      result?.then((value) => {
        businessLogic?.handleUpdatedPatientPhotoChange(value.data.uploadImage)
      })
    }
  }

  return (
    <ProfilePhotoUploadLayout>
      {clientPhoto ? (
        <ProfilePhoto src={updatedPatientPhoto?.url ?? clientPhoto} />
      ) : (
        <NoProfilePhoto>
          <AvatarIcon />
        </NoProfilePhoto>
      )}

      <FileButton onChange={handleImageUpload} accept="image/png,image/jpeg">
        {(props) => (
          <Button {...props} radius="sm" size="xs" uppercase variant="filled">
            UPLOAD
          </Button>
        )}
      </FileButton>
    </ProfilePhotoUploadLayout>
  )
}
