import React, { createContext, useContext } from 'react'
import { CreateLabOrderBusinessLogicContextContract } from './CreateLabOrderBusinessLogicContextContract'

const CreateLabOrderBusinessLogicContext = createContext<CreateLabOrderBusinessLogicContextContract | null>(null)

export const useCreateLabOrderBusinessLogic = (): CreateLabOrderBusinessLogicContextContract | null => {
  return useContext(CreateLabOrderBusinessLogicContext)
}

interface ComponentProps extends React.ComponentProps<any>, CreateLabOrderBusinessLogicContextContract {}

export const CreateLabOrderBusinessLogicProvider: React.FunctionComponent<ComponentProps> = (props: React.PropsWithChildren<ComponentProps>) => {
  const { children, ...rest } = props

  return <CreateLabOrderBusinessLogicContext.Provider value={rest}>{children}</CreateLabOrderBusinessLogicContext.Provider>
}
