import React, { useEffect, useState } from 'react'
import { Label } from 'semantic-ui-react'
import { CheckList } from '@labsavvyapp/ui-components'
import { getBrightness } from '../../../utils/helpers'

import style from './NewLabOrder.module.css'

export default function NewLabOrder({ selectedCompendiumPanels, setState }) {
  const [groupsChecklistItems, setGroupsChecklistItems] = useState([])

  useEffect(() => {
    setGroupsChecklistItems(
      selectedCompendiumPanels.map(
        ({ code, name, results, specimen, meta }) => {
          const groupTests = results.map((result) => ({
            text: (
              <div className={style.checkListTest}>
                <span className={style.checkListTestCode}>{result.code}</span>
                <span>{result.name}</span>
              </div>
            ),
            value: result.code,
          }))

          const metaData = (
            <>
              {meta?.containers?.map((container, i) => {
                const light = getBrightness(container?.value)

                return (
                  <Label
                    style={
                      light
                        ? {
                            backgroundColor: `#` + container?.value,
                            border: '1px solid #ccc',
                            color: '#333',
                          }
                        : {
                            backgroundColor: `#` + container?.value,
                            border: `1px solid #` + container?.value,
                            color: '#eee',
                          }
                    }
                    horizontal
                    key={i}
                  >
                    {container?.value2}
                  </Label>
                )
              })}

              {meta?.containersExternal?.map((container, i) => (
                <Label
                  horizontal
                  style={{ background: 'none', margin: 0 }}
                  key={i}
                >
                  {container?.name}
                </Label>
              ))}

              {meta?.specimens?.map((specimen, i) => (
                <Label
                  horizontal
                  style={{ background: 'none', margin: 0 }}
                  key={i}
                >
                  {specimen?.name}
                </Label>
              ))}
            </>
          )

          return {
            group: {
              code,
              name,
              label: specimen?.label,
              type: specimen?.type,
              checked: true,
            },
            tests: groupTests,
            metas: metaData,
          }
        },
      ),
    )
  }, [selectedCompendiumPanels])

  const removeTestHandler = (code) => {
    // update state used for display
    const newGroupChecklistItems = groupsChecklistItems.filter(
      ({ group }) => group.code !== code,
    )
    setGroupsChecklistItems(newGroupChecklistItems)

    // update state used for review page
    const newSelectedCompendiumPanels = selectedCompendiumPanels.filter(
      (panel) => panel.code !== code,
    )
    setState({ selectedCompendiumPanels: newSelectedCompendiumPanels })
  }

  return (
    <div className={style.newLabOrderContainer}>
      {/* children */}
      {groupsChecklistItems.length !== 0 &&
        groupsChecklistItems.map(({ group, tests, metas }) => (
          <div className={style.panelContainer} key={group.code}>
            <CheckList
              className={style.checkListHeader}
              readOnly
              title={
                <div className={style.checkListTest}>
                  <div>
                    <span className={style.checkListTestCode}>
                      {group.code}
                    </span>
                    <span>{group.name}</span>
                  </div>
                  <div>{metas}</div>
                </div>
              }
              group={{
                value: group.code,
                checked: group.checked,
              }}
              items={tests}
              showCheckboxes={false}
              onDeleteClick={removeTestHandler}
            />
          </div>
        ))}
    </div>
  )
}
