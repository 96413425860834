import React, { useEffect, useState } from 'react'
import { AddEditClientPageBusinessLogicProvider } from './logic'
import { useClientViewModel, useQueryGetPatientApi, useUserViewModel } from '../../../viewmodels'
import { useMutation } from 'react-apollo'
import { UploadImage } from '../../../graphql/files/mutations'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { PatientDataInput, UploadedFile, useForm } from '@labsavvyapp/components'
import { CLIENTS } from '../../../config/routes'
import { CreatePatient, UpdatePatient } from '../../../graphql/clients/mutations'
import { GetPatient } from '../../../graphql/clients/queries'

interface ComponentProps extends React.ComponentProps<any> {}

export const AddEditClientPageBusinessLogic: React.FunctionComponent<ComponentProps> = (props: ComponentProps) => {
  const { clientId } = useParams<{ clientId: string }>()
  const { push } = useHistory()

  const userViewModel = useUserViewModel()
  const clientViewModel = useClientViewModel()
  const consumerTitle = userViewModel?.consumerTitle ?? ''
  const genderOptions = clientViewModel?.genderOptions ?? []
  const raceOptions = clientViewModel?.raceOptions ?? []
  const ethnicityOptions = clientViewModel?.ethnicityOptions ?? []
  const stateOptions = clientViewModel?.stateOptions ?? []
  const phoneTypeOptions = clientViewModel?.phoneTypeOptions ?? []

  const [updatedPatientPhoto, setUpdatedPatientPhoto] = useState<UploadedFile | null>(null)
  const [showSavingModal, setShowSavingModal] = useState<boolean>(false)
  const [showSavedModal, setShowSavedModal] = useState<boolean>(false)
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false)
  const [apiError, setApiError] = useState('')
  const previousPageURL = clientId ? generatePath(CLIENTS.view, { clientId }) : CLIENTS.list

  const { data: selectedPatient, loading: selectedPatientLoading } = useQueryGetPatientApi(clientId)

  useEffect(() => {
    if (selectedPatient) {
      form.setValues({
        first_name: selectedPatient?.data?.name?.first ?? '',
        last_name: selectedPatient?.data?.name?.last ?? '',
        dob: selectedPatient?.data?.dob ? new Date(selectedPatient?.data?.dob) : null,
        gender: selectedPatient?.data.gender ?? '',
        race: selectedPatient?.data?.race?._id ?? '',
        ethnicity: selectedPatient?.data?.ethnicity?._id ?? '',
        street: selectedPatient?.data?.address?.street ?? '',
        city: selectedPatient?.data?.address?.city ?? '',
        state: selectedPatient?.data?.address?.state ?? '',
        zip: selectedPatient?.data?.address?.zip ?? '',
        address_billing_street: selectedPatient?.data?.address_billing?.street ?? '',
        address_billing_city: selectedPatient?.data?.address_billing?.city ?? '',
        address_billing_state: selectedPatient?.data?.address_billing?.state ?? '',
        address_billing_zip: selectedPatient?.data?.address_billing?.zip ?? '',
        address_shipping_street: selectedPatient?.data?.address_shipping?.street ?? '',
        address_shipping_city: selectedPatient?.data?.address_shipping?.city ?? '',
        address_shipping_state: selectedPatient?.data?.address_shipping?.state ?? '',
        address_shipping_zip: selectedPatient?.data?.address_shipping?.zip ?? '',
        phone_number: selectedPatient?.data?.phones[0]?.number ?? '',
        phone_type: selectedPatient?.data?.phones[0]?.type ?? '',
        email: selectedPatient?.data?.emails[0]?.address ?? '',
      })
    }
  }, [selectedPatient])

  const form = useForm({
    initialValues: {
      first_name: selectedPatient?.data?.name?.first ?? '',
      last_name: selectedPatient?.data?.name?.last ?? '',
      dob: selectedPatient?.data?.dob ? new Date(selectedPatient?.data?.dob) : null,
      gender: selectedPatient?.data.gender ?? '',
      race: selectedPatient?.data?.race?._id ?? '',
      ethnicity: selectedPatient?.data?.ethnicity?._id ?? '',
      street: selectedPatient?.data?.address?.street ?? '',
      city: selectedPatient?.data?.address?.city ?? '',
      state: selectedPatient?.data?.address?.state ?? '',
      zip: selectedPatient?.data?.address?.zip ?? '',
      address_billing_street: selectedPatient?.data?.address_billing?.street ?? '',
      address_billing_city: selectedPatient?.data?.address_billing?.city ?? '',
      address_billing_state: selectedPatient?.data?.address_billing?.state ?? '',
      address_billing_zip: selectedPatient?.data?.address_billing?.zip ?? '',
      address_shipping_street: selectedPatient?.data?.address_shipping?.street ?? '',
      address_shipping_city: selectedPatient?.data?.address_shipping?.city ?? '',
      address_shipping_state: selectedPatient?.data?.address_shipping?.state ?? '',
      address_shipping_zip: selectedPatient?.data?.address_shipping?.zip ?? '',
      phone_number: selectedPatient?.data?.phones[0]?.number ?? '',
      phone_type: selectedPatient?.data?.phones[0]?.type ?? '',
      email: selectedPatient?.data?.emails[0]?.address ?? '',
    },
    validate: {
      first_name: (value) => (value?.length <= 0 ? 'Cannot be empty' : null),
      last_name: (value) => (value?.length <= 0 ? 'Cannot be empty' : null),
      dob: (value) => (value === null ? 'Cannot be empty' : null),
      gender: (value) => (value?.length <= 0 ? 'Cannot be empty' : null),
      race: (value) => (value?.length <= 0 ? 'Cannot be empty' : null),
      ethnicity: (value) => (value?.length <= 0 ? 'Cannot be empty' : null),
      phone_number: (value) => (value?.length <= 0 ? 'Cannot be empty' : null),
      email: (value) => (value?.length <= 0 ? 'Cannot be empty' : null),
      street: (value) => (value?.length <= 0 ? 'Cannot be empty' : null),
      city: (value) => (value?.length <= 0 ? 'Cannot be empty' : null),
      state: (value) => (value?.length <= 0 ? 'Cannot be empty' : null),
      zip: (value) => (value?.length <= 0 ? 'Cannot be empty' : null),
    },
  })

  const [uploadImage] = useMutation(UploadImage)
  const [createPatient] = useMutation(CreatePatient)
  const [updatePatient] = useMutation(UpdatePatient, {
    refetchQueries: [
      {
        query: GetPatient,
        variables: { id: clientId },
      },
    ],
  })

  const uploadImageFile = async (file: File) => {
    try {
      const payload = await uploadImage({ variables: { file } })
      return payload as UploadedFile
    } catch (error) {
      handleApiErrorChange('Error uploading image')
      setShowErrorModal(true)
    }
  }

  const handleUpdatedPatientPhotoChange = (uploadedFile: UploadedFile) => {
    setUpdatedPatientPhoto(uploadedFile)
  }

  const handleServerError = (error: any) => {
    handleShowSavingModalChange(false)
    setShowErrorModal(true)
    setApiError(error?.graphQLErrors[0]?.message)
  }

  const sleep = (ms: number): Promise<void> => {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  const onAdd = async (values: PatientDataInput) => {
    try {
      handleShowSavingModalChange(true)

      const patient = await createPatient({
        variables: {
          data: {
            data: values,
          },
        },
      })

      await sleep(1000)
      handleShowSavingModalChange(false)
      setShowSavedModal(true)
      await sleep(1000)

      push(
        generatePath(CLIENTS.view, {
          clientId: patient.data.createPatient._id,
        }),
      )
    } catch (error) {
      handleServerError(error)
    }
  }

  const onEdit = async (values: PatientDataInput) => {
    try {
      handleShowSavingModalChange(true)

      await updatePatient({
        variables: {
          id: clientId,
          data: {
            data: values,
          },
        },
      })
      await sleep(1000)
      handleShowSavingModalChange(false)
      setShowSavedModal(true)
      await sleep(1000)

      push(generatePath(CLIENTS.view, { clientId }))
    } catch (error) {
      handleServerError(error)
    }
  }

  const handleApiErrorChange = (value: string) => {
    setApiError(value)
  }

  const handleShowSavingModalChange = (value: boolean) => {
    setShowSavingModal(value)
  }

  const handleShowSavedModalChange = (value: boolean) => {
    setShowSavedModal(value)
  }

  const handleShowErrorModalChange = (value: boolean) => {
    setShowErrorModal(value)
  }

  const handleSaveChangesClick = () => {
    const values: PatientDataInput = {
      name: {
        first: form.values.first_name,
        last: form.values.last_name,
      },
      dob: form.values.dob?.toISOString() ?? '',
      gender: form.values.gender,
      address: {
        street: form.values.street,
        city: form.values.city,
        state: form.values.state,
        zip: form.values.zip,
      },
      address_billing: {
        street: form.values.address_billing_street,
        city: form.values.address_billing_city,
        state: form.values.address_billing_state,
        zip: form.values.address_billing_zip,
      },
      address_shipping: {
        street: form.values.address_shipping_street,
        city: form.values.address_shipping_city,
        state: form.values.address_shipping_state,
        zip: form.values.address_shipping_zip,
      },
      phones: [
        {
          number: form.values.phone_number,
          type: form.values.phone_type,
        },
      ],
      emails: [{ address: form.values.email }],
      profile_photo_id: updatedPatientPhoto?._id ?? selectedPatient?.data?.profile_photo?._id,
      race: form.values.race.toString(),
      ethnicity: form.values.ethnicity.toString(),
    }
    if (selectedPatient) {
      onEdit(values)
    } else {
      onAdd(values)
    }
  }

  return (
    <AddEditClientPageBusinessLogicProvider
      selectedPatient={selectedPatient}
      selectedPatientLoading={selectedPatientLoading}
      consumerTitle={consumerTitle}
      form={form}
      updatedPatientPhoto={updatedPatientPhoto}
      handleUpdatedPatientPhotoChange={handleUpdatedPatientPhotoChange}
      uploadImageFile={uploadImageFile}
      previousPageURL={previousPageURL}
      genderOptions={genderOptions}
      raceOptions={raceOptions}
      ethnicityOptions={ethnicityOptions}
      stateOptions={stateOptions}
      phoneTypeOptions={phoneTypeOptions}
      apiError={apiError}
      handleApiErrorChange={handleApiErrorChange}
      showSavingModal={showSavingModal}
      handleShowSavingModalChange={handleShowSavingModalChange}
      showSavedModal={showSavedModal}
      handleShowSavedModalChange={handleShowSavedModalChange}
      showErrorModal={showErrorModal}
      handleShowErrorModalChange={handleShowErrorModalChange}
      handleSaveChangesClick={handleSaveChangesClick}
    >
      {props.children}
    </AddEditClientPageBusinessLogicProvider>
  )
}
