import React, { createContext, useContext } from 'react'
import { ClientContextContract } from './ClientContextContract'

const ClientContext = createContext<ClientContextContract | null>(null)

export const useClientViewModel = (): ClientContextContract | null => {
  return useContext(ClientContext)
}

interface ComponentProps
  extends React.ComponentProps<any>,
    ClientContextContract {}

export const ClientContextProvider: React.FunctionComponent<ComponentProps> = (
  props: React.PropsWithChildren<ComponentProps>,
) => {
  const { children, ...rest } = props

  return (
    <ClientContext.Provider value={rest}>{children}</ClientContext.Provider>
  )
}
