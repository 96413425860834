import React from 'react'

import { ListRow } from '@labsavvyapp/ui-components'
import { Checkbox } from 'semantic-ui-react'
import classnames from 'classnames'

import style from './ICDList.module.css'

const ICDRow = ({ data, onICDSelection, checked }) => {
  const { code, text } = data

  const handleCheck = () => {
    onICDSelection({ code, text })
  }

  const selected = checked ? style.selected : ''

  return (
    <ListRow
      data-test="icd-list-row"
      className={classnames(style.row, selected)}
      onClick={handleCheck}
    >
      <span className={style.check}>
        <Checkbox onClick={handleCheck} value={code} checked={checked} />
      </span>
      <span className={style.code}>{code}</span>
      <span className={style.text}>{text}</span>
    </ListRow>
  )
}

export default ICDRow
