import styled from '@emotion/styled'
import { ScrollArea } from '@labsavvyapp/components'
import React from 'react'
import { useCreateLabOrderBusinessLogic } from '../../../../logic'
import { CustomTestTransferListItem } from '../../../CustomCompendiaTransferListItem'

const StyledScrollArea = styled(ScrollArea)`
  background-color: white;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border: 1px solid #ced4da;
  border-top: 0;
  padding: 10px;
  height: 100%;
`

const NothingMessage = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 100%;
  color: gray;
`
interface SelectedTestListProps {
  hideCheckbox?: boolean
}
export const SelectedTestList = (props: SelectedTestListProps) => {
  const hideCheckbox = props.hideCheckbox ?? false

  const businessLogic = useCreateLabOrderBusinessLogic()
  const transferListData = businessLogic?.transferListData ?? [[], []]
  const transferListSelected = transferListData[1]
  const rightPanelSearch = businessLogic?.rightPanelSearch ?? ''
  const selectedRightPanelItems = businessLogic?.selectedRightPanelItems ?? []
  const setSelectedRightPanelItems = businessLogic && businessLogic.handleSelectedRightPanelItemsChange

  const filteredTransferListSelected = transferListSelected.filter(
    (item) =>
      item?.label?.toLowerCase().includes(rightPanelSearch?.toString()?.toLowerCase() ?? '') ||
      item?.code?.toLowerCase().includes(rightPanelSearch?.toString()?.toLowerCase() ?? ''),
  )

  return (
    <StyledScrollArea>
      {filteredTransferListSelected.map((data) => (
        <CustomTestTransferListItem
          key={data.value}
          data={data}
          selected={selectedRightPanelItems.some((item) => item === data.value)}
          handleSelectedItems={setSelectedRightPanelItems}
          hideCheckbox={hideCheckbox}
        />
      ))}
      {!filteredTransferListSelected.length && <NothingMessage>Nothing here...</NothingMessage>}
    </StyledScrollArea>
  )
}
