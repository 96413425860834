import React, { createContext, useContext } from 'react'
import { UserContextContract } from './UserContextContract'

const UserContext = createContext<UserContextContract | null>(null)

export const useUserViewModel = (): UserContextContract | null => {
  return useContext(UserContext)
}

interface ComponentProps extends React.ComponentProps<any>, UserContextContract {}

export const UserContextProvider: React.FunctionComponent<ComponentProps> = (props: React.PropsWithChildren<ComponentProps>) => {
  const { children, ...rest } = props

  return <UserContext.Provider value={rest}>{children}</UserContext.Provider>
}
