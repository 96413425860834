import React from 'react'
import styled from '@emotion/styled'
import { InfiniteScrollList } from '@labsavvyapp/ui-components'
import { fetchNext, hasMore, LoadingIcon } from '@labsavvyapp/components'
import { useCreateLabOrderBusinessLogic } from '../../../../logic'
import { CustomTestTransferListItem } from '../../../CustomCompendiaTransferListItem'
import { useUserViewModel } from '../../../../../../viewmodels'

const StyledScrollArea = styled.div`
  background-color: white;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border: 1px solid #ced4da;
  border-top: 0;
  padding: 10px;
  height: 100%;
`

const NothingMessage = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 100%;
  color: gray;
`

const LoadingMessage = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 5px;
  align-content: center;
  align-items: center;
`

export const UnselectedTestList = () => {
  const userViewModel = useUserViewModel()
  const businessLogic = useCreateLabOrderBusinessLogic()
  const me = userViewModel?.me ?? null
  const transferListData = businessLogic?.transferListData ?? [[], []]
  const leftPanelSearch = businessLogic?.leftPanelSearch ?? ''
  const selectedLeftPanelItems = businessLogic?.selectedLeftPanelItems ?? []
  const partnerCompendiaLoading = businessLogic?.partnerCompendiaLoading ?? false
  const selectedLaboratory = businessLogic?.selectedLaboratory ?? null
  const partnerCompendiaPageData = businessLogic?.partnerCompendiaPageData ?? null
  const setSelectedLeftPanelItems = businessLogic && businessLogic.handleSelectedLeftPanelItemsChange
  const transferListUnselected = transferListData[0]

  const filteredTransferListUnselected = transferListUnselected.filter(
    (item) =>
      item?.label?.toLowerCase().includes(leftPanelSearch?.toString()?.toLowerCase() ?? '') ||
      item?.code?.toLowerCase().includes(leftPanelSearch?.toString()?.toLowerCase() ?? ''),
  )

  let items = []
  let pagination = {
    page: 0,
    pages: 0,
    total: 0,
  }

  if (partnerCompendiaPageData) {
    items = partnerCompendiaPageData.panels
    pagination.page = partnerCompendiaPageData.page
    pagination.pages = partnerCompendiaPageData.pages
    pagination.total = partnerCompendiaPageData.total
  }

  return (
    <StyledScrollArea>
      <InfiniteScrollList
        dataLength={filteredTransferListUnselected?.length || 0}
        scrollableTarget="test-selection-list"
        hasMore={hasMore(pagination)}
        next={() =>
          fetchNext('partnerCompendium', 'panels', {
            page: pagination.page,
            fetchMore: businessLogic?.fetchMorePartnerCompendiaData,
            filter: {
              search: '',
              partnerId: me?.partner?._id,
              providerId: selectedLaboratory?._id,
            },
          })
        }
        saveScrollPosition={false}
      >
        {filteredTransferListUnselected.map((data) => (
          <CustomTestTransferListItem
            key={data.value}
            data={data}
            selected={selectedLeftPanelItems.some((item) => item === data.value)}
            handleSelectedItems={setSelectedLeftPanelItems}
          />
        ))}

        {!partnerCompendiaLoading && !filteredTransferListUnselected.length && <NothingMessage>Nothing here...</NothingMessage>}
        {partnerCompendiaLoading && (
          <LoadingMessage>
            <LoadingIcon size="sm" color="gray" />
            Loading more items
          </LoadingMessage>
        )}
      </InfiniteScrollList>
    </StyledScrollArea>
  )
}
