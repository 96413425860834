import React from 'react'

import MainLayout from './MainLayout'
import { LabsavvyViewModels } from '../../viewmodels'
import { User } from '@labsavvyapp/components'

interface MainLayoutProps {
  loggedIn: boolean
  me: User
}

const withMainLayout = (Component: any, { loggedIn, me }: MainLayoutProps) => (
  <LabsavvyViewModels loggedIn={loggedIn} me={me}>
    <MainLayout loggedIn={loggedIn} me={me}>
      <Component loggedIn={loggedIn} me={me} />
    </MainLayout>
  </LabsavvyViewModels>
)
export default withMainLayout
