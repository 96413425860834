import React from 'react';
import { LabOrdersContextProvider } from './context';
import { useQueryPartnerCompendiumApi } from './api';
import { User } from '@labsavvyapp/components';
import { getOrderTypes } from './utils';

interface ComponentProps extends React.ComponentProps<any> {
  me: User;
}

export const LabOrdersViewModel: React.FunctionComponent<ComponentProps> = (props: React.PropsWithChildren<ComponentProps>) => {
  const { me } = props;

  const partnerId = me?.partner?._id;
  const providerId = me?.partner?.config?.ordering?.default_laboratory?._id || me?.providers[0]?._id;

  const paymentMethodListForDropdown = getOrderTypes();

  const {
    data: partnerCompendiaData,
    error: partnerCompendiaError,
    loading: loadingPartnerCompendia,
    refetch: refetchPartnerCompendiaData,
  } = useQueryPartnerCompendiumApi({
    filter: { search: '', partnerId: partnerId, providerId: providerId },
  });

  return (
    <LabOrdersContextProvider
      partnerCompendiaData={partnerCompendiaData}
      partnerCompendiaError={partnerCompendiaError}
      partnerCompendiaLoading={loadingPartnerCompendia}
      refetchPartnerCompendiaData={refetchPartnerCompendiaData}
      paymentMethodListForDropdown={paymentMethodListForDropdown}
    >
      {props.children}
    </LabOrdersContextProvider>
  );
};
