import { BILL_TYPES, ORDER_TYPES, PaymentMethodListDropdownOption } from '../../../viewmodels'

export const generateBillType = (selectedPaymentMethod: PaymentMethodListDropdownOption | null) => {
  switch (selectedPaymentMethod?.value) {
    case ORDER_TYPES['Bill to Client'].value:
      return BILL_TYPES.Client
    case ORDER_TYPES['Bill to Patient'].value:
      return BILL_TYPES.Patient
    case ORDER_TYPES['Bill to Insurance'].value:
      return BILL_TYPES['Third Party']
    default:
      return BILL_TYPES.Client
  }
}
