import React, { useState, useEffect } from 'react'
import { Icon, Modal, Popup, Search } from 'semantic-ui-react'
import { Button, InfiniteScrollList } from '@labsavvyapp/ui-components'
import ListItem from './ListItem.jsx'

import style from './TestSelectionModal.module.css'

const TestSelectionModal = ({
  onNext,
  onSearch,
  onSubmit,
  onClose,
  onClear,
  hasMore,
  searchQuery,
  open,
  items,
}) => {
  const firstItem = items ? items.find((item) => !item.selected) : undefined
  const [selectedCode, setSelectedCode] = useState(firstItem?.code || undefined)
  const [selectedCodeFromSelectedList, setSelectedCodeFromSelectedList] =
    useState(undefined)
  const [modalOpen, setModalOpen] = useState(open)
  const [selectedCodeList, setSelectedCodeList] = useState([])

  const resetSelections = () => {
    setSelectedCodeList([])
    resetSelectedRight()
    resetSelectedLeft()
  }

  const resetSelectedRight = () => {
    setSelectedCodeFromSelectedList(undefined)
  }
  const resetSelectedLeft = () => {
    setSelectedCode(undefined)
  }

  const handleAddSelection = () => {
    const selectedItem = items.find((item) => item.code === selectedCode)
    const tempSelectedItems = selectedCodeList.filter(
      (item) => item.code !== selectedCode,
    )
    tempSelectedItems.push({
      ...selectedItem,
    })
    setSelectedCodeList(tempSelectedItems)
    resetSelectedLeft()
  }

  const handleRemoveSelection = () => {
    const tempSelectedItems = selectedCodeList.filter(
      (item) => item.code !== selectedCodeFromSelectedList,
    )
    setSelectedCodeList(tempSelectedItems)
    resetSelectedRight()
  }

  useEffect(() => {
    setSelectedCode(undefined)
  }, [selectedCodeList])

  useEffect(() => {
    setModalOpen(open)
  }, [open])

  useEffect(() => {
    onSearch(searchQuery)
  }, [searchQuery])

  useEffect(() => {
    if (items?.length === 0) setSelectedCode(undefined)
    if (!items?.find((item) => item.code === selectedCode) && items?.length > 0)
      setSelectedCode(firstItem?.code || undefined)
  }, [onSearch])

  const handleSelect = (code) => {
    resetSelectedRight()
    if (!selectedCodeList.find((selected) => selected.code === code)) {
      setSelectedCode(code)
    }
  }

  const handleSelectFromSelectedList = (code) => {
    resetSelectedLeft()
    setSelectedCodeFromSelectedList(code)
  }

  return (
    <Modal open={modalOpen} size="large" closeIcon={false}>
      <div className={style.content}>
        <div className={style.titleHeader}>
          <span>Search</span>
        </div>
        <Search
          className={style.search}
          placeholder="Search for a test by name or test code"
          showNoResults={false}
          onSearchChange={(e, data) => onSearch(data.value)}
          icon={<Icon link name="remove" onClick={onClear} />}
          value={searchQuery}
        />
        <div className={style.tables}>
          {items && items.length > 0 ? (
            <>
              <div className={style.panelsContainer}>
                <div className={style.titleHeader}>
                  <span>Available Test(s)</span>
                </div>
                <ul className={style.list}>
                  <InfiniteScrollList
                    dataLength={items?.length || 0}
                    scrollableTarget="test-selection-list"
                    hasMore={hasMore}
                    next={onNext}
                    saveScrollPosition={false}
                  >
                    {items.map((item, i) => (
                      <ListItem
                        key={i + item.code}
                        item={item}
                        selected={item.code === selectedCode}
                        disabled={selectedCodeList.find(
                          (selected) => selected.code === item.code,
                        )}
                        onClick={() => handleSelect(item.code)}
                      />
                    ))}
                  </InfiniteScrollList>
                </ul>
              </div>
              <div className={style.aggregatorButtons}>
                <Button
                  className={style.addButton}
                  onClick={handleAddSelection}
                  disabled={!selectedCode}
                >
                  <Icon name="arrow right" size="large" />
                </Button>
                <Button
                  className={style.addButton}
                  onClick={handleRemoveSelection}
                  disabled={!selectedCodeFromSelectedList}
                >
                  <Icon name="arrow left" size="large" />
                </Button>
              </div>
              <div className={style.panelsContainer}>
                <div className={style.titleHeader}>
                  <span>Selected</span>
                </div>
                <ul className={style.list}>
                  <InfiniteScrollList
                    dataLength={selectedCodeList.length ?? 0}
                    scrollableTarget="test-selected-list"
                    hasMore={hasMore}
                    next={onNext}
                    saveScrollPosition={false}
                  >
                    {selectedCodeList.map((code) => (
                      <Popup
                        key={`${code.code}_selected_pop`}
                        trigger={
                          <ListItem
                            key={`${code.code}_selected`}
                            item={code}
                            selected={
                              code.code === selectedCodeFromSelectedList
                            }
                            onClick={() =>
                              handleSelectFromSelectedList(code.code)
                            }
                          />
                        }
                        content={code?.results?.map((result, key) => (
                          <div key={key}>
                            <strong>{result.code}</strong> - {result.name}
                          </div>
                        ))}
                        position="left center"
                        hideOnScroll={true}
                      />
                    ))}
                  </InfiniteScrollList>
                </ul>
              </div>
            </>
          ) : (
            <div className={style.noResults}>
              <h2>No results!</h2>
            </div>
          )}
        </div>
        <div className={style.actions}>
          <Button className={style.cancelButton} onClick={onClose}>
            Cancel
          </Button>
          <Button
            className={style.addButton}
            onClick={() => {
              resetSelections()
              onSubmit(selectedCodeList)
            }}
            disabled={selectedCodeList.length === 0}
          >
            Add test(s)
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default TestSelectionModal
