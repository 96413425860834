import React from 'react'
import styled from '@emotion/styled'
import { SelectedTestList, SelectedTestListSearch, TestSelectionControls, UnselectedTestList, UnselectedTestListSearch } from './components'

const ComponentLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 30px 1fr;
  grid-template-rows: 100%;
  grid-column-gap: 20px;
  grid-row-gap: 0px;
  height: 700px;
  padding-top: 15px;
  margin-right: 30px;
`

const TestSelectionColumn = styled.div`
  display: flex;
  flex-flow: column nowrap;
`

export const StepSelectTests = () => {
  return (
    <ComponentLayout>
      <TestSelectionColumn>
        <label>Order sets and tests</label>
        <UnselectedTestListSearch />
        <UnselectedTestList />
      </TestSelectionColumn>

      <TestSelectionControls />

      <TestSelectionColumn>
        <label>Selections</label>
        <SelectedTestListSearch />
        <SelectedTestList />
      </TestSelectionColumn>
    </ComponentLayout>
  )
}
