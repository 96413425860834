import React from 'react'
import { Confirm } from 'semantic-ui-react'
import { useCreateLabOrderBusinessLogic } from '../../logic'
import { useHistory } from 'react-router-dom'
import { LAB_REPORTS } from '../../../../config/routes'

export const ConfirmCancelCreateLabOrderModal = () => {
  const { push } = useHistory()
  const businessLogic = useCreateLabOrderBusinessLogic()
  const showConfirmCancelCreateLabOrderModal = businessLogic?.showConfirmCancelCreateLabOrderModal ?? false

  const onCancelChangeLab = () => {
    businessLogic?.toggleConfirmCancelCreateLabOrderModal?.()
  }

  const onConfirmCancelCreateLabOrder = () => {
    push(LAB_REPORTS.base)
    businessLogic?.toggleConfirmCancelCreateLabOrderModal?.()
  }

  return (
    <Confirm
      header="Are you sure you want to cancel Create Lab Order"
      content="If you continue, all your changes will be lost.
    Exit Create Lab Order Page?"
      confirmButton="Continue"
      open={showConfirmCancelCreateLabOrderModal}
      onCancel={onCancelChangeLab}
      onConfirm={onConfirmCancelCreateLabOrder}
    />
  )
}
