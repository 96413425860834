import React from 'react'
import { format } from 'date-fns'
import { ListRow } from './components'

import style from './OrderSentList.module.css'
import styled from '@emotion/styled'
import { Badge } from '@labsavvyapp/components'

const RowContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export default function OrderSentListRow({ data }: any) {
  const status = data.stripeReferenceStatus || data.orderStatus

  const getBadgeStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case 'succeeded':
        return 'green'
      case 'send to patient for purchase':
        return 'pink'
      default:
        return ''
    }
  }

  return (
    <ListRow bordered={false} numberOfColumns={5}>
      <RowContainer>
        <span className={style.user} data-test="report-client">
          {data.patientName}
        </span>
      </RowContainer>
      <RowContainer>
        <span className={style.reportName} data-test="report-name">
          {data.name}
        </span>
      </RowContainer>
      <RowContainer>
        <span className={style.organization}>{data.partnerName}</span>
      </RowContainer>
      <RowContainer>
        <span className={style.ordered}>{data.createdAt && format(new Date(data.createdAt), 'MM/dd/yyyy')}</span>
      </RowContainer>
      <RowContainer>
        <Badge color={getBadgeStatusColor(status)}>{status}</Badge>
      </RowContainer>
    </ListRow>
  )
}
