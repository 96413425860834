import React from 'react'
import { NavLink, generatePath, useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { Loader } from 'semantic-ui-react'
import { ClientDetails as ClientDetailsComponent } from '@labsavvyapp/components'
import { Button } from '@labsavvyapp/ui-components'

import style from './ClientDetails.module.css'
import { CLIENTS } from '../../../../config/routes'

import { ListInsurance } from '../../../../graphql/insurance/queries'
// import InsuranceModal from '../../../../components/Modals/InsuranceModal/InsuranceModal'

export default function ClientDetails({
  client,
  loading,
  isReadOnlyPartnerManager,
}) {
  const { push } = useHistory()
  const { data: insuranceData } = useQuery(ListInsurance, {
    variables: { patient_id: client?.id },
    skip: !client,
  })

  if (loading || !client) return <Loader active />

  return (
    <div className={style.container} data-test="client-details">
      <ClientDetailsComponent
        _id={client.id}
        data={{
          ...client.data,
          profile_photo: {
            _id: client?.data?.profilePhoto?.id ?? '',
            url: client?.data?.profilePhoto?.url ?? '',
          },
        }}
        ctaSection={
          <NavLink
            data-test="button-insurance-client"
            to={
              insuranceData?.listInsurance.length
                ? generatePath(CLIENTS.insurance, { clientId: client.id })
                : generatePath(CLIENTS.newInsurance, { clientId: client.id })
            }
          >
            <Button>
              {insuranceData?.listInsurance.length ? 'View' : 'Add'} Insurance
            </Button>
          </NavLink>
        }
        clientDataPageLink={() =>
          push(generatePath(CLIENTS.view, { clientId: client.id }))
        }
      />

      {!isReadOnlyPartnerManager && (
        <NavLink
          data-test="button-edit-client"
          to={generatePath(CLIENTS.edit, { clientId: client.id })}
          className={style.editLink}
        >
          edit profile
        </NavLink>
      )}
    </div>
  )
}
